import React, { useState, useEffect } from 'react';
import { IconButton, Menu, MenuItem, Checkbox, ListItemText, Modal, Box, Button, Typography, TextField, Paper } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Barcode from 'react-barcode';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const DEFAULT_SETTINGS = {
  setTax: false,
  enableWholesalePrice: false,
  outOfStockAlert: false,
  setBarcode: false,
  setBuyingPrice:false,
};

const SettingsMenu = ({ settings,setSettings,setModalOpen2}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
//   const [settings, setSettings] = useState({ ...DEFAULT_SETTINGS, ...initialSettings });
  const [barcodeData, setBarcodeData] = useState('');
  const token = useSelector((state) => state.auth.token);
  const { id } = useParams();


  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleCheckboxChange = async (setting) => {
    const updatedSettings = { 
      ...settings,
      [setting]: !settings[setting]
    };
    setSettings(updatedSettings);

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/store/settings/${id}`, updatedSettings, {
        headers: { Authorization: `JWT ${token}` },
      });
      console.log(`${setting} updated successfully`);
    } catch (error) {
      // Revert the setting if the API call fails
      setSettings(settings);
      console.error(`Error updating ${setting}:`, error);
    }
  };

  const handleSaveBarcode = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/store/settings/${id}`, { barcodeData }, {
        headers: { Authorization: `JWT ${token}` },
      });
      console.log('Barcode saved:', barcodeData);
    } catch (error) {
      console.error('Error saving barcode:', error);
    } finally {
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setBarcodeData('');  // Reset barcode data when closing modal
  };

  return (
    <div>
      <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu 
        anchorEl={anchorEl} 
        open={Boolean(anchorEl)} 
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => setModalOpen2()}>
          {/* <Checkbox  /> */}
          <ListItemText primary="Manage Categories" />
        </MenuItem>
        <MenuItem onClick={() => handleCheckboxChange('setTax')}>
          <Checkbox checked={settings.setTax} />
          <ListItemText primary="Set Tax" />
        </MenuItem>
        <MenuItem onClick={() => handleCheckboxChange('enableWholesalePrice')}>
          <Checkbox checked={settings.enableWholesalePrice} />
          <ListItemText primary="set Wholesale Price" />
        </MenuItem>
        <MenuItem onClick={() => handleCheckboxChange('outOfStockAlert')}>
          <Checkbox checked={settings.outOfStockAlert} />
          <ListItemText primary="set Below stock Qty" />
        </MenuItem>
           <MenuItem onClick={() => handleCheckboxChange('setBuyingPrice')}>
          <Checkbox checked={settings.setBuyingPrice} />
          <ListItemText primary="Set Buying price (Calc Profit)" />
        </MenuItem>
        <MenuItem onClick={() => handleCheckboxChange('setBarcode')}>
          <Checkbox checked={settings.setBarcode} />
          <ListItemText primary="Set Barcode" />
        </MenuItem>
     
      </Menu>

      <Modal 
        open={modalOpen} 
        onClose={handleCloseModal}
        aria-labelledby="barcode-modal-title"
        aria-describedby="barcode-modal-description"
      >
        <Paper 
          sx={{ 
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
          }}
        >
          <Typography id="barcode-modal-title" variant="h6" component="h2" gutterBottom>
            Enter Barcode Data
          </Typography>
          <TextField
            id="barcode-input"
            label="Barcode Data"
            variant="outlined"
            fullWidth
            value={barcodeData}
            onChange={(e) => setBarcodeData(e.target.value)}
            margin="normal"
          />
          {barcodeData && (
            <Box mt={2} textAlign="center">
              <Barcode value={barcodeData} />
            </Box>
          )}
          <Box mt={2} display="flex" justifyContent="flex-end" gap={1}>
            <Button variant="outlined" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleSaveBarcode}
              disabled={!barcodeData}
            >
              Save
            </Button>
          </Box>
        </Paper>
      </Modal>
    </div>
  );
};

SettingsMenu.propTypes = {
  initialSettings: PropTypes.shape({
    setTax: PropTypes.bool,
    enableWholesalePrice: PropTypes.bool,
    outOfStockAlert: PropTypes.bool,
    setBarcode: PropTypes.bool,
  }),
};

SettingsMenu.defaultProps = {
  initialSettings: DEFAULT_SETTINGS,
};

export default SettingsMenu;
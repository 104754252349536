'use client'

import React, { useState, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Typography,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
} from '@mui/material'
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { useParams } from 'react-router-dom'

export default function CustomerList() {
  const { id: storeIdParam } = useParams()
  const [customers, setCustomers] = useState([])
  const [filteredCustomers, setFilteredCustomers] = useState([])
  const [open, setOpen] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: {
      street: '',
      city: '',
      state: '',
      zipCode: ''
    }
  })

  const [snackOpen, setSnackOpen] = useState(false)
  const [snackMessage, setSnackMessage] = useState('')
  const [snackSeverity, setSnackSeverity] = useState('success')
  const [searchQuery, setSearchQuery] = useState('')

  const isSmallScreen = useMediaQuery('(max-width:600px)')

  const fetchCustomers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/customer/${storeIdParam}`)
      const data = await response.json()
      setCustomers(data)
      setFilteredCustomers(data)
    } catch (error) {
      console.error('Error fetching customers:', error)
      setSnackMessage('Error fetching customers')
      setSnackSeverity('error')
      setSnackOpen(true)
    }
  }

  useEffect(() => {
    fetchCustomers()
  }, [storeIdParam])

  useEffect(() => {
    const results = customers.filter(customer =>
      customer.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer.email.toLowerCase().includes(searchQuery.toLowerCase())
    )
    setFilteredCustomers(results)
  }, [searchQuery, customers])

  const handleOpen = (customer = null) => {
    if (customer) {
      setSelectedCustomer(customer)
      setFormData(customer)
    } else {
      setSelectedCustomer(null)
      setFormData({
        name: '',
        email: '',
        phone: '',
        address: {
          street: '',
          city: '',
          state: '',
          zipCode: ''
        }
      })
    }
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedCustomer(null)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name.includes('.')) {
      const [parent, child] = name.split('.')
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }))
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }))
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const url = selectedCustomer
        ? `${process.env.REACT_APP_BACKEND}/api/customer/${storeIdParam}/${selectedCustomer._id}`
        : `${process.env.REACT_APP_BACKEND}/api/customer/${storeIdParam}`
      const method = selectedCustomer ? 'PUT' : 'POST'

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })

      if (response.ok) {
        fetchCustomers()
        handleClose()
        setSnackMessage(selectedCustomer ? 'Customer updated successfully' : 'Customer added successfully')
        setSnackSeverity('success')
        setSnackOpen(true)
      } else {
        setSnackMessage('Failed to save customer')
        setSnackSeverity('error')
        setSnackOpen(true)
      }
    } catch (error) {
      console.error('Error saving customer:', error)
      setSnackMessage('Error saving customer')
      setSnackSeverity('error')
      setSnackOpen(true)
    }
  }

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this customer?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/customer/${storeIdParam}/${id}`, {
          method: 'DELETE',
        })

        if (response.ok) {
          fetchCustomers()
          setSnackMessage('Customer deleted successfully')
          setSnackSeverity('success')
          setSnackOpen(true)
        } else {
          setSnackMessage('Failed to delete customer')
          setSnackSeverity('error')
          setSnackOpen(true)
        }
      } catch (error) {
        console.error('Error deleting customer:', error)
        setSnackMessage('Error deleting customer')
        setSnackSeverity('error')
        setSnackOpen(true)
      }
    }
  }

  const handleSnackClose = () => {
    setSnackOpen(false)
  }

  return (
    <div style={{padding:10}}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px 0' }}>
        <Typography variant="h5">Customers</Typography>
        <Button variant="contained" color="primary" onClick={() => handleOpen()}>
          Add Customer
        </Button>
      </div>


      <TextField
        label="Search by Name or Email"
        variant="outlined"
        size="small"
        className="mb-4"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        autoComplete="off"
      />
<br/><br/>
      {isSmallScreen ? (
        <Grid container spacing={2}>
          {filteredCustomers.map((customer) => (
            <Grid item xs={12} sm={6} md={4} key={customer._id}>
              <Card>
                <CardContent>
                  <Typography variant="h6">{customer.name}</Typography>
                  <Typography>Email: {customer.email}</Typography>
                  <Typography>Phone: {customer.phone}</Typography>
                  <Typography>City: {customer.address?.city}</Typography>
                  <Typography>Street: {customer.address?.street}</Typography>
                  <div>
                    <IconButton onClick={() => handleOpen(customer)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(customer._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Street</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCustomers.map((customer, index) => (
                <TableRow key={customer._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{customer.name}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>{customer.phone}</TableCell>
                  <TableCell>{customer.address?.city}</TableCell>
                  <TableCell>{customer.address?.street}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpen(customer)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(customer._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{selectedCustomer ? 'Edit Customer' : 'Add Customer'}</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              name="name"
              label="Name"
              fullWidth
              margin="normal"
              value={formData.name}
              onChange={handleChange}
              required
              autoComplete="off"
            />
            <TextField
              name="email"
              label="Email"
              fullWidth
              margin="normal"
              value={formData.email}
              onChange={handleChange}
              required
              type="email"
              autoComplete="off"
            />
            <TextField
              name="phone"
              label="Phone"
              fullWidth
              margin="normal"
              value={formData.phone}
              onChange={handleChange}
              required
              autoComplete="off"
            />
            <TextField
              name="address.street"
              label="Street"
              fullWidth
              margin="normal"
              value={formData.address.street}
              onChange={handleChange}
              required
              autoComplete="off"
            />
            <TextField
              name="address.city"
              label="City"
              fullWidth
              margin="normal"
              value={formData.address.city}
              onChange={handleChange}
              required
              autoComplete="off"
            />
            {/* <TextField
              name="address.state"
              label="State"
              fullWidth
              margin="normal"
              value={formData.address.state}
              onChange={handleChange}
              required
              autoComplete="off"
            /> */}
            {/* <TextField
              name="address.zipCode"
              label="Zip Code"
              fullWidth
              margin="normal"
              value={formData.address.zipCode}
              onChange={handleChange}
              required
              autoComplete="off"
            /> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">Cancel</Button>
            <Button type="submit" color="primary">{selectedCustomer ? 'Update' : 'Add'}</Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity={snackSeverity} className="w-full">
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}
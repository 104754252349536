import React, { useState, useEffect } from 'react';
import {
    Container,
    TextField,
    Button,
    Typography,
    Grid,
    IconButton,
    Paper,
    Card,
    CardMedia,
    CardContent,
    CardActions, Tooltip,
    Snackbar,
    Alert,Avatar,Modal
} from '@mui/material';
import { AppBar, Toolbar,  } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, Restore as RestoreIcon,ExpandMore as ExpandMoreIcon,ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import axios from 'axios';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { createTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BarChartIcon from '@mui/icons-material/BarChart';
import DescriptionIcon from '@mui/icons-material/Description';
import LayersIcon from '@mui/icons-material/Layers';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import InventoryIcon from '@mui/icons-material/Inventory';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import PointOfSale from './point';
import Inventory from './inventory';
import POSDashboard from './dashboard';
import OrdersTable from './orders';
import { useNavigate } from 'react-router-dom';
import { useDispatch, } from 'react-redux';
import { setUser, clearUser } from '../redux/slices/userSlice';
import { setToken, clearToken } from '../redux/slices/authSlice';
import { Logout } from '@mui/icons-material';
import SubaccountManager from './accounts';
import { persistor } from '../redux/store';
import ReferralCodeModal from './refferal';
import { jwtDecode } from 'jwt-decode';
import ImageModal from './imagesModal';
const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});



function DashboardLayoutBasic(props) {
   
  const { window } = props;
  const user = useSelector((state) => state.user);
  const [pathname, setPathname] = React.useState(null); // Start with null or appropriate path
  const token = useSelector((state) => state.auth.token);
  const router = React.useMemo(() => ({
    pathname,
    searchParams: new URLSearchParams(),
    navigate: (path) => {
      if (pathname !== path) { // Prevent unnecessary navigation
        setPathname(String(path));
      }
    },
  }), [pathname]);

  // Remove this const when copying and pasting into your project.
  const demoWindow = window !== undefined ? window() : undefined;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
 
      setPathname('/logout')
      // Navigate to login page
  };
  useEffect(() => {
// var tt=localStorage.getItem('subaccount') ;
//     console.log(tt)
    // if(tt!= 'true'){
    //     navigate('/store/login')
    // }
    // console.log(user)

    if(token==null ){
        if(user.email=="johndoe@gmail.com"){
            navigate('/pos/demo')
        }else{
                  navigate('/pos/login')
        }
  
    }
    var email=user.email;
    if (pathname === '/logout') {
    //   dispatch(clearUser());
      dispatch(clearToken());
      persistor.purge(); 
      if(email=="johndoe@gmail.com"){
        navigate('/pos/demo')
    }else{
              navigate('/pos/login')
    } // Redirect to login after clearing the user
    }
  }, [pathname]);

  // Inside your component
 
  return (
    <AppProvider
      branding={{
        logo: <img src="../../assets/logo.png" alt="Armada" />,
        title: 'Armada',
      }}
      router={router}
      theme={demoTheme}
      window={demoWindow}
    >
         <AppBar position="static">
            <Toolbar>
            <a href="../../"  style={{textDecoration:"none"}}>
        <div style={{}}>
          {/* <img src='../../assets/logo.png' style={{height:40}}/>  */}
          <Typography variant='h6' style={{color:"white"}}>Armada</Typography>
        </div>
       </a><div style={{ flexGrow: 1 }}></div>
               
                <Tooltip title={`${user?.email}`} arrow>
                    <Avatar
                        alt={user?.name}
                        src={user?.picture } // Default avatar if none
                        style={{ marginRight: '10px' }}
                    />
                </Tooltip>
                <Button color="inherit" onClick={handleLogout}>
                    Logout
                </Button>
            </Toolbar>
        </AppBar>
        <Container maxWidth="lg" style={{ marginTop: '20px' }}>
        <Box 
            display={{ xs: 'block', sm: 'flex' }} // Stack on small screens, flex on larger
            justifyContent="space-between"
            alignItems="center"
            bgcolor="background.paper"
            borderRadius={1}
            padding={2}
            boxShadow={2}
        >
            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                Welcome, {user?.name}!
            </Typography>
            <Typography variant="body1" color="textSecondary" textAlign={{ xs: 'center', sm: 'right' }}>
                You are logged in as {user?.email}
            </Typography>
        </Box>
    </Container>
<br/>
        <StoreManager/>
        
    </AppProvider>
  );
}

DashboardLayoutBasic.propTypes = {
  window: PropTypes.func,
};

export default DashboardLayoutBasic;
const placeholderImage = "https://via.placeholder.com/150"; // Placeholder image URL

const StoreManager = () => {
    const [stores, setStores] = useState([]);
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [editStoreId, setEditStoreId] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const user = useSelector((state) => state.user);
    const token = useSelector((state) => state.auth.token);
    const BASE_URL = `${process.env.REACT_APP_BACKEND}/api/store`;
const navigate=useNavigate()
    useEffect(() => {
        // console.log(user)
        localStorage.removeItem('lastPath');
        fetchStores();
    }, []);
const [sub,setSub]=useState(false)
    const fetchStores = async () => {
        try {
            const response = await axios.get(BASE_URL, {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            });
            // if(response.data.status==false){
            //     navigate('/store/login')
            // } 
            setStores(response.data.stores)
            const decoded = jwtDecode(token); // Decodes the JWT without verifying it
            console.log(decoded);
          
            if(decoded.type=="subaccount"){
                setStores([decoded.store])
                setSub(true)
            }
           ;
        } catch (error) {
            if (error?.response?.status === 404) {
                // Redirect to login if the store is not found
                navigate('../../pos/login'); // Change this to your login route
            } 
            console.error('Error fetching stores:', error);
            setSnackbarMessage('Error fetching stores');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const validateFields = () => {
        if (!name || !location || !description) {
            setSnackbarMessage('Please fill in all required fields.');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return false;
        }
        return true;
    };

    const handleAddStore = async () => {
        if (!validateFields()) return; // Validate fields before proceeding

        try {
            const response = await axios.post(
                BASE_URL,
                { name, location, description, image },
                {
                    headers: {
                        Authorization: `JWT ${token}`,
                    },
                }
            );
            // setStores([...stores, response.data.store]);
            fetchStores()
            setSnackbarMessage('Store added successfully');
            setSnackbarSeverity('success'); 
            clearFields();
        } catch (error) {
            console.error('Error adding store:', error);
            setSnackbarMessage('Error adding store');
            setSnackbarSeverity('error');
        }
        setOpenSnackbar(true);
    };

    const handleUpdateStore = async () => {
        if (!validateFields()) return; // Validate fields before proceeding

        try {
            const response = await axios.put(
                `${BASE_URL}/${editStoreId}`,
                { name, location, description, image }, // Send updated data
                {
                    headers: {
                        Authorization: `JWT ${token}`,
                    },
                }
            );
            // setStores(
            //     stores.map((store) =>
            //         store._id === editStoreId ? response.data.store : store
            //     )
            // );
            fetchStores()
            setSnackbarMessage('Store updated successfully');
            clearFields();
            setEditStoreId(null);
        } catch (error) {
            console.error('Error updating store:', error);
            setSnackbarMessage('Error updating store');
            setSnackbarSeverity('error');
        }
        setOpenSnackbar(true);
    };

    const handleEditStore = (store) => {
        setEditStoreId(store.storeId);
        setName(store.name);
        setLocation(store.location);
        setDescription(store.description);
        setImage(store.image);
    };

    const handleDeleteStore = async (id) => {
        const confirmed = window.confirm('Are you sure you want to delete this store?');
        if (!confirmed) return;

        try {
            await axios.delete(`${BASE_URL}/${id}`, {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            });
            // setStores(stores.filter((store) => store._id !== id));
            setSnackbarMessage('Store deleted successfully');
            fetchStores()
        } catch (error) {
            console.error('Error deleting store:', error);
            setSnackbarMessage('Error deleting store');
            setSnackbarSeverity('error');
        }
        setOpenSnackbar(true);
    };

    const clearFields = () => {
        setName('');
        setLocation('');
        setDescription('');
        setImage('');
        setEditStoreId(null);
        setSelectedImageUrl("")
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const openStoreLink = (storeId) => {
        const link = `${window.location.origin}/store/${storeId}`; // Adjust as necessary
        // window.open(link, '_blank'); 
        window.location.href = link;
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const referralCode = "ABC123"; // Replace with dynamic code if available
  
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState(null);

    const handleImageSelect = (url) => {
      setSelectedImageUrl(url);
      console.log('Selected image URL:', url);
      setImage(url)
    };
    const handleRestoreStore = async (id) => {
        try {
            const response = await axios.put(`${BASE_URL}/restore/${id}`, {}, {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            });
            fetchStores(); // Refresh the list after restoring
            setSnackbarMessage('Store restored successfully');
            setSnackbarSeverity('success');
        } catch (error) {
            console.error('Error restoring store:', error);
            setSnackbarMessage('Error restoring store');
            setSnackbarSeverity('error');
        }
        setOpenSnackbar(true);
    };
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    return (
        <Container>
 <Typography variant="h4" component="h1">    Manage Stores {sub==true && <>(Subaccount)</>}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', mb: 3 }}>
       
       {sub==false &&   <Button
       size="small"
          variant="contained"
          color="primary"
          onClick={() => setShowCreateForm(!showCreateForm)}
          startIcon={showCreateForm ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        >
         Create Store
        </Button>}
      </Box>

            {sub==false &&  showCreateForm && <Paper style={{ padding: '16px', marginBottom: '20px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            label="Store Name"
                            value={name}
                            autoComplete='off'
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            label="Location"
                            value={location}
                               autoComplete='off'
                            onChange={(e) => setLocation(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            label="Image URL"
                            value={image}
                               autoComplete='off'
                            disabled
                            onChange={(e) => setImage(e.target.value)}
                        />
                   
                        <ImageModal onImageSelect={handleImageSelect} />
      {selectedImageUrl && (
        <div>
          {/* <h2>Selected Image:</h2> */}
          <img 
            src={selectedImageUrl} 
            alt="Selected image" 
            style={{ maxWidth: '200px', maxHeight: '200px' }} 
          />
          {/* <p>URL: {selectedImageUrl}</p> */}
        </div>
      )}
                    </Grid>
                </Grid>
            </Paper>}
            {sub==false &&  showCreateForm &&    <ReferralCodeModal
        open={isModalOpen}
        onClose={handleCloseModal}
        referralCode={referralCode}
      />}
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3,width:'100%' }}>
         {sub==false &&  showCreateForm &&     <div style={{ marginBottom: '20px' }}>
                                    <Button
                    size="small"
                        variant="contained"
                        color="primary"
                        style={{ marginRight: '8px' }}
                        onClick={editStoreId ? handleUpdateStore : handleAddStore}
                    >
                        {editStoreId ? 'Update Store' : 'Add Store'}
                    </Button>
                    {name || location || description || image ?  (
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={clearFields}
                             size="small"
                        >
                            Clear
                        </Button>
                    ):null}
               
            </div>}
            {sub==false &&  showCreateForm &&    <Button size="small" color="primary"  onClick={handleOpenModal}>
        Referral Code
      </Button>}

             {/* Button container */}
         
</Box>

            {/* Store Listing with Cards */}
            <Grid container spacing={3}>
            {stores.filter(item=>item.isDeleted!=true).map((store) => (
                    <Grid item xs={12} sm={6} md={4} key={store._id}>
                        <Card>
                            <CardMedia
                                component="img"
                                height="140"
                                image={store.image || placeholderImage}
                                alt={store.name}
                            />
                            <CardContent>
                                <Typography variant="h5">{store.name}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {store.location}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {store.description}
                                </Typography>
                            </CardContent>
                            <CardActions style={{ justifyContent: 'flex-end' }}>
                            {sub==false &&<><IconButton onClick={() => handleEditStore(store)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDeleteStore(store.storeId)}>
                                    <DeleteIcon />
                                </IconButton></>}
                                <div style={{flex:1}}></div>
                                <Button onClick={() => {  localStorage.setItem('store', JSON.stringify(store));openStoreLink(store.storeId)}} color="primary">
                                    Open
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <br/>  
            {stores.filter(item=>item.isDeleted==true).length>0&& <Button  color="secondary" size="small" onClick={() => setIsModalOpen2(true)}>
                View Deleted Stores
            </Button>}
        
            <Modal open={isModalOpen2} onClose={()=>setIsModalOpen2(false)} aria-labelledby="deleted-stores-modal">
        <Box sx={{ width: 400, bgcolor: 'background.paper', p: 4, margin: 'auto', mt: 5, borderRadius: 1 }}>
            <Typography variant="h6" id="deleted-stores-modal" gutterBottom>Deleted Stores</Typography>
            {stores.filter(item=>item.isDeleted==true).length ? (
                stores.filter(item=>item.isDeleted==true).map(store => (
                    <Card key={store._id} sx={{ mb: 2 }}>
                        <CardContent>
                            <Typography variant="h6">{store.name}</Typography>
                            <Typography variant="body2" color="textSecondary">{store.location}</Typography>
                        </CardContent>
                        <CardActions>
                            <IconButton color="primary" onClick={() => handleRestoreStore(store.storeId)}>
                                <RestoreIcon /> Restore
                            </IconButton>
                            {/* <IconButton color="error" onClick={() => handlePermanentDelete(store.id)}>
                                <DeleteIcon /> Delete Permanently
                            </IconButton> */}
                        </CardActions>
                    </Card>
                ))
            ) : (
                <Typography>No deleted stores available.</Typography>
            )}
        </Box>
    </Modal>
            {/* Snackbar for notifications */}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper
} from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const LowStockProductsModal = ({ open, handleClose, storeId }) => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [realStoreId, setRealStoreId] = useState(null); 
  const token = useSelector((state) => state.auth.token);
  const { id: storeIdParam } = useParams();
  const BASE_URL = `${process.env.REACT_APP_BACKEND}`;

  // Fetch low-stock products from API
  useEffect(() => {
    if (open) {
      fetchLowStockProducts(page, rowsPerPage);
    }
  }, [open, page, rowsPerPage]);

  const fetchLowStockProducts = async (page, limit) => {
    try {
      const response = await axios.get(BASE_URL+`/api/below_stock/${storeIdParam}`, {
        params: { page: page + 1, limit }
      },{ headers: {
        Authorization: `JWT ${token}`,
      },});
      setProducts(response.data.products);
      setTotalItems(response.data.totalItems);
    } catch (error) {
      console.error('Error fetching low-stock products:', error);
    }
  };

  // Handle pagination changes
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="low-stock-modal-title">
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        maxHeight: '90vh',
        overflowY: 'auto'
      }}>
        <Typography id="low-stock-modal-title" variant="h6" component="h2">
          Low Stock Products
        </Typography>

        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Below Stock Threshold</TableCell>
                <TableCell>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <TableRow key={product.productId}>
                  <TableCell>{product.productId}</TableCell>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>{product.category}</TableCell>
                  <TableCell>{product.quantity}</TableCell>
                  <TableCell>{product.quantity_below}</TableCell>
                  <TableCell>{product.price.toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default LowStockProductsModal;

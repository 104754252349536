import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Grid,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Assignment, TrendingUp, Inventory, Group } from '@mui/icons-material';

// Mock data for the chart
const mockChartData = [
  { name: 'Jan', sales: 4000, profit: 2400 },
  { name: 'Feb', sales: 3000, profit: 1398 },
  { name: 'Mar', sales: 2000, profit: 9800 },
  { name: 'Apr', sales: 2780, profit: 3908 },
  { name: 'May', sales: 1890, profit: 4800 },
  { name: 'Jun', sales: 2390, profit: 3800 },
];

export default function AIPOSReportGenerator() {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!prompt.trim()) {
      setError('Please enter a report query');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      // Simulating an API call with a timeout
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      // Replace this with your actual API call to generate the report
      const reportResponse = `Based on your query: "${prompt}", here's the generated report:

Sales Summary:
- Total Sales: $15,780
- Number of Transactions: 423
- Average Transaction Value: $37.30

Top 5 Products:
1. Product A - 150 units
2. Product B - 120 units
3. Product C - 95 units
4. Product D - 80 units
5. Product E - 75 units

Revenue by Category:
- Electronics: $5,200
- Clothing: $4,800
- Home & Garden: $3,100
- Books: $1,500
- Others: $1,180`;

      setResponse(reportResponse);
    } catch (err) {
      setError('Failed to generate the report. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const sampleQueries = [
    "Generate a sales report for the last 30 days",
    "Show me the top 10 selling products this month",
    "What's the average daily revenue for each category?",
    "Compare this month's sales to last month",
  ];

  return (
    <Container maxWidth="lg">
  <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
        backdropFilter: "blur(10px)",           // Apply blur to the background
        display:"flex" ,   // Toggle visibility
        alignItems: "center",
        justifyContent: "center",
        zIndex: 5,                           // Ensure overlay is on top
      }}
    >
      <Box
        sx={{
          position: "relative",
          color: "white",
          textAlign: "center",
          zIndex: 1400,  // Layer on top of blur
        }}
      >
        <Typography variant="h4" component="div">
          Coming Soon
        </Typography>
        <Typography variant="body2" component="div">
        We are currently on testing phase for this functionality, Thanks for your patience
        </Typography>
        <Typography variant="body2" component="div">
        This feature will be  available for <b>FREE</b> for our first <b>200</b> customers
        </Typography>
      </Box>
    </Box>
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          AI POS Report Generator
        </Typography>
        <Typography variant="subtitle1" align="center" sx={{ mb: 4, color: 'text.secondary' }}>
          Ask questions about your POS data and get instant reports
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card elevation={3}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Query Input
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    label="Enter your report query"
                    variant="outlined"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    margin="normal"
                    multiline
                    rows={4}
                    placeholder="E.g., Generate a sales report for the last 30 days, including top-selling products and revenue by category"
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isLoading}
                    sx={{ mt: 2 }}
                  >
                    {isLoading ? <CircularProgress size={24} /> : 'Generate Report'}
                  </Button>
                </form>
              </CardContent>
            </Card>

            <Card elevation={3} sx={{ mt: 4 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Sample Queries
                </Typography>
                <List>
                  {sampleQueries.map((query, index) => (
                    <ListItem
                      key={index}
                      button
                      onClick={() => setPrompt(query)}
                    >
                      <ListItemIcon>
                        <Assignment />
                      </ListItemIcon>
                      <ListItemText primary={query} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card elevation={3}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Generated Report
                </Typography>
                {response ? (
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      bgcolor: 'background.default',
                      minHeight: '200px',
                      maxHeight: '400px',
                      overflowY: 'auto',
                    }}
                  >
                    <Typography variant="body1" component="pre" sx={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
                      {response}
                    </Typography>
                  </Paper>
                ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <Typography variant="body1" color="text.secondary">
                      Your generated report will appear here
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>

            <Card elevation={3} sx={{ mt: 4 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Sales Overview
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={mockChartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="sales" fill="#8884d8" />
                    <Bar dataKey="profit" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Divider sx={{ my: 4 }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TrendingUp color="primary" sx={{ mr: 2 }} />
                  <Typography variant="h6">Total Sales</Typography>
                </Box>
                <Typography variant="h4" sx={{ mt: 2 }}>$15,780</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Inventory color="secondary" sx={{ mr: 2 }} />
                  <Typography variant="h6">Top Product</Typography>
                </Box>
                <Typography variant="h4" sx={{ mt: 2 }}>Product A</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Group color="success" sx={{ mr: 2 }} />
                  <Typography variant="h6">Total Customers</Typography>
                </Box>
                <Typography variant="h4" sx={{ mt: 2 }}>423</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
}
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Divider,
  TextField,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  Snackbar,
  Modal,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Alert,
  Card,
  FormControlLabel,
  Switch,
  CardContent,
  useMediaQuery,
  useTheme,
  Menu,
  Chip,
  Slider,
} from '@mui/material';
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { Edit as EditIcon, Close as CloseIcon, MoreVert as MoreVertIcon, Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ThermalReceipt from './receipt';
import debounce from 'lodash.debounce';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 800,
  width: '100%',
  maxHeight: '70vh',
  overflow: "scroll",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1,
};

const formatNumber = (num) => {
  return Math.round(num).toLocaleString('en-US');
};

const ProductCard = ({ product, onAddToOrder, isWholesale, quantity, onQuantityChange, isInActiveOrder }) => (
  <Card sx={{ mb: 2 }}>
    <CardContent sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>{product.name}</Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
            Available: {product.quantity}
          </Typography>
          <Typography variant="h6" color="primary">
            KSh {formatNumber(isWholesale ? product.price_w : product.price)}
          </Typography>
        </Box>
        {isInActiveOrder ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton size="small" onClick={() => onQuantityChange(product._id, Math.max(0, quantity - 1))}>
              <RemoveIcon />
            </IconButton>
            <Typography>{quantity}</Typography>
            <IconButton size="small" onClick={() => onQuantityChange(product._id, quantity + 1)}>
              <AddIcon />
            </IconButton>
          </Box>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => onAddToOrder(product)}
            sx={{ minWidth: 80 }}
          >
            Add
          </Button>
        )}
      </Box>
    </CardContent>
  </Card>
);

export default function Component() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { id: storeIdParam } = useParams();
  const [paymentMethod, setPaymentMethod] = useState('');
  const [realStoreId, setRealStoreId] = useState(null);
  const [activeOrder, setActiveOrder] = useState(() => {
    const savedActiveOrder = localStorage.getItem(`activeOrder_${storeIdParam}`);
    return savedActiveOrder ? JSON.parse(savedActiveOrder) : { items: [], id: 0 };
  });
  const [heldOrders, setHeldOrders] = useState(() => {
    const savedHeldOrders = localStorage.getItem(`heldOrders_${storeIdParam}`);
    return savedHeldOrders ? JSON.parse(savedHeldOrders) : [];
  });
  const [nextOrderId, setNextOrderId] = useState(1);
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const token = useSelector((state) => state.auth.token);
  const BASE_URL = `${process.env.REACT_APP_BACKEND}`;
  const [barcode, setBarcode] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState(null);
  const [orderType, setOrderType] = useState('paid');
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isWholesale, setIsWholesale] = useState(false);
  const [volume, setVolume] = useState(50);

  useEffect(() => {
    if (storeIdParam) {
      setRealStoreId(storeIdParam);
      fetchProducts(storeIdParam);
    }
  }, [storeIdParam]);

  useEffect(() => {
    if (realStoreId) {
      localStorage.setItem(`activeOrder_${realStoreId}`, JSON.stringify(activeOrder));
      localStorage.setItem(`heldOrders_${realStoreId}`, JSON.stringify(heldOrders));
    }
  }, [activeOrder, heldOrders, realStoreId]);

  useEffect(() => {
    const savedMuteState = localStorage.getItem("isMuted");
    if (savedMuteState !== null) {
      const parsedMuteState = JSON.parse(savedMuteState);
      setIsMuted(parsedMuteState);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("isMuted", JSON.stringify(isMuted));
  }, [isMuted]);

  useEffect(() => {
    const fetchWithDelay = async () => {
      try {
        await fetchProducts(storeIdParam);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
  
    if (isInitialLoad) {
      fetchWithDelay();
      setIsInitialLoad(false);
    } else {
      const delayFetch = setTimeout(() => {
        fetchWithDelay();
      }, 1000);
  
      return () => clearTimeout(delayFetch);
    }
  }, [searchTerm, barcode, storeIdParam]);

  const fetchProducts = async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/product/${id}`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
        params: { name: searchTerm, barcode },
      });
      const data = response.data.products;
      setProducts(data);

      const initialQuantities = data.reduce((acc, product) => ({
        ...acc,
        [product._id]: 1,
      }), {});
      
      setSelectedQuantities(initialQuantities);
      return response.data.products;
    } catch (error) {
      console.error('Error fetching products:', error);
      setSnackbarMessage('Error fetching products');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const addToOrder = (product) => {
    const existingItem = activeOrder.items.find((item) => item._id === product._id);
    const quantity = selectedQuantities[product._id] || 1;

    setActiveOrder(prevOrder => {
      const updatedItems = existingItem
        ? prevOrder.items.map(item =>
            item._id === product._id
              ? { ...item, quantity: item.quantity + quantity }
              : item
          )
        : [...prevOrder.items, { ...product, quantity }];

      return { ...prevOrder, items: updatedItems };
    });
    playAudio();
  };

  const removeFromOrder = (productId) => {
    setActiveOrder(prevOrder => ({
      ...prevOrder,
      items: prevOrder.items.filter((item) => item._id !== productId)
    }));
  };

  const calculateTotalPrice = (items) => {
    return items.reduce((total, item) => total + (isWholesale ? item.price_w : item.price) * item.quantity, 0);
  };

  const calculateTotalPriceTax = (items) => {
    return items.reduce((total, item) => total + ((isWholesale ? item.price_w : item.price) * (item.tax / 100)) * item.quantity, 0);
  };

  const calculateTotalPriceWithTax = (items) => {
    return items.reduce((total, item) => total + ((isWholesale ? item.price_w : item.price) * (1 + item.tax / 100)) * item.quantity, 0);
  };

  const playAudio = () => {
    const audio = new Audio("../../assets/beep.mp3");
    if (!isMuted) {
      audio.volume = volume / 100;
      audio.play();
    }
  };

  const holdOrder = () => {
    if (activeOrder.items.length > 0) {
      setHeldOrders(prevHeldOrders => [...prevHeldOrders, { ...activeOrder, id: nextOrderId }]);
      setActiveOrder({ items: [], id: nextOrderId + 1 });
      setNextOrderId(prevId => prevId + 1);
    }
  };

  const resumeOrder = (orderId) => {
    if (activeOrder.items.length === 0) {
      const orderToResume = heldOrders.find(order => order.id === orderId);
      if (orderToResume) {
        setActiveOrder(orderToResume);
        setHeldOrders(prevHeldOrders => prevHeldOrders.filter(order => order.id !== orderId));
      }
    } else {
      setSnackbarMessage("Cannot resume order. Please complete or clear the current order first.");
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
    }
  };

  const handleOpen = (orderData) => {
    setOrder(orderData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const completeOrder = async () => {
    handleCloseModal();
    if (activeOrder.items.length === 0) {
      setSnackbarMessage("Cannot complete an empty order.");
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/api/orders/${realStoreId}`,
        {
          products: activeOrder.items.map(item => ({
            _id: item._id,
            productId: item.productId,
            quantity: item.quantity,
            name: item.name,
            price: item.price,
            tax: item.tax
          })),
          paymentMethod,
          paymentStatus: 'paid',
          isWholesale
        },
        {
          headers: {
            Authorization: `JWT ${token}`,
          }
        }
      );
      setOrder(response?.data);
      setOpen(true);
      setSnackbarMessage("Order completed successfully!");
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setActiveOrder({ items: [], id: nextOrderId });
      setNextOrderId(prevId => prevId + 1);
      fetchProducts(realStoreId);
    } catch (error) {
      console.error('Error completing order:', error);
      setSnackbarMessage("Error completing order. Please try again.");
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleBarcodeKeyPress = async (e) => {
    if (e.key === 'Enter') {
      const matchingProducts = await fetchProducts(storeIdParam);
      if (matchingProducts.length === 1) {
        addToOrder(matchingProducts[0]);
        setBarcode('');
        setSearchTerm('');
      } else if (matchingProducts.length === 0) {
        setSnackbarMessage('No product found with that barcode.');
        setSnackbarSeverity('warning');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Multiple products found with that barcode.');
        setSnackbarSeverity('warning');
        setSnackbarOpen(true);
      }
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleComplete = () => {
    setModalOpen(true);
  };

  const handlePayNow = async () => {
    completeOrder('paid');
    setOrderType('paid');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTogglePrice = () => {
    setIsWholesale(!isWholesale);
  };

  const handleQuantityChange = (productId, newQuantity) => {
    if (newQuantity === 0) {
      removeFromOrder(productId);
    } else {
      setActiveOrder(prevOrder => ({
        ...prevOrder,
        items: prevOrder.items.map(item =>
          item._id === productId ? { ...item, quantity: newQuantity } : item
        )
      }));
    }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: { xs: 1, sm: 2, md: 3 }, paddingBottom: 100 }}>
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>Complete Order</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Choose a payment method to complete the order:
          </Typography>
          <TextField
            select
            label="Payment Method"
            fullWidth
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            sx={{ mb: 2 }}
          >
            <MenuItem value="Cash">Cash Payment</MenuItem>
            <MenuItem value="Mobile Money">Mobile Money</MenuItem>
            <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="error" size='large' variant='contained'>
            Cancel
          </Button>
          <Button onClick={completeOrder} color="primary" size='large' variant='contained'>
            Payment Completed
          </Button>
        </DialogActions>
      </Dialog>

      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          color: 'white',
          padding: '16px 24px',
          boxShadow: 3,
          zIndex: 100,
          width: '100%',
          textAlign: 'right'
        }}
      >
        <Typography variant="h4">
          Total: {formatNumber(calculateTotalPrice(activeOrder.items))}
        </Typography>
      </Paper>

      {order && (
        <ThermalReceipt onClose={handleClose} open={open} order={order} type={orderType} />
      )}

      <Grid container spacing={2} wrap="wrap-reverse">
        <Grid item xs={12} md={7}>
          <Typography variant="h6" gutterBottom>
            Available Products
          </Typography>
            
          <Box sx={{ display: 'flex', gap: 1, marginBottom: 2 }}>
            <TextField
              label="Search Products"
              variant="outlined"
              fullWidth
              autoFocus
              size="small"
              autoComplete='off'
              value={searchTerm}
              style={{flex: 3}}
              onKeyPress={handleBarcodeKeyPress}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <TextField
              label="Barcode"
              variant="outlined"
              size="small"
              value={barcode}
              style={{flex: 2}}
              onKeyPress={handleBarcodeKeyPress}
              onChange={(e) => setBarcode(e.target.value)}
            />
          </Box>

          {isMobile ? (
            <Box sx={{ mt: 2 }}>
              {products.map((product) => (
                <ProductCard
                  key={product._id}
                  product={product}
                  onAddToOrder={addToOrder}
                  isWholesale={isWholesale}
                  quantity={activeOrder.items.find(item => item._id === product._id)?.quantity || 0}
                  onQuantityChange={handleQuantityChange}
                  isInActiveOrder={activeOrder.items.some(item => item._id === product._id)}
                />
              ))}
            </Box>
          ) : (
            <TableContainer component={Paper}>
              <Table size="small" sx={{ minWidth: 350 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell align="right">Available</TableCell>
                    <TableCell align="right">Price (KSh)</TableCell>
                    {/* <TableCell align="right">Quantity</TableCell> */}
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((product) => {
                    const isInActiveOrder = activeOrder.items.some(item => item._id === product._id);
                    const quantity = activeOrder.items.find(item => item._id === product._id)?.quantity || 0;
                    return (
                      <TableRow key={product._id}>
                        <TableCell component="th" scope="row">
                          {product.name}
                        </TableCell>
                        <TableCell align="right">{product.quantity}</TableCell>
                        <TableCell align="right">
                          {isWholesale ? formatNumber(product.price_w) : formatNumber(product.price)}
                        </TableCell>
                        {/* <TableCell align="right">
                    
                        </TableCell> */}
                        <TableCell align="right">
                          {!isInActiveOrder ? (
                            <Button
                              size='small'
                              variant="contained"
                              color="primary"
                              onClick={() => addToOrder(product)}
                              sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
                            >
                              Add
                            </Button>
                          ):    
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>
                              <IconButton size="small" onClick={() => handleQuantityChange(product._id, Math.max(0, quantity - 1))}>
                                <RemoveIcon />
                              </IconButton>
                              <Typography>{quantity}</Typography>
                              <IconButton size="small" onClick={() => handleQuantityChange(product._id, quantity + 1)}>
                                <AddIcon />
                              </IconButton>
                            </Box>
                        }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>

        <Grid item xs={12} md={5}>
          <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Typography variant="h6" gutterBottom>
              Active Order
              {isWholesale && <Chip color='error' variant='outlined' label={"Wholesale Price"} size="small" style={{margin:3}}/>}
            </Typography>
            <IconButton onClick={handleMenuOpen} size="small">
              <MoreVertIcon />
            </IconButton>
          </div>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem>
                <FormControlLabel
                  control={<Switch checked={isWholesale} onChange={handleTogglePrice} />}
                  label="Use Wholesale Price"
                />
              </MenuItem>
              <MenuItem>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  {isMuted ? (
                    <VolumeOffIcon color="action" />
                  ) : (
                    <VolumeUpIcon color="primary" />
                  )}
                  <Switch
                    checked={!isMuted}
                    onChange={() => setIsMuted(!isMuted)}
                    color="primary"
                    inputProps={{ "aria-label": "Toggle sound" }}
                  />
                  <Typography variant="body1">{isMuted ? "Sound Off" : "Sound On"}</Typography>
                </Box>
              </MenuItem>
              <MenuItem>
                <Box sx={{ width: '100%', px: 2 }}>
                  <Typography id="volume-slider" gutterBottom>
                    Volume
                  </Typography>
                  <Slider
                    value={volume}
                    onChange={(e, newValue) => setVolume(newValue)}
                    aria-labelledby="volume-slider"
                    valueLabelDisplay="auto"
                    min={0}
                    max={100}
                    disabled={isMuted}
                  />
                </Box>
              </MenuItem>
            </Menu>
          </Box>

          {isMobile ? (
            <Box>
              {activeOrder.items.map((item, index) => (
                <Card key={index} sx={{ mb: 2 }}>
                  <CardContent sx={{ p: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                          {item.name}
                        </Typography>
                        <Grid container spacing={1} sx={{ mt: 1 }}>
                          <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">
                              Quantity: {item.quantity}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">
                              Price: KSh {formatNumber(isWholesale ? item.price_w : item.price)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Typography variant="subtitle2" sx={{ mt: 1, color: 'primary.main' }}>
                          Total: KSh {formatNumber((isWholesale ? item.price_w : item.price) * item.quantity)}
                        </Typography>
                      </Box>
                      <IconButton
                        size='small'
                        color="error"
                        onClick={() => removeFromOrder(item._id)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </CardContent>
                </Card>
              ))}
              {activeOrder.items.length > 0 && (
                <Card sx={{ mb: 2, }}>
                  <CardContent>
                    <Typography variant="h6" sx={{ color: 'white' }}>
                      Total: KSh {formatNumber(calculateTotalPriceWithTax(activeOrder.items))}
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Box>
          ) : (
            <TableContainer component={Paper}>
              <Table size="small" sx={{ minWidth: 250 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell align="right">Qty</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Total</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activeOrder.items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {item.name}
                      </TableCell>
                      <TableCell align="right">{item.quantity}</TableCell>
                      <TableCell align="right">
                        {formatNumber(isWholesale ? item.price_w : item.price)}
                      </TableCell>
                      <TableCell align="right">
                        {formatNumber((isWholesale ? item.price_w : item.price) * item.quantity)}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          size='small'
                          color="error"
                          onClick={() => removeFromOrder(item._id)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={3} align="right">
                      <strong>Total (KSh)</strong>
                    </TableCell>
                    <TableCell align="right">
                      <Typography component={'div'}>
                        {formatNumber(calculateTotalPriceWithTax(activeOrder.items))}
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {activeOrder.items.length > 0 && <Divider sx={{ marginTop: 2, marginBottom: 2 }} />}

          {activeOrder.items.length > 0 && (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' }, 
              gap: { xs: 1, sm: 2 }, 
              mb: 2 
            }}>
              <Button
                variant="outlined"
                color="error"
                fullWidth
                onClick={() => setActiveOrder({ items: [], id: activeOrder.id })}
                sx={{ fontSize: { xs: '0.75rem', sm: '1rem' } }}
              >
                Clear Order
              </Button>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={holdOrder}
                sx={{ fontSize: { xs: '0.75rem', sm: '1rem' } }}
              >
                Hold Order
              </Button>
            </Box>
          )}

          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
          
          <Button
            variant="contained"
            color="success"
            fullWidth
            onClick={handleComplete}
            sx={{ 
              marginBottom: 1, 
              fontSize: { xs: '0.875rem', sm: '1rem' },
              py: { xs: 1.5, sm: 1 }
            }}
          >
            Complete Sale
          </Button>

          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

          <Typography variant="h6" gutterBottom>
            Held Orders
          </Typography>
          
          {heldOrders.map((order) => (
            <Accordion key={order.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  Order #{order.id} - KSh {formatNumber(calculateTotalPrice(order.items))}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {isMobile ? (
                  <Box>
                    {order.items.map((item, index) => (
                      <Card key={index} sx={{ mb: 1 }}>
                        <CardContent sx={{ py: 1 }}>
                          <Typography variant="subtitle2">{item.name}</Typography>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                            <Typography variant="body2">Qty: {item.quantity}</Typography>
                            <Typography variant="body2">
                              KSh {formatNumber((isWholesale ? item.price_w : item.price) * item.quantity)}
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    ))}
                  </Box>
                ) : (
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Product</TableCell>
                          <TableCell align="right">Qty</TableCell>
                          <TableCell align="right">Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {order.items.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell align="right">{item.quantity}</TableCell>
                            <TableCell align="right">
                              {formatNumber((isWholesale ? item.price_w : item.price) * item.quantity)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => resumeOrder(order.id)}
                  sx={{ 
                    marginTop: 2,
                    py: { xs: 1.5, sm: 1 }
                  }}
                >
                  Resume Order
                </Button>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
  
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
import React, { useEffect, useState } from 'react';
import { Grid, Box, TextField, Button, Typography, Card, CardContent, Snackbar, Alert, Tabs, Tab } from '@mui/material';
import { useDispatch,useSelector } from 'react-redux';
import { clearUser, setUser } from '../../redux/slices/userSlice';
import { setToken ,clearToken} from '../../redux/slices/authSlice';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate, useParams } from 'react-router-dom';
import { Google as GoogleIcon } from '@mui/icons-material';
import {jwtDecode}  from 'jwt-decode';
import axios from 'axios';
function LoginDemo() {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: storeIdParam } = useParams(); // Get the store ID from the URL parameters
const token=useSelector(state=>state.auth.token)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'error' });
  const [tabValue, setTabValue] = useState(0); // State for tab selection
  const [storeId, setStoreId] = useState(storeIdParam || ''); // Editable Store ID state
  const [storeName, setStoreName] = useState(''); // State for the fetched store name
  const [storeIdFetched, setStoreIdFetched] = useState(false); // State to check if store ID has been fetched

  // Function to fetch the store details
  const fetchRealStoreId = async () => {
    try {
      const token = localStorage.getItem('token'); // Assuming token is stored in local storage
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/store/get-object-id/${storeId}`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      // Update state with fetched store name
      setStoreName(response.data.store.name); // Adjust according to your response structure
      setStoreIdFetched(true); // Mark as fetched
      
    } catch (error) {
      console.error('Error fetching store details:', error);
      setAlert({ open: true, message: 'Failed to fetch store details. Please check the Store ID.', severity: 'error' });
    }
  };

  // Fetch store details when the storeId changes
  useEffect(() => {
    // dispatch(clearToken());
    // dispatch(clearUser());
    // persistor.purge(); 
    // dispatch(clearToken())
    // console.log(token)
    // if (storeId) {
    //   fetchRealStoreId();
    // }
  }, [storeId]);

  // Switch to store login tab if storeId is set
  useEffect(() => {
    if (storeIdParam) {
      setTabValue(1); // Switch to Store Login tab
      setStoreId(storeIdParam); // Set storeId from params
    }
  }, [storeIdParam]);

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleEmailPasswordSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/store/`+storeId+`/subaccount/login`, {
       
        email,
        password,
        storeId, // Include storeId in the request
      });

      console.log('Login successful!', response.data);
      dispatch(setToken(response.data.token));
      dispatch(setUser(response.data.user));

      await localStorage.setItem('subaccount', 'true');
      navigate('/stores'); 
    } catch (error) {
      console.error('Error logging in:', error);
      setAlert({ open: true, message: error.response?.data?.message || 'Login failed. Please try again.', severity: 'error' });
    }
  };

  const handleGoogleLogin = async (credentialResponse) => {
    dispatch(clearToken());
    // dispatch(clearUser());
 
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/auth/demo`, 
        {  email:"johndoe@gmail.com",
          picture:"https://img.freepik.com/premium-photo/head-shot-portrait-smiling-african-american-man-wearing-glasses-making-video-call-looking-camera_908985-13611.jpg",
          name:"John doe",}
      );

      console.log('Google Login successful!', response.data);
      dispatch(setToken(response.data.token));
      dispatch(setUser(response.data.user));
      // const decoded = jwtDecode(response.data.token); // Decodes the JWT without verifying it
  // console.log(decoded);
  // if(decoded.type=='subaccount')
      await localStorage.setItem('subaccount', 'false');
      navigate('/stores'); 
    } catch (error) {
      console.error('Error logging in with Google:', error);
      setAlert({ open: true, message: error.response?.data?.message || 'Google login failed. Please try again.', severity: 'error' });
    }
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <>
    {/* {JSON.stringify(user)}
    {JSON.stringify(token)} */}
      <Grid container sx={{ minHeight: '100vh' }}>
       <a href="../../">
        <div style={{left:10,top:10,position:"fixed",display:"flex",alignItems:"center"}}>
          <img src='../../assets/logo.png' style={{height:40}}/> 
          <Typography variant='h6'>Amberspace</Typography>
        </div>
       </a>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1647427017067-8f33ccbae493?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cG9pbnQlMjBvZiUyMHNhbGV8ZW58MHx8MHx8fDA%3D)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: { xs: 'none', md: 'block' }, // Hide on small screens
          }}
        />

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        
            backgroundImage: { xs: 'url(https://images.unsplash.com/photo-1647427017067-8f33ccbae493?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cG9pbnQlMjBvZiUyMHNhbGV8ZW58MHx8MHx8fDA%3D)', md: 'url(https://img.freepik.com/free-vector/white-abstract-background_23-2148810352.jpg)' },
            //   'url(assets/images/banner.jpg)',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
    
              backgroundColor: { xs: 'rgba(255, 255, 255, 0.8)', md: 'transparent' }, // Background for small screens
            
            // backgroundColor: { xs: 'rgba(255, 255, 255, 0.8)', md: 'transparent' },
            boxShadow: { xs: 3, md: 'none' },
            borderRadius: 2,
            p: { xs: 2, sm: 4 },
          }}
        >
          <Card sx={{ width: '100%', maxWidth: 400 }}>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: "center", alignItems: "center" }}>
                <img className="img-responsive" height="30px" src={'../../assets/logo.png'} alt="Logo" />
                <Typography variant='h5'>Armada (Demo)</Typography>
              </Box>

              {/* Tabs for Login Type */}
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => setTabValue(newValue)}
                variant="fullWidth"
              >
                <Tab label="Google Login" />
                <Tab label="Store Login" />
              </Tabs>

              {tabValue === 0 && ( // Google Login Tab
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                 
                   <Button
    variant="outlined"
    startIcon={<GoogleIcon />}
    onClick={()=>{handleGoogleLogin()}}
    sx={{
      textTransform: 'none',
      borderColor: '#4285F4',
      color: '#4285F4',
      '&:hover': {
        borderColor: '#357ae8',
        backgroundColor: '#e8f0fe',
      },
    }}
  >
    Sign in with Google
  </Button>
                </Box>
              )}

              {tabValue === 1 && ( // Email/Password Login Tab
                <Box>
                  <Typography variant="h5" component="h1" gutterBottom>
                    Sign In
                  </Typography>
                  <form onSubmit={handleEmailPasswordSubmit}>
                   
                    {/* <TextField
                      label="Store Name"
                      variant="outlined"
                      fullWidth
                      value={storeName} // Display the store name
                      disabled // Make it read-only
                      sx={{ marginBottom: 2 }}
                    /> */}
                    <TextField
                      label="Email"
                      name='email'
                      variant="outlined"
                      fullWidth
                      required
                      value={email}
                      onChange={handleEmailChange}
                      sx={{ marginBottom: 2 }}
                    />
                    <TextField
                      label="Password"
                      type="password"
                      variant="outlined"
                      fullWidth
                      required
                      value={password}
                      onChange={handlePasswordChange}
                      sx={{ marginBottom: 2 }}
                    />
                     <TextField
                      label="Store ID"
                      variant="outlined"
                      fullWidth
                      name='storeid'
                      required
                      value={storeId} // Editable Store ID
                      onChange={(e) => setStoreId(e.target.value)} // Make it editable
                      sx={{ marginBottom: 2 }}
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                      Login
                    </Button>
                  </form>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Snackbar for alerts */}
      <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default LoginDemo;

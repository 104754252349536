import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import {
  Table,
  Tooltip,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress,
  Pagination,
  Alert,
  Tabs,
  Tab,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery,
  Menu,
  Chip,
  MenuItem,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, Search,MoreVert  } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReceiptModal from './receiptModal'; // Adjust the import path accordingly
// import _ from 'lodash';

import debounce from 'lodash.debounce';

function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}


function ExpandableRow({ row, onClick, onOpenReceipt, onCancelOrder }) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCancelClick = () => {
    handleMenuClose();
    setOpenConfirmDialog(true);
  };

  const handleConfirmCancel = () => {
    onCancelOrder(row.orderId);
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <TableRow onClick={() => onClick(row)} style={{ cursor: 'pointer' }}>
        <TableCell>
          <IconButton onClick={(e) => { e.stopPropagation(); setOpen(!open); }}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.orderId} {row.paymentStatus === 'canceled' && (
            <Chip
              label="Canceled"
              color="error"
              size="small"
                  variant="outlined"
              style={{ marginLeft: '5px' }}
            />
          )}</TableCell>
        <TableCell>
          <Tooltip title={row.seller.email} arrow>
            <span>{row.seller.name}</span>
          </Tooltip>
        </TableCell>
        <TableCell>{`Ksh ${row.totalAmount.toFixed(2)}`}</TableCell>
        <TableCell>{formatTimestamp(row.createdAt)}</TableCell>
        <TableCell>
          <IconButton onClick={handleMenuOpen}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={(e) => {
              e.stopPropagation();
              handleMenuClose();
              onOpenReceipt(row);
            }}>
              Receipt
            </MenuItem>
            <MenuItem onClick={handleCancelClick}>Cancel Order</MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Products in Order
              </Typography>
              <Table size="small" aria-label="products">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Product ID</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.products.map((product, index) => (
                    <TableRow key={index}>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>{product.productId}</TableCell>
                      <TableCell style={{ textAlign: 'right' }}>{product.quantity}</TableCell>
                      <TableCell style={{ textAlign: 'right' }}>{`Ksh ${product.price.toFixed(2)}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirm Order Cancellation</DialogTitle>
        <DialogContent>
          Are you sure you want to cancel this order?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>No</Button>
          <Button onClick={handleConfirmCancel} color="error">Yes, Cancel Order</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default function OrdersTable() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { id: storeIdParam } = useParams();
  const token = useSelector((state) => state.auth.token);
  const BASE_URL = `${process.env.REACT_APP_BACKEND}`;
  const [realStoreId, setRealStoreId] = useState(null);
  const [value, setValue] = useState(0); // State for tab selection
  const [selectedOrder, setSelectedOrder] = useState(null); // State for selected order
  const [openReceiptModal, setOpenReceiptModal] = useState(false); // State for receipt modal
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [showSearch, setShowSearch] = useState(false);
  // const [searchOrderId, setSearchOrderId] = useState('');
  // const [searchSeller, setSearchSeller] = useState('');
  // const [searchAmount, setSearchAmount] = useState('');
  // const [searchDate, setSearchDate] = useState('');

  const toggleSearch = () => {
    setShowSearch((prev) => !prev);
  };
  useEffect(() => {
    if (storeIdParam) {
      fetchRealStoreId();
    }
  }, [storeIdParam, page]);

  const fetchRealStoreId = async () => {
    setRealStoreId(storeIdParam);
    await fetchOrders(storeIdParam);
  };
  const [searchStatus, setSearchStatus] = useState('');
  const [searchOrderId, setSearchOrderId] = useState('');
  const [searchSeller, setSearchSeller] = useState('');
  const [searchMinAmount, setSearchMinAmount] = useState('');
  const [searchMaxAmount, setSearchMaxAmount] = useState('');
  const [searchDateFrom, setSearchDateFrom] = useState('');
  const [searchDateTo, setSearchDateTo] = useState('');
  const [searchProductName, setSearchProductName] = useState(''); // For product name
  const [searchBarcode, setSearchBarcode] = useState('');
const [totalOrders,setTotalOrders] =useState('0');

  
const fetchOrders = async (id) => {
  setLoading(true);
  const searchParams = {
    orderId: searchOrderId,
    seller: searchSeller,
    minAmount: searchMinAmount,
    maxAmount: searchMaxAmount,
    dateFrom: searchDateFrom,
    dateTo: searchDateTo,
    productName: searchProductName,
    barcode: searchBarcode,
    paymentStatus: searchStatus, // Add this line
  }
  try {
    const params = new URLSearchParams({
      page: page,
      limit: 10,
      ...searchParams,
    }).toString();

    const response = await axios.get(`${BASE_URL}/api/orders/${id ?? realStoreId}?${params}`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
    setTotalOrders(response.data.totalOrders)
    setOrders(response.data.orders);
    setTotalPages(response.data.totalPages);
    setError(null);
  } catch (err) {
    console.error('Error fetching orders:', err);
    setError('Failed to fetch orders. Please try again later.');
  } finally {
    setLoading(false);
  }
};
  // const handleSearch = useCallback(() => {
  //   //  setPage(1)
  //   fetchOrders(storeIdParam);
   
  // }, [searchOrderId, searchSeller, searchMinAmount, searchMaxAmount, searchDateFrom, searchDateTo, searchProductName, searchBarcode]);

  // Create a debounced version of the handleSearch function
  // const debouncedSearch = useMemo(() => _.debounce(handleSearch, 1000), [handleSearch]);

  // useEffect(() => {
  //   debouncedSearch();
  //   return () => debouncedSearch.cancel();
  // }, [debouncedSearch]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    // Define the fetch function
    const fetchWithDelay = async () => {
      try {
        await fetchOrders(storeIdParam);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
  
    if (isInitialLoad) {
      // Fetch immediately on initial load
      fetchWithDelay();
      setIsInitialLoad(false); // Mark initial load as done
    } else {
      // Delay fetch for subsequent changes
      const delayFetch = setTimeout(() => {
        fetchWithDelay();
      }, 1000); // 2000ms delay
  
      // Clear timeout if dependencies change before delay completes
      return () => clearTimeout(delayFetch);
    }
  }, [storeIdParam, searchOrderId, searchSeller,searchStatus, searchMinAmount, searchMaxAmount, searchDateFrom, searchDateTo, searchProductName, searchBarcode]);
  
  // fetchOrders(storeIdParam, searchParams);
  const handleInputChange = (setter) => (e) => {
    setter(e.target.value)
  
  
  };
  const handlePageChange = (event, value) => {
    setPage(value);
    // debouncedSearch()
  };

  const filteredOrders = orders.filter(
    (order) =>
      order._id.includes(search) ||
      order.seller.name.toLowerCase().includes(search.toLowerCase()) ||
      order.seller.email.toLowerCase().includes(search.toLowerCase())
  );

  const handleOrderClick = (order) => {
    setSelectedOrder(order); // Set the selected order to display in the modal
  };

  const handleOpenReceiptModal = (order) => {
    setSelectedOrder(order);
    setOpenReceiptModal(true);
  };

  const handleCloseReceiptModal = () => {
    setOpenReceiptModal(false);
    setSelectedOrder(null);
  };
  const handleCloseModal = () => {
    setSelectedOrder(null); // Close the modal by resetting selected order
  };

  // if (loading) {
  //   return <CircularProgress />;
  // }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  const productsInOrders = orders?.flatMap(order => 
    order?.products.map(product => ({
      ...product,
      orderId: order.orderId,
      seller: order.seller,
      paymentStatus: order.paymentStatus,
      createdAt: order.createdAt
    }))
  );

  const filteredProducts = productsInOrders.filter(
    (product) =>
      product.name.toLowerCase().includes(search.toLowerCase()) ||
      product.productId.includes(search)
  );

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const clearSearchFields = () => {
    setSearchOrderId('');
    setSearchSeller('');
    setSearchMinAmount('');
    setSearchMaxAmount('');
    setSearchDateFrom('');
    setSearchDateTo('');
    setSearchProductName('');
    setSearchBarcode('');
    setSearchStatus(''); // Add this line
  };
  const handleCancelOrder = async (orderId) => {
    try {
      await axios.put(`${BASE_URL}/api/orders/`+storeIdParam+`/${orderId}/cancel`, {}, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      // Refresh the orders after cancellation
      fetchOrders(storeIdParam);
    } catch (err) {
      console.error('Error cancelling order:', err);
      setError('Failed to cancel order. Please try again later.');
    }
  };
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

 const  [selectedOrderID,setSelectedOrderID]=useState(null)


  const handleCancelClick = () => {

    setOpenConfirmDialog(true);
  };

  const handleConfirmCancel = () => {
    // onCancelOrder(row.orderId);
    setOpenConfirmDialog(false);
  };
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Tabs value={value} onChange={(event, newValue) => {setValue(newValue)}} indicatorColor="primary" textColor="primary">
          <Tab label="Orders" />
          <Tab label="Products" />
        </Tabs>
      </Box>

      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
   <div style={{display:'flex',flexDirection:'column',width:'100%',padding:10}}>   
    <div style={{width:'100%',display:'flex',justifyContent:'space-between'}}>
       <Typography variant="h5" gutterBottom style={{ marginBottom: '16px',alignSelf:"start" }}>
    All Orders
  </Typography>
  <div style={{display:'flex',alignItems:'center',flexDirection:'row',justifyContent:'center'}}>
   {/* <Typography variant='h6'>({(totalOrders)})</Typography> */}
  <Button onClick={toggleSearch} size="small" variant="outlined" style={{ marginBottom: '16px',alignSelf:"end" }}>
        {showSearch ? 'Hide Search' : 'Show Search'}
      </Button> 
  </div>
  
    </div>
  
  <div style={{marginBottom:0,display:'flex',alignItems:'center',flexDirection:'row',justifyContent:'space-between'}}>
{      showSearch!=true ? <TextField
          fullWidth style={{maxWidth:320}}
          label="Order ID"
          variant="outlined"
          value={searchOrderId}
          onChange={handleInputChange(setSearchOrderId)}
          size="small"
          InputLabelProps={{ shrink: true }}
        /> :<div></div>}
          <Typography variant='h6'>({(totalOrders)+" Orders"})</Typography> </div>
      {showSearch && (  <Paper style={{ padding: '16px', marginBottom: '20px',}}>
  <Typography variant="h6" gutterBottom>
    Search Orders
  </Typography>
  <Grid container spacing={2} style={{ marginBottom: '16px' }}>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Order ID"
          variant="outlined"
          value={searchOrderId}
          onChange={handleInputChange(setSearchOrderId)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Seller"
          variant="outlined"
          value={searchSeller}
          onChange={handleInputChange(setSearchSeller)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Min Amount"
          variant="outlined"
          value={searchMinAmount}
          onChange={handleInputChange(setSearchMinAmount)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Max Amount"
          variant="outlined"
          value={searchMaxAmount}
          onChange={handleInputChange(setSearchMaxAmount)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Date From"
            type="date"
          variant="outlined"
          value={searchDateFrom}
          onChange={handleInputChange(setSearchDateFrom)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
            type="date"
          label="Date To"
          variant="outlined"
          value={searchDateTo}
          onChange={handleInputChange(setSearchDateTo)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Product Name"
          variant="outlined"
          value={searchProductName}
          onChange={handleInputChange(setSearchProductName)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      {/* <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Barcode"
          variant="outlined"
          value={searchBarcode}
          onChange={handleInputChange(setSearchBarcode)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
    </Grid> */}
      <Grid item xs={12} sm={3}>
              <TextField
                select
                fullWidth
                label="Payment Status"
                variant="outlined"
                value={searchStatus}
                onChange={handleInputChange(setSearchStatus)}
                size="small"
                InputLabelProps={{ shrink: true }}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="paid">Paid</MenuItem>
                <MenuItem value="canceled">Canceled</MenuItem>
              </TextField>
            </Grid>
      <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined" color="secondary" onClick={clearSearchFields}>
          Clear Search
        </Button>
      </Grid>
    </Grid>

</Paper>)}  </div>
        {/* <TextField
          autoFocus
          variant="outlined"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          size="small"
        /> */}
      </Box>
      <Dialog 
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirm Order Cancellation</DialogTitle>
        <DialogContent>
          Are you sure you want to cancel this order?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>No</Button>
          <Button onClick={()=>{handleCancelOrder(selectedOrderID);setOpenConfirmDialog(false)}} color="error">Yes, Cancel Order</Button>
        </DialogActions>
      </Dialog>
  
      {value === 0 && (
        <>
          {isSmallScreen ? (
            <Grid container spacing={2}>
              {filteredOrders.map((order) => (
                <Grid item xs={12} sm={6} md={4} key={order._id}>
                <Card 
               
                 sx={{ cursor: 'pointer', mb: 2 }}>
  <CardContent>
    <Typography variant="h6" gutterBottom>
      Order ID: {order.orderId}   {order.paymentStatus === 'canceled' && (
            <Chip
              label="Canceled"
              color="error"
              size="small"
                  variant="outlined"
              style={{ marginLeft: '5px' }}
            />
          )}
    </Typography>
    <Typography variant="body2">
      <strong>Seller:</strong> {order.seller.name} ({order.seller.email})
    </Typography>
    <Typography variant="body2" sx={{ mt: 1 }}>
      <strong>Order Date:</strong> {formatTimestamp(order.createdAt)}
    </Typography>
    <Typography
      variant="h6"
      sx={{ mt: 2, color: 'success.main', fontWeight: 'bold' }}
    >
      Total Amount:Ksh {order.totalAmount.toFixed(2)}
    </Typography>
    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
      <Button
        size="small"
        // onClick={(e) => {
        //   e.stopPropagation();
        //   handleOpenReceiptModal(order);
        // }}
        onClick={() => handleOrderClick(order)}
      >
        Open
      </Button>
      <Button
        size="small"
        color="error"
        // onClick={()=>}
        onClick={(e) => {
          e.stopPropagation();
          setSelectedOrderID(order.orderId)
          handleCancelClick()
        }}
      >
        Cancel Order
      </Button>
   
    </Box>
  </CardContent>
</Card>

                </Grid>
              ))}
            </Grid>
          ) : (
            <TableContainer component={Paper}>
              <Table size="small" aria-label="orders table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Order ID</TableCell>
                    <TableCell>Seller</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>Order Date</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredOrders.map((row, index) => (
                    <ExpandableRow 
                      key={index} 
                      row={row} 
                      onClick={handleOrderClick} 
                      onOpenReceipt={handleOpenReceiptModal}
                      onCancelOrder={handleCancelOrder}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
               variant="outlined"
        shape="rounded"
            />
          </Box>
        </>
      )}
      
      {value === 1 && (
        <>
          {isSmallScreen ? (
            <Grid container spacing={2}>
              {filteredProducts.map((product) => (
                <Grid item xs={12} sm={6} md={4} key={product.productId}>
        <Card sx={{ mb: 2 }}>
  <CardContent>
    <Typography variant="h6" gutterBottom>
      {product.name}
    </Typography>
    <Typography variant="body2">
      <strong>Product ID:</strong> {product.productId}
    </Typography>
    <Typography variant="body2" sx={{ mt: 1 }}>
      <strong>Quantity:</strong> {product.quantity}
    </Typography>
    <Typography
      variant="h6"
      sx={{ mt: 2, color: 'success.main', fontWeight: 'bold' }}
    >
      Price: Ksh {product.price.toFixed(2)}
    </Typography>
    <Typography variant="body2" sx={{ mt: 1 }}>
      <strong>Order ID:</strong> {product.orderId}   {product.paymentStatus === 'canceled' && (
            <Chip
              label="Canceled"
              color="error"
              size="small"
                  variant="outlined"
              style={{ marginLeft: '5px' }}
            />
          )}
    </Typography>
    <Typography variant="body2" sx={{ mt: 1 }}>
      <strong>Seller:</strong> {product.seller.name}
    </Typography>
    <Typography variant="body2" sx={{ mt: 1 }}>
      <strong>Order Date:</strong> {new Date(product.createdAt).toLocaleString()}
    </Typography>
  </CardContent>
</Card>

                </Grid>
              ))}
            </Grid>
          ) : (
            <TableContainer component={Paper}>
              <Table size="small" aria-label="products in orders table">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Product ID</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Order ID</TableCell>
                    <TableCell>Seller</TableCell>
                    <TableCell>Order Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProducts.map((product, index) => (
                    <TableRow key={index}>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>{product.productId}</TableCell>
                      <TableCell>{product.quantity}</TableCell>
                      <TableCell>{`Ksh ${product.price.toFixed(2)}`}</TableCell>
                      <TableCell>{product.orderId}    {product.paymentStatus  === 'canceled' && (
            <Chip
              label="Canceled"
              color="error"
              size="small"
                  variant="outlined"
              style={{ marginLeft: '5px' }}
            />
          )}</TableCell>
                      <TableCell>
                        <Tooltip title={product.seller.email} arrow>
                          <span>{product.seller.name}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{new Date(product.createdAt).toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
                variant="outlined"
        shape="rounded"
            />
          </Box>
        </>
      )}

      {/* Modal for Order Details, only show on small screens */}
      {isSmallScreen && (
        <Dialog open={Boolean(selectedOrder)} onClose={handleCloseModal} maxWidth="md" fullWidth>
          <DialogTitle>Order Details</DialogTitle>
          <DialogContent sx={{ p: 1 }}>
            {selectedOrder && (
              <Grid container spacing={1}>
                {selectedOrder.products.map((product, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
               <Card sx={{ mb: 2 }}>
  <CardContent>
    <Typography variant="h6" gutterBottom>
      {product.name}
    </Typography>
    <Typography variant="body2">
      <strong>Product ID:</strong> {product.productId}
    </Typography>
    <Typography variant="body2" sx={{ mt: 1 }}>
      <strong>Quantity:</strong> {product.quantity}
    </Typography>
    <Typography
      variant="h6"
      sx={{ mt: 2, color: 'success.main', fontWeight: 'bold' }}
    >
      Price: Ksh {product.price.toFixed(2)}
    </Typography>
    <Typography variant="body2" sx={{ mt: 1 }}>
      <strong>Order Date:</strong> {formatTimestamp(selectedOrder.createdAt)}
    </Typography>
  </CardContent>
</Card>

                  </Grid>
                ))}
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
             <Button size="small" variant="outlined" onClick={() => handleOpenReceiptModal(selectedOrder)} >Receipt</Button>
            <Button size="small" onClick={handleCloseReceiptModal}>Close</Button>
           
        
          </DialogActions>
        </Dialog>
      )}

      {/* Receipt Modal */}
      {selectedOrder && (
        <ReceiptModal
          order={selectedOrder}
          open={openReceiptModal}
          onClose={handleCloseReceiptModal}
        />
      )}
    </>
  );
}

import React, { useState, useCallback } from 'react';
import {
  Button,
  Modal,
  Box,
  TextField,
  Typography,
  IconButton,
  Grid,
  Card,
  CardMedia,
  Radio,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

export default function Component({ onImageSelect = () => {} }) {
  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [savedImages, setSavedImages] = useState([

    {url:"https://img.freepik.com/free-photo/view-3d-cool-modern-bird_23-2150946449.jpg"},
    {url:"https://c4.wallpaperflare.com/wallpaper/313/134/489/space-computer-1920x1200-desktop-wallpaper-preview.jpg"},
    {url:"https://img.freepik.com/premium-photo/futuristic-glowing-red-light-blue-gray-metallic-d-squares-background_208978-8965.jpg?semt=ais_hybrid"},
    {url:"https://www.pixelstalk.net/wp-content/uploads/image10/Cool_girl_wallpaper_HD_for_PC.jpg"},
    {url:"https://img.freepik.com/premium-photo/cool-cat-high-definition-photography-creative-background-wallpaper_993236-9654.jpg"},
    {url:"https://e0.pxfuel.com/wallpapers/647/366/desktop-wallpaper-leopard-face-%E2%9D%A4-for-ultra-tv-%E2%80%A2-wide-u-3840-2160.jpg"}
  ]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleImageUrlChange = (event) => {
    setImageUrl(event.target.value);
  };

  const handleSaveUrl = () => {
    if (imageUrl && !savedImages.some(img => img.url === imageUrl)) {
      setSavedImages([...savedImages, { url: imageUrl }]);
      setImageUrl('');
    }
  };

  const handleImageSelection = useCallback((index) => {
    setSelectedImageIndex(index);
    onImageSelect(savedImages[index].url);
    console.log(savedImages)
    handleClose()
  }, [savedImages, onImageSelect]);

  const handleRemoveImage = (index, event) => {
    event.stopPropagation();
    const newSavedImages = savedImages.filter((_, i) => i !== index);
    setSavedImages(newSavedImages);
    if (selectedImageIndex === index) {
      setSelectedImageIndex(null);
      onImageSelect(null);
    } else if (selectedImageIndex !== null && selectedImageIndex > index) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Button  onClick={handleOpen}>
     Select image
      </Button>
      {selectedImageIndex !== null && (
        <Box
          sx={{
            // width: 100,
            // height: 100,
            marginTop:8,
            borderRadius: 1,
            overflow: 'hidden',
            boxShadow: 1,
          }}
        >
        
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 800,
            maxHeight: '90vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="image-modal-title" variant="h6" component="h2" gutterBottom>
            Image Viewer
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <TextField
              fullWidth
              label="Image URL"
              variant="outlined"
              value={imageUrl}
              onChange={handleImageUrlChange}
            />
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleSaveUrl}
              disabled={!imageUrl}
            >
              Add
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
            <Grid container spacing={2}>
              {savedImages.map((image, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      position: 'relative',
                      height: 200,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleImageSelection(index)}
                  >
                    <CardMedia
                      component="img"
                      image={image.url}
                      alt={`Saved image ${index + 1}`}
                      sx={{
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                    <Radio
                      checked={selectedImageIndex === index}
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        },
                      }}
                    />
                    <IconButton
                      aria-label="remove image"
                      onClick={(e) => handleRemoveImage(index, e)}
                      sx={{
                        position: 'absolute',
                        top: 8,
                        left: 8,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
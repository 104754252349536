import React, { useState ,useEffect} from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Container, 
  Grid, 
  Card, Paper,
  CardContent, Modal,
  CardActions, 
  Button, 
  ThemeProvider, 
  createTheme,
  Box,
  CardMedia,
  TextField,
  Link as MuiLink,
  Chip,
  IconButton, useMediaQuery,
  useTheme,    Drawer,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { 

  Snackbar,
  Alert 
} from '@mui/material';
import axios from 'axios';
import { Business, Menu as MenuIcon,ShoppingCart, Work, LocalHospital, School, AccountBalance, Email, Phone, LocationOn, ArrowBack, ArrowForward } from '@mui/icons-material';
// import Link from 'next/link';
import { useSpring, animated } from 'react-spring';
import { Link, useNavigate } from 'react-router-dom';
import ImageModal from './demo';
import { Helmet } from "react-helmet-async";
import { clearToken } from '../redux/slices/authSlice';
import { persistor } from '../redux/store';
import { useDispatch, useSelector } from 'react-redux';
// Create a custom theme with amber as the primary color
const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2', // Blue
        light: '#63a4ff', // Light blue
        dark: '#004ba0', // Dark blue
        contrastText: '#ffffff', // White text for contrast
      },
      secondary: {
        main: '#ffca28', // Amber or other secondary color
      },
    },
  });
// Define the system data
const systems = [
  { name: 'POS System (Armada)', description: 'Efficient point of sale system for retail businesses', icon: <ShoppingCart />, image: 'https://images.pexels.com/photos/6205519/pexels-photo-6205519.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1', available: true },
  { name: 'Job Application System', description: 'Streamlined hiring process for employers and applicants', icon: <Work />, image: 'https://images.pexels.com/photos/3184459/pexels-photo-3184459.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1', available: false },
  { name: 'Hospital Management System', description: 'Comprehensive solution for healthcare facilities', icon: <LocalHospital />, image: 'https://images.pexels.com/photos/6129111/pexels-photo-6129111.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1', available: false },
  { name: 'Bursary Management', description: 'Simplified bursary allocation and tracking', icon: <AccountBalance />, image: 'https://images.pexels.com/photos/901964/pexels-photo-901964.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1', available: false },
  { name: 'School Management System', description: 'All-in-one platform for educational institutions', icon: <School />, image: 'https://images.pexels.com/photos/2982449/pexels-photo-2982449.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1', available: false },
];

const heroSlides = [
  {
    title: "Welcome to Amberspace",
    subtitle: "Innovative Solutions for Modern Business",
      image: "https://images.pexels.com/photos/8555677/pexels-photo-8555677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",

  },
  {
    title: "Streamline Your Operations",
    subtitle: "Boost Efficiency with Our Advanced Systems",
      image: "https://images.pexels.com/photos/6476588/pexels-photo-6476588.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    title: "Empower Your Business",
    subtitle: "Cutting-edge Tools for Growth and Success",
    image: "https://images.pexels.com/photos/5439392/pexels-photo-5439392.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
];

const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  export default function AmberspaceSystems() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [drawerOpen, setDrawerOpen] = useState(false);
  
    const toggleDrawer = () => {
      setDrawerOpen(!drawerOpen);
    };
    const [currentSlide, setCurrentSlide] = useState(0);
    const handleDemo = (systemName) => {
        alert(`Launching demo for ${systemName}`);
        // In a real application, this would navigate to the demo page or launch the demo
      };
      const dispatch = useDispatch();
 const user = useSelector((state) => state.user);
    const navigate=useNavigate()
      const handleUse = (systemName) => {
       
   
        // alert(`Redirecting to ${systemName} login page`);
        var email=user.email;
    
        //   dispatch(clearUser());
         
          if(email=="johndoe@gmail.com"){
             dispatch(clearToken());
          persistor.purge(); 
      
        }


        navigate('/stores');
        // In a real application, this would navigate to the system's login or dashboard page
      };
    
    useEffect(() => {
      const slideInterval = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % heroSlides.length);
      }, 5000); // Change slide every 5 seconds
  
      return () => clearInterval(slideInterval); // Cleanup interval on component unmount
    }, []);
  
    const nextSlide = () => {
      setCurrentSlide((prev) => (prev + 1) % heroSlides.length);
    };
  
    const prevSlide = () => {
      setCurrentSlide((prev) => (prev - 1 + heroSlides.length) % heroSlides.length);
    };
  
    const heroAnimation = useSpring({
      from: { opacity: 0, transform: 'translateX(-50px)' },
      to: { opacity: 1, transform: 'translateX(0)' },
      reset: true,
      key: currentSlide,
    });
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      message: 'Demo user'
    });
    const [messages, setMessages] = useState([]);
    const [snackbar, setSnackbar] = useState({
      open: false,
      message: '',
      severity: 'success'
    });
  
    // Handle input changes
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    };
  
    // Handle form submission
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND}/api/messages`, formData);
        setSnackbar({
          open: true,
          message: 'Message sent successfully!',
          severity: 'success'
        });
        // Clear form
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: ''
        });
        // Refresh messages
        // fetchMessages();
        navigate("/pos/demo")
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Error sending message!',
          severity: 'error'
        });
      }
    };
  
    // Fetch existing messages
    const fetchMessages = async () => {
      try {
        const response = await axios.get(`{process.env.REACT_APP_BACKEND}/api/messages`);
        setMessages(response.data);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };
  
    useEffect(() => {
      fetchMessages();
    }, []);
    const [open, setOpen] = useState(false);
    return (<> <Helmet>
   
        <title>Amberspace Technologies - Innovative Software Solutions</title>
        <meta name="description" content="Amberspace Technologies provides cutting-edge software solutions, including Armada POS, school systems, SACCO systems, and ERP solutions for diverse industries." />
        <meta name="keywords" content="Amberspace Technologies, software company, POS system, school management software, SACCO systems, ERP solutions" />
        <meta property="og:title" content="Amberspace Technologies" />
        <meta property="og:description" content="Discover Amberspace Technologies' suite of solutions, from POS and ERP to school and SACCO systems." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://amberspace.co.ke" />
        <meta property="og:image" content="https://amberspace.co.ke" />
      </Helmet>
      <ThemeProvider theme={theme}>
        
        <Box sx={{ minHeight: '100vh' }}>
        <AppBar position="sticky" color="default" elevation={1} sx={{ bgcolor: 'background.paper', }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'text.primary',display:'flex',alignItems:'center' }}>
        <img src='../../assets/logo.png' style={{height:40}}/>  Amberspace
        </Typography>

        {isMobile ? (
          <>
            <IconButton edge="end" color="inherit" onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer} sx={{width:250,'& .MuiDrawer-paper': { width: 250 } }}>
              <List>
                {['Home', 'About', 'Systems','Affiliate',  'Contact'].map((item) => (
                  <ListItem button key={item} onClick={() => {
                    toggleDrawer();
                    scrollToSection(item.toLowerCase());
                  }}>
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {['Home', 'About', 'Systems','Affiliate',  'Contact'].map((item) => (
              <Button
                key={item}
                sx={{ color: 'text.primary' }}
                onClick={() => scrollToSection(item.toLowerCase())}
              >
                {item}
              </Button>
            ))}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  
          {/* Hero Section with Carousel */}
          <Box
            id="home"
            sx={{
              position: 'relative',
              height: '80vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              textAlign: 'center',
              backgroundImage: `url(${heroSlides[currentSlide].image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'background-image 0.5s ease-in-out',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
            />
            <animated.div style={heroAnimation}>
              <Box sx={{ position: 'relative', zIndex: 1 }}>
                <Typography variant="h2" component="h1" gutterBottom>
                  {heroSlides[currentSlide].title}
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                  {heroSlides[currentSlide].subtitle}
                </Typography>
                <Button 
                  variant="contained" 
                  color="primary" 
                  size="large" 
                  onClick={() => scrollToSection('systems')}
                  sx={{ '&:hover': { bgcolor: 'secondary.main' } }}
                >
                  Explore Our Systems
                </Button>
              </Box>
            </animated.div>
            <IconButton
              sx={{ position: 'absolute', left: 20, color: 'white' }}
              onClick={prevSlide}
            >
              <ArrowBack />
            </IconButton>
            <IconButton
              sx={{ position: 'absolute', right: 20, color: 'white' }}
              onClick={nextSlide}
            >
              <ArrowForward />
            </IconButton>
          </Box>
         


        {/* About Section */}
        <Box sx={{ py: 8, bgcolor: 'background.paper'  }} id="about"> {/* Amber 50 */}
          <Container maxWidth="lg">
            <Typography variant="h4" component="h2" gutterBottom align="center">
              About Amberspace
            </Typography>
            <Typography variant="body1" paragraph align="center">
              Amberspace is a leading provider of innovative business solutions. We specialize in developing cutting-edge systems that streamline operations, enhance productivity, and drive growth for businesses across various industries.
            </Typography>
            <Typography variant="body1" paragraph align="center">
              Our team of experts combines deep industry knowledge with advanced technology to deliver tailored solutions that meet the unique needs of each client. From point-of-sale systems to comprehensive school management platforms, we're committed to empowering businesses with the tools they need to succeed in today's competitive landscape.
            </Typography>
          </Container>
        </Box>

        {/* Systems Section */}
        <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }} id="systems">
          <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ mb: 6 }}>
            Our Solutions
          </Typography>
 
      {/* <ImageModal open={open} onClose={() => setOpen(false)} images={ [
    '../assets/1.png',
    '../assets/2.png',
    '../assets/3.png',
    '../assets/4.png',
    '../assets/5.png'
  ]} /> */}
      <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',

          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth:400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5">Complete form </Typography>
        <Grid item xs={12} md={6}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    variant="outlined"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    variant="outlined"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    variant="outlined"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    inputProps={{
                      pattern: "[0-9]{10}",
                      title: "Please enter a valid 10-digit phone number"
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Message"
                    name="message"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formData.message}
                    onChange={handleChange}
                    required
                    style={{display:'none'}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                  >
                    Proceed to Demo
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
      </Box>
    </Modal>
          <Grid container spacing={4}>
            {systems.map((system,i) => (
              <Grid item xs={12} sm={6} md={4} key={system.name}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardMedia
                    component="img"
                    height="180"
                    image={system.image}
                    alt={`${system.name} illustration`}
                    sx={{ objectFit: 'cover', aspectRatio: '16/9' }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    {React.cloneElement(system.icon, { fontSize: 'large', color: 'primary', sx: { mb: 2 } })}
                    <Typography variant="h5" component="div" gutterBottom>
                      {system.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {system.description}
                    </Typography>
                    {system.available ? (
                      <Chip label="Available" color="primary" sx={{ mt: 2 }} />
                    ) : (
                      <Chip label="Coming Soon" sx={{ mt: 2 }} />
                    )}
                  </CardContent>
                  <CardActions style={{alignItems:"center",display:'flex',}}>
                    <Button 
                      size="small" 
                        variant="outlined" 
                      color="primary"
                      onClick={() => {
if(i==0){
  setOpen(true)
 
}

                      }}
                     // onClick={() => handleDemo(system.name)}
                      fullWidth
                    //   sx={{ mb: 1 }}
                    >
                      Try Demo
                    </Button>
                    {system.available && (
                      <Button 
                        size="small" variant="contained" 
                      
                        color="primary"
                        onClick={() => handleUse(system.name)}
                        fullWidth
                      >
                        Use System
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Container sx={{ py: 8 }} id="affiliate">
  <Typography variant="h4" component="h2" gutterBottom>
    Become an Affiliate
  </Typography>
  <Typography variant="body1" paragraph>
    Join our affiliate program and start earning by promoting Amberspace systems. As an affiliate, 
    you'll get a unique referral link to share with your audience. Every sale you generate earns you a commission, and for every client who pays for at least one month, you'll receive 100% of their first-month payment!
  </Typography>

  <Grid container spacing={4}>
    <Grid item xs={12} sm={6} md={4}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h6" component="h3" gutterBottom>
          Earn 100% of the First Month
        </Typography>
        <Typography variant="body2" paragraph>
          For every client you refer that subscribes and pays for at least one month, you'll receive 100% of their first month's payment as your commission!
        </Typography>
      </Paper>
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h6" component="h3" gutterBottom>
          Free to Join
        </Typography>
        <Typography variant="body2" paragraph>
          Our affiliate program is completely free to join. No hidden costs, just opportunities to earn.
        </Typography>
      </Paper>
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h6" component="h3" gutterBottom>
          Track Your Sales
        </Typography>
        <Typography variant="body2" paragraph>
          Use your affiliate dashboard to track clicks, sales, and commissions in real-time.
        </Typography>
      </Paper>
    </Grid>
  </Grid>

  <Box sx={{ mt: 4, textAlign: 'center' }}>
    <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={() => window.location.href = '/affiliate'}
    >
      Join as an Affiliate
    </Button>
  </Box>
</Container>

        {/* Contact Section */}
        <Box sx={{ bgcolor: 'background.paper', py: 8 }} id="contact">
      <Container maxWidth="md">
        <Typography variant="h4" component="h2" gutterBottom align="center">
          Contact Us
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    variant="outlined"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    variant="outlined"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    variant="outlined"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    inputProps={{
                      pattern: "[0-9]{10}",
                      title: "Please enter a valid 10-digit phone number"
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Message"
                    name="message"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                  >
                    Send Message
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="h6">Get in Touch</Typography>
              {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Email color="primary" />
                <Typography>info@amberspace.com</Typography>
              </Box> */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Phone color="primary" />
                <Typography>+254101165327</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LocationOn color="primary" />
                <Typography>Nairobi, Kenya</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>

        {/* Footer */}
        <Box component="footer" sx={{  bgcolor: '#f5f5f5', color: 'text.primary', py: 6 }}> {/* Amber 100 */}
          <Container maxWidth="lg">
            <Grid container spacing={4} justifyContent="space-between">
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" gutterBottom>
                  Amberspace
                </Typography>
                <Typography variant="body2">
                  Empowering businesses with innovative solutions since 2023.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" gutterBottom>
                  Quick Links
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {['Home', 'About', 'Systems','Affiliate',  'Contact'].map((item) => (
                    <Link href={`#${item.toLowerCase()}`} key={item} passHref>
                      <MuiLink color="inherit" sx={{ '&:hover': { color: 'secondary.main' } }}>
                        {item}
                      </MuiLink>
                    </Link>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" gutterBottom>
                  Connect With Us
                </Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {['Facebook', 'Twitter', 'LinkedIn', 'Instagram'].map((social) => (
                    <MuiLink href="#" key={social} color="inherit" sx={{ '&:hover': { color: 'secondary.main' } }}>
                      {social}
                    </MuiLink>
                  ))}
                </Box>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Typography variant="body2" align="center">
                © {new Date().getFullYear()} Amberspace Systems. All rights reserved.
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
    </>
    
  );
}
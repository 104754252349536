import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useDebounce } from 'use-debounce';
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Modal,
  TextField,
  Snackbar,
  Alert,
  Grid,
  useMediaQuery,
  Card,
  CardContent,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  Menu,
  MenuItem,
  Badge,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import InventoryIcon from '@mui/icons-material/Inventory';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsMenu from './stockSettings';
import CategoryModal from './categories';
import LogTable from './logpage';
import LowStockProductsModal from './belowStock';
function ProductCard({ item, settings, handleOpenModal, handleOpenStockChangeModal, handleDeleteProduct }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        borderRadius: 2,
        boxShadow: 2,
        // bgcolor: '#f9f9f9',
        transition: '0.3s',
        // '&:hover': {
        //   transform: 'scale(1.02)',
        //   boxShadow: 4,
        // },
      }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom color="primary">
          {item.name}
        </Typography>
        <Typography variant="body2">
          <strong>Quantity:</strong> {item.quantity}
        </Typography>

        {settings?.outOfStockAlert && (
          <Typography variant="body2" sx={{ mt: 1, color: 'error.main' }}>
            <strong>Below Stock Qty:</strong> {item.quantity_below}
          </Typography>
        )}

        <Typography
          variant="h6"
          sx={{ mt: 2, color: 'success.main', fontWeight: 'bold' }}
        >
          Price: Ksh {item.price}
        </Typography>

        {settings.enableWholesalePrice && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            <strong>Price (Wholesale):</strong> Ksh {item.price_w}
          </Typography>
        )}

        {settings?.setTax && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            <strong>Tax:</strong> {item.tax}%
          </Typography>
        )}

        {settings?.setBarcode && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            <strong>Barcode:</strong> {item.barcode}
          </Typography>
        )}

        <Typography variant="body2" sx={{ mt: 1 }}>
          <strong>Category:</strong> {item.category}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          <strong>Description:</strong> {item.description}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          <strong>Size:</strong> {item.size}
        </Typography>
        {settings?.setBuyingPrice && ( <Typography variant="body2" sx={{ mt: 1 }}>
          <strong>Buying Price:</strong> {item.buyingPrice}
        </Typography>)}
        {/* Price Button Menu */}
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'end' }}>
          <Button
            // variant="contained"
            // color="primary"
            size="small"
            onClick={handleMenuClick}
            endIcon={<MoreVertIcon />}
            sx={{ textTransform: 'none' }}
          >
            Options
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={() => { handleOpenModal(item); handleMenuClose(); }}>
              <EditIcon sx={{ mr: 1 }} />
              Edit Product
            </MenuItem>
            <MenuItem onClick={() => { handleOpenStockChangeModal(item); handleMenuClose(); }}>
              <InventoryIcon sx={{ mr: 1 }} />
              Manage Stock
            </MenuItem>
            <MenuItem onClick={() => { handleDeleteProduct(item._id); handleMenuClose(); }}>
              <DeleteIcon sx={{ mr: 1 }} />
              Delete Product
            </MenuItem>
          </Menu>
        </Box>
      </CardContent>
    </Card>
  );
}

export default function Component() {
  const { id: storeIdParam } = useParams();
  const token = useSelector((state) => state.auth.token);
  const BASE_URL = process.env.REACT_APP_BACKEND;

  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchParams, setSearchParams] = useState({
    name: '',
    category: '',
    description: '',
    barcode: '',
    quantityMin: '',
    quantityMax: '',
    priceMin: '',
    priceMax: '',
    page: 1,
    limit: 10
  });
  
  const [debouncedSearchParams] = useDebounce(searchParams, 1000);

  const initialSearchParams = { ...searchParams };

  const [openModal, setOpenModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [realStoreId, setRealStoreId] = useState(null);
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
  const [showSearch, setShowSearch] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalProducts, setTotalProducts] = useState('0');
  const [categories, setCategories] = useState([]);
  const [settings, setSettings] = useState({
    setTax: false,
    enableWholesalePrice: false,
    outOfStockAlert: false,
    setBarcode: false,
    setBuyingPrice:false,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [stockChangeModal, setStockChangeModal] = useState(false);
  const [stockChangeItem, setStockChangeItem] = useState(null);
  const [stockChangeType, setStockChangeType] = useState('add');
  const [stockChangeAmount, setStockChangeAmount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuItem, setMenuItem] = useState(null);
  const [showTable, setShowTable] = useState(false);

  const handleClearFields = () => {
    setSearchParams(initialSearchParams);
  };

  const fetchRealStoreId = async () => {
    setRealStoreId(storeIdParam);
  };

  const handlePageChange = (event, newPage) => {
    setSearchParams(prev => ({
      ...prev,
      page: newPage
    }));
  };

  const fetchProducts = useCallback(async () => {
    if (!realStoreId) return;

    try {
      const response = await axios.get(`${BASE_URL}/api/product/${realStoreId}`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
        params: debouncedSearchParams,
      });
      
      setTotalPages(response.data.totalPages);
      setItems(response.data.products);
      setTotalProducts(response.data.totalProducts);
      setFilteredItems(response.data.products);
    } catch (error) {
      console.error('Error fetching products:', error);
      setAlert({ open: true, message: 'Error fetching products.', severity: 'error' });
    }
  }, [BASE_URL, realStoreId, token, debouncedSearchParams]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/category/${storeIdParam}`);
      setCategories(response.data.map(item => item.name));
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchSettings = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/store/settings/${storeIdParam}`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      setSettings({
        setTax: false,
        enableWholesalePrice: false,
        outOfStockAlert: false,
        setBarcode: false,
        setBuyingPrice:false,
        ...response.data
      });
    } catch (error) {
      console.error('Error fetching settings:', error);
      setSettings({
        setTax: false,
        enableWholesalePrice: false,
        outOfStockAlert: false,
        setBarcode: false,
        setBuyingPrice:false,
      });
    }
  };

  useEffect(() => {
    fetchRealStoreId();
  }, [storeIdParam]);

  useEffect(() => {
    if (realStoreId) {
      fetchProducts();
      fetchCategories();
      fetchSettings();
    }
  }, [realStoreId, debouncedSearchParams, fetchProducts]);

  const handleOpenModal = (item) => {
    setCurrentItem(item);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentItem(null);
  };

  const handleUpdateStock = async (e) => {
    e.preventDefault();
    const updatedItem = {
      ...currentItem,
      name: e.target.name.value,
      quantity: parseInt(e.target.quantity.value),
      quantity_below: parseFloat(e.target?.quantity_below?.value ?? 0),
      barcode: e.target?.barcode?.value ?? "",
      tax: parseInt(e.target?.tax?.value ?? 0) ?? 0,
      category: e.target.category.value,
      description: e.target.description.value,
      size: e.target.size.value,
      price: parseFloat(e.target.price.value),
      price_w: parseFloat(e.target?.price_w?.value ?? e.target.price.value),
      buyingPrice: parseInt(e.target?.buyingPrice?.value ?? 0) ?? 0,
    };

    try {
      await axios.put(`${BASE_URL}/api/product/update-product/${realStoreId}/${currentItem._id}`, updatedItem, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      fetchProducts();
      handleCloseModal();
      setAlert({ open: true, message: 'Product updated successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error updating product:', error);
      const errorMessage = error.response?.data?.message || 'Error updating product.';
      setAlert({ open: true, message: errorMessage, severity: 'error' });
    }
  };

  const handleSaveNewItem = async (e) => {
    e.preventDefault();
    const newItem = {
      name: e.target.name.value,
      quantity: parseInt(e.target.quantity.value),
      quantity_below: parseFloat(e.target?.quantity_below?.value ?? 0),
      barcode: e.target?.barcode?.value ?? "",
      tax: parseInt(e.target?.tax?.value ?? 0) ?? 0,
      category: e.target.category.value,
      description: e.target.description.value,
      size: e.target.size.value,
      price: parseFloat(e.target.price.value),
      price_w: parseFloat(e.target?.price_w?.value ?? e.target.price.value),
      buyingPrice: parseInt(e.target?.buyingPrice?.value ?? 0) ?? 0,
    };

    try {
      await axios.post(`${BASE_URL}/api/product/add-product/${realStoreId}`, newItem, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      fetchProducts();
      handleCloseModal();
      setAlert({ open: true, message: 'Product added successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error adding product:', error);
      const errorMessage = error.response?.data?.message || 'Error adding product.';
      setAlert({ open: true, message: errorMessage, severity: 'error' });
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await axios.delete(`${BASE_URL}/api/product/delete-product/${realStoreId}/${productId}`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      fetchProducts();
      setAlert({ open: true, message: 'Product deleted successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error deleting product:', error);
      const errorMessage = error.response?.data?.message || 'Error deleting product.';
      setAlert({ open: true, message: errorMessage, severity: 'error' });
    }
  };

  const handleAlertClose = () => {
    setAlert({ ...alert, open: false });
  };

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleOpenStockChangeModal = (item) => {
    setStockChangeItem(item);
    setStockChangeModal(true);
  };

  const handleCloseStockChangeModal = () => {
    setStockChangeModal(false);
    setStockChangeItem(null);
    setStockChangeType('add');
    setStockChangeAmount(0);
  };

  const handleStockChange = async () => {
    try {
      const change = stockChangeType === 'add' ? stockChangeAmount : -stockChangeAmount;
      await axios.put(
        `${BASE_URL}/api/product/update-stock-2/${realStoreId}/${stockChangeItem._id}`,
        { change, type: stockChangeType },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      );
      fetchProducts();
      handleCloseStockChangeModal();
      setAlert({ open: true, message: 'Stock updated successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error updating stock:', error);
      const errorMessage = error.response?.data?.message || 'Error updating stock.';
      setAlert({ open: true, message: errorMessage, severity: 'error' });
    }
  };

  const handleMenuOpen = (event, item) => {
    setAnchorEl(event.currentTarget);
    setMenuItem(item);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuItem(null);
  };

  const toggleTableVisibility = () => {
    setShowTable(prevShowTable => !prevShowTable);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box sx={{ padding: 2 }}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center', gap: 10, marginBottom: 15 }}>
        <Typography variant="h5" gutterBottom>
          Inventory
        </Typography>
        <div style={{ flex: 1 }}></div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'end', flexWrap: 'wrap', alignItems: 'center', gap: 10, minWidth: 250 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            size='small'
            onClick={() => setOpenModal(true)}
          >
            Add Item
          </Button>
          <Button 
            onClick={toggleSearch} 
            size="small" 
            variant="outlined"
          >
            {showSearch ? 'Hide Search By' : 'Search By'}
          </Button>
        </div>
        <SettingsMenu settings={settings} setModalOpen2={() => setModalOpen(true)} setSettings={setSettings} />
        <CategoryModal open={modalOpen} onClose={() => setModalOpen(false)} storeId={storeIdParam} />
      </div>
      <div style={{ marginBottom: 0, display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
        {!showSearch ? (
          <>
            {Object.keys(searchParams).map((key, i) => {
              if (i === 0) {
                return (
                  <div key={key} style={{ marginBottom: 20, maxWidth: 500 }}>
                    <TextField
                      label="Search by name"
                      variant="outlined"
                      value={searchParams[key]}
                      onChange={(e) => setSearchParams({ ...searchParams, [key]: e.target.value })}
                      fullWidth
                      size="small"
                      autoComplete="off"
                    />
                  </div>
                );
              }
              return null;
            })}
          </>
        ) : <div></div>}
        <Typography variant='h6'>({totalProducts} Products)</Typography>
      </div>

      {showSearch && (
        <Paper style={{ padding: '16px', marginBottom: '20px' }}>
          <Typography variant="h6" gutterBottom>
            Search Inventory
          </Typography>
          <Grid container spacing={2} style={{ marginBottom: '16px' }}>
            {Object.keys(searchParams).map((key, i) => {
              if (key !== 'page' && key !== 'limit') {
                return (
                  <Grid item xs={12} sm={3} key={key}>
                    <TextField
                      label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                      variant="outlined"
                      value={searchParams[key]}
                      onChange={(e) => setSearchParams({ ...searchParams, [key]: e.target.value })}
                      fullWidth
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      autoComplete="off"
                    />
                  </Grid>
                );
              }
              return null;
            })}
            <Grid item xs={12} sm={12}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClearFields}
                sx={{ marginTop: 1, float: 'right' }}
              >
                Clear Fields
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}

      {isSmallScreen ? (
        <Grid container spacing={2}>
          {filteredItems.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item._id}>
        <ProductCard
        item={item}
        settings={settings}
        handleOpenModal={handleOpenModal}
        handleOpenStockChangeModal={handleOpenStockChangeModal}
        handleDeleteProduct={handleDeleteProduct}
      />

            </Grid>
          ))}
        </Grid>
      ) : (
        <TableContainer component={Paper}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>Quantity</TableCell>
                {settings?.outOfStockAlert && <TableCell>Below stock Qty</TableCell>}
                <TableCell>Price</TableCell>
                {settings.enableWholesalePrice && <TableCell>Wholesale</TableCell>}

                {settings?.setBuyingPrice&& <TableCell>Buying price</TableCell>}
                {settings?.setTax && <TableCell>Tax</TableCell>}
                {settings?.setBarcode && <TableCell>Barcode</TableCell>}
                <TableCell>Category</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems.map((item) => (
                <TableRow key={item._id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  {settings?.outOfStockAlert && <TableCell>{item.quantity_below}</TableCell>}
                  <TableCell>{item.price}</TableCell>
                  {settings.enableWholesalePrice && <TableCell>{item.price_w}</TableCell>}
                  {settings?.setBuyingPrice && <TableCell>{item.buyingPrice}</TableCell>}
                  {settings?.setTax && <TableCell>{item.tax}%</TableCell>}
                  {settings?.setBarcode && <TableCell>{item.barcode}</TableCell>}
                  <TableCell>{item.category}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.size}</TableCell>
                  <TableCell>
                  <IconButton
                    aria-label="more"
                    id={`long-button-${item._id}`}
                    aria-controls={`long-menu-${item._id}`}
                    aria-expanded={Boolean(anchorEl)}
                    aria-haspopup="true"
                    onClick={(event) => handleMenuOpen(event, item)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
   
  
      )}
   
       <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => {
            handleOpenModal(menuItem);
            handleMenuClose();
          }}>
            <EditIcon fontSize="small" style={{ marginRight: '8px' }} />
            Edit
          </MenuItem>
          <MenuItem onClick={() => {
            handleOpenStockChangeModal(menuItem);
            handleMenuClose();
          }}>
            <InventoryIcon fontSize="small" style={{ marginRight: '8px' }} />
            Update Stock
          </MenuItem>
          <MenuItem onClick={() => {
            handleDeleteProduct(menuItem._id);
            handleMenuClose();
          }}>
            <DeleteIcon fontSize="small" style={{ marginRight: '8px' }} />
            Delete
          </MenuItem>
        </Menu>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={totalPages}
          page={searchParams.page}
          onChange={handlePageChange}
          color="primary"
          variant="outlined"
          shape="rounded"
        />
      </Box>

      <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <form onSubmit={currentItem ? handleUpdateStock : handleSaveNewItem}>
            <TextField
              name="name"
              label="Product Name"
              fullWidth
              margin="normal"
              defaultValue={currentItem?.name || ''}
            />
            <div style={{display:"flex",gap:10}}>
              <TextField
                name="quantity"
                label="Quantity"
                style={{flex:1}}
                fullWidth
                margin="normal"
                type="number"
                defaultValue={currentItem?.quantity || ''}
              />
              {settings?.outOfStockAlert && (
                <TextField
                  name="quantity_below"
                  style={{flex:1}}
                  label="Below stock Qty"
                  required
                  fullWidth
                  margin="normal"
                  type="number"
                  defaultValue={currentItem?.quantity_below ?? 0}
                />
              )}
            </div>
            {settings?.setBarcode && (
              <TextField
                name="barcode"
                label="Barcode"
                fullWidth
                margin="normal"
                defaultValue={currentItem?.barcode || ''}
              />
            )}
            <TextField
              name="category"
              label="Category"
              fullWidth
              margin="normal"
              select
              defaultValue={currentItem?.category || ''}
              SelectProps={{
                native: true,
              }}
            >
              <option value=""></option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </TextField>
            <TextField
              name="description"
              label="Description"
              fullWidth
              margin="normal"
              defaultValue={currentItem?.description || ''}
            />
            <TextField
              name="size"
              label="Size"
              fullWidth
              margin="normal"
              defaultValue={currentItem?.size || ''}
            />
            <div style={{display:"flex",gap:10}}>
              <TextField
                style={{flex:1}}
                name="price"
                label="Price (Retail)"
                fullWidth
                margin="normal"
                type="number"
                defaultValue={currentItem?.price || 0}
              />
              {settings?.enableWholesalePrice && (
                <TextField
                  style={{flex:1,}}
                  name="price_w"
                  label="Price (Wholesale)"
                  fullWidth
                  margin="normal"
                  type="number"
                  defaultValue={currentItem?.price_w ?? currentItem?.price}
                />
              )}
            </div>
            {settings?.setBuyingPrice && (
              <TextField
                name="buyingPrice"
                label="Buying price per Unit"
                required
                fullWidth
                type="number"
                // InputProps={{
                //   inputProps: {
                //     min: 0,
                //     max: 100,
                //     step: "0.01",
                //   },
                // }}
                margin="normal"
                defaultValue={currentItem?.buyingPrice ?? 0}
              />
            )}
            {settings?.setTax && (
              <TextField
                name="tax"
                label="Tax(%)"
                required
                fullWidth
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 100,
                    step: "0.01",
                  },
                }}
                margin="normal"
                defaultValue={currentItem?.tax ?? 0}
              />
            )}
            <div style={{display:"flex",justifyContent:"end",gap:10}} >
                   <Button onClick={()=>handleCloseModal()}  sx={{ mt: 2 }}>
             Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              {currentItem ? 'Update' : 'Add'} Product
            </Button>
            </div>
            
          </form>
        </Box>
      </Modal>

      <Dialog open={stockChangeModal} onClose={handleCloseStockChangeModal}>
        <DialogTitle>Update Stock for {stockChangeItem?.name}</DialogTitle>
        <DialogContent>
          <RadioGroup
            row
            value={stockChangeType}
            onChange={(e) => setStockChangeType(e.target.value)}
          >
            <FormControlLabel value="add" control={<Radio />} label="Add" />
            <FormControlLabel value="deduct" control={<Radio />} label="Deduct" />
          </RadioGroup>
          <TextField
            autoFocus
            margin="dense"
            label="Amount"
            type="number"
            fullWidth
            value={stockChangeAmount}
            onChange={(e) => setStockChangeAmount(parseInt(e.target.value))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStockChangeModal}>Cancel</Button>
          <Button onClick={handleStockChange} variant="contained" color="primary">
            Update Stock
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{display:"flex",marginTop:50,justifyContent:"space-between",gap:10,flexWrap:"wrap",}}>
        <Button size='small' variant="outlined" onClick={toggleTableVisibility}>
          {showTable ? 'Hide Logs Table' : 'Show Logs'}
        </Button>
<StoreDashboard/>
        </div>
      {showTable && (   <LogTable/>)}
    </Box>
  );
}

const StoreDashboard = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [lowStockCount, setLowStockCount] = useState(0);
  const storeId = 'your-store-id'; // Replace with actual store ID
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  // Fetch the count of low-stock products

  const token = useSelector((state) => state.auth.token);
  const { id: storeIdParam } = useParams();
  const BASE_URL = `${process.env.REACT_APP_BACKEND}`;
  const fetchLowStockCount = async (page, limit) => {
    try {
      const response = await axios.get(BASE_URL+`/api/below_stock/${storeIdParam}`, {
        params: { page: page + 1, limit }
      },{ headers: {
        Authorization: `JWT ${token}`,
      },});
      console.log(response.data)
      setLowStockCount(response.data.totalItems);
    } catch (error) {
      console.error('Error fetching low-stock products:', error);
    }
  };
  // Fetch low-stock products from API
  useEffect(() => {
    fetchLowStockCount(page, rowsPerPage);
  }, []);



  return (
    <div>
      <Badge badgeContent={lowStockCount} color="error">
        <Button  size='small' variant="outlined" onClick={() => setModalOpen(true)}>
Low Stock Products
        </Button>
      </Badge>

      <LowStockProductsModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        storeId={storeId}
      />
    </div>
  );
};

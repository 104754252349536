import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Typography, Pagination, CircularProgress, Tooltip, Chip, TextField, Box
} from '@mui/material';
import { useSelector } from 'react-redux';
import UserList from './users';

const StoreList = () => {
    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 10; // Items per page
    const [search, setSearch] = useState('');
    const [activeStatus, setActiveStatus] = useState('all'); // 'active', 'inactive', 'all'
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(search); // New state for debounced search

    const token = useSelector((state) => state.auth.token);
    const BASE_URL = process.env.REACT_APP_BACKEND;

    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
    
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    // Effect to handle debouncing
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 1000); // Delay of 2 seconds

        // Cleanup function to clear timeout if the component unmounts or search changes
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        const fetchStores = async () => {
            setLoading(true);
            try {
                // Prepare the query parameters
                const params = new URLSearchParams();
                params.append('page', page);
                params.append('limit', limit);
                if (debouncedSearchTerm) { // Use the debounced search term
                    params.append('email', debouncedSearchTerm);
                }
                // Only include the active status if it's not 'all'
                if (activeStatus !== 'all') {
                    params.append('active', activeStatus === 'active' ? 'true' : 'false');
                }
    
                const response = await axios.get(`${BASE_URL}/api/stores/all?${params.toString()}`, {
                    headers: {
                        Authorization: `JWT ${token}`,
                    },
                });
                setStores(response.data.stores);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error('Error fetching stores:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchStores();
    }, [page, debouncedSearchTerm, activeStatus]); // Use debouncedSearchTerm here
    
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const handleStatusChange = (status) => {
        setActiveStatus(status);
    };

    return (
        <>
            <Paper elevation={3} sx={{ p: 2,m:2 }}>
                <Typography variant="h5" gutterBottom>Stores List</Typography>
                <Box sx={{ mb: 2 }}>
                    <TextField 
                        label="Search by Email" 
                        variant="outlined" 
                        value={search} 
                        onChange={handleSearchChange} 
                        sx={{ mr: 2 }}
                             size="small"
                    />
                    <Chip 
                        label="All" 
                        color={activeStatus === 'all' ? 'primary' : 'default'} 
                        onClick={() => handleStatusChange('all')} 
                        sx={{ cursor: 'pointer' }}
                    />
                    <Chip 
                        label="Active" 
                        color={activeStatus === 'active' ? 'success' : 'default'} 
                        onClick={() => handleStatusChange('active')} 
                        sx={{ mr: 1, cursor: 'pointer' }}
                    />
                    <Chip 
                        label="Inactive" 
                        color={activeStatus === 'inactive' ? 'warning' : 'default'} 
                        onClick={() => handleStatusChange('inactive')} 
                        sx={{ mr: 1, cursor: 'pointer' }}
                    />
                </Box>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <TableContainer component={Paper}>
                            <Table size="small" sx={{ minWidth: 650, '& tbody tr:nth-of-type(odd)': { backgroundColor: '#f9f9f9' } }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>Store Name</TableCell>
                                        <TableCell>User</TableCell>
                                        <TableCell>Location</TableCell>
                                        <TableCell>Expiry Date</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Subaccounts</TableCell>
                                        <TableCell>Customers</TableCell>
                                        <TableCell>Suppliers</TableCell>
                                        <TableCell>Products</TableCell>
                                        <TableCell>Orders</TableCell>
                                        <TableCell>Expenditures</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stores.map((store, index) => {
                                        const isActive = new Date(store.expiryDate) > new Date();
                                        return (
                                            <TableRow key={store.storeId}>
                                                <TableCell>{(page - 1) * limit + index + 1}</TableCell>
                                                <TableCell>
                                                    <Tooltip title={store.description || 'No description'}>
                                                        <span>{store.name}</span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title={store.user.email}>
                                                        <span>{store.user.name}</span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>{store.location || 'N/A'}</TableCell>
                                                <TableCell>{store.expiryDate ? formatTimestamp(store.expiryDate) : 'N/A'}</TableCell>
                                                <TableCell>
                                                    <Chip label={isActive ? 'Active' : 'Inactive'} color={isActive ? 'success' : 'warning'} />
                                                </TableCell>
                                                <TableCell>{store.counts.subaccountsCount}</TableCell>
                                                <TableCell>{store.counts.customersCount}</TableCell>
                                                <TableCell>{store.counts.suppliersCount}</TableCell>
                                                <TableCell>{store.counts.productsCount}</TableCell>
                                                <TableCell>{store.counts.ordersCount}</TableCell>
                                                <TableCell>{store.counts.expendituresCount}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handlePageChange}
                            sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
                        />
                    </>
                )}
            </Paper>
            <UserList />
        </>
    );
};

export default StoreList;

import React, { useState, useEffect,useCallback,useMemo } from 'react';
import axios from 'axios';
import {
  Table,
  Tooltip,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,Chip,
  Paper,MenuItem,
  IconButton,
  Collapse,
  Box,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress,
  Pagination,
  Alert,
  Tabs,
  Tab,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery,Avatar
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp,  ArrowBack, ArrowForward, AttachMoney, ShoppingCart } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReceiptModal from './receiptModal'; // Adjust the import path accordingly
// import _ from 'lodash';
import CountUp from 'react-countup';

import debounce from 'lodash.debounce';
import { green, blue, orange, red } from '@mui/material/colors';
const SummaryCard = ({ title, value, icon, color }) => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item>
            <Avatar sx={{ bgcolor: color }}>
              {icon}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="h4">
                {value.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              {/* <CountUp start={0} end={value} duration={2.75} separator="," /> */}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function ExpandableRow({ row, onClick, onOpenReceipt }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow onClick={() => onClick(row)} style={{ cursor: 'pointer' }}>
        <TableCell>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.orderId}  {row.paymentStatus === 'canceled' && (
            <Chip
              label="Canceled"
              color="error"
              size="small"
              variant="outlined"
              style={{ marginLeft: '5px' }}
            />
          )}</TableCell>
        <TableCell> <Tooltip title={row.seller.email} arrow>
                        <span>{row.seller.name}</span>
                      </Tooltip></TableCell>
        <TableCell>{`Ksh ${row.totalAmount.toFixed(2)}`}</TableCell>
        <TableCell>{formatTimestamp(row.createdAt)}</TableCell>
        <TableCell> 
          <Button variant="outlined" size="small" onClick={(e) => {
            e.stopPropagation(); // Prevent the row click event
            onOpenReceipt(row);
          }}>
            Receipt
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Products in Order
              </Typography>
              <Table size="small" aria-label="products">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Product ID</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.products.map((product, index) => (
                    <TableRow key={index}>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>{product.productId}</TableCell>
                      <TableCell style={{ textAlign: 'right' }}>{product.quantity}</TableCell>
                      <TableCell style={{ textAlign: 'right' }}>{`Ksh ${product.price.toFixed(2)}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function OrdersTable() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { id: storeIdParam } = useParams();
  const token = useSelector((state) => state.auth.token);
  const BASE_URL = `${process.env.REACT_APP_BACKEND}`;
  const [realStoreId, setRealStoreId] = useState(null);
  const [value, setValue] = useState(0); // State for tab selection
  const [selectedOrder, setSelectedOrder] = useState(null); // State for selected order
  const [openReceiptModal, setOpenReceiptModal] = useState(false); // State for receipt modal
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [showSearch, setShowSearch] = useState(false);
  // const [searchOrderId, setSearchOrderId] = useState('');
  // const [searchSeller, setSearchSeller] = useState('');
  // const [searchAmount, setSearchAmount] = useState('');
  // const [searchDate, setSearchDate] = useState('');

  const toggleSearch = () => {
    setShowSearch((prev) => !prev);
  };
  useEffect(() => {
    if (storeIdParam) {
      fetchRealStoreId();
    }
  }, [storeIdParam, page]);

  const fetchRealStoreId = async () => {
    setRealStoreId(storeIdParam);
    await fetchOrders(storeIdParam);
  };
  const [summary, setSummary] = useState({ totalOrders: 0, totalAmount: 0 });

  const [searchOrderId, setSearchOrderId] = useState('');
  const [searchSeller, setSearchSeller] = useState('');
  const [searchMinAmount, setSearchMinAmount] = useState('');
  const [searchMaxAmount, setSearchMaxAmount] = useState('');
  const [searchDateFrom, setSearchDateFrom] = useState(new Date().toISOString().slice(0, 10));
  const [searchDateTo, setSearchDateTo] = useState(new Date().toISOString().slice(0, 10));
  const [searchProductName, setSearchProductName] = useState(''); // For product name
  const [searchBarcode, setSearchBarcode] = useState('');
const [totalOrders,setTotalOrders] =useState('0');
const [searchStatus, setSearchStatus] = useState('');
const fetchSummary = async (id) => {
    try {
      const queryParams = new URLSearchParams();
      if (searchDateFrom) queryParams.append('dateFrom', searchDateFrom);
      if (searchDateTo) queryParams.append('dateTo', searchDateFrom);
      if (searchOrderId) queryParams.append('orderId', searchOrderId);
      if (searchSeller) queryParams.append('seller', searchSeller);
      if (searchMinAmount) queryParams.append('minAmount', searchMinAmount);
      if (searchMaxAmount) queryParams.append('maxAmount', searchMaxAmount);
      if (searchProductName) queryParams.append('productName', searchProductName);
      if (searchBarcode) queryParams.append('barcode', searchBarcode);
      if (searchStatus) queryParams.append('paymentStatus', searchStatus);
  
      const response = await fetch(`${BASE_URL}/api/summary/${id??realStoreId}?${queryParams}`);
      const data = await response.json();
      setSummary(data);
    } catch (error) {
      console.error('Error fetching summary:', error);
    }
  };
useEffect(() => {
    fetchSummary(storeIdParam);
  }, [storeIdParam,searchStatus, searchOrderId, searchSeller, searchMinAmount, searchMaxAmount, searchDateFrom, searchDateTo, searchProductName, searchBarcode]);
  

  const fetchOrders = async (id,) => {
    setLoading(true);
    const searchParams = {
      orderId: searchOrderId,
      seller: searchSeller,
      minAmount: searchMinAmount,
      maxAmount: searchMaxAmount,
      dateFrom: searchDateFrom,
      dateTo: "",
      productName: searchProductName,
      barcode: searchBarcode,
         
    paymentStatus: searchStatus, 
    }
    try {
      // Construct query parameters based on provided searchParams
      const params = new URLSearchParams({
        page: page,
        limit: 10,
        ...searchParams, // Spread the search parameters into the query
      }).toString();
  
      const response = await axios.get(`${BASE_URL}/api/orders/${id ?? realStoreId}?${params}`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
  setTotalOrders(response.data.totalOrders)
      setOrders(response.data.orders);
      setTotalPages(response.data.totalPages);
      setError(null);
    } catch (err) {
      console.error('Error fetching orders:', err);
      setError('Failed to fetch orders. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  // const handleSearch = useCallback(() => {
   
  //   fetchOrders(storeIdParam);
   
  // }, [searchOrderId, searchSeller, searchMinAmount, searchMaxAmount, searchDateFrom, searchDateTo, searchProductName, searchBarcode]);

  // Create a debounced version of the handleSearch function
  // const debouncedSearch = useMemo(() => _.debousnce(handleSearch, 1000), [handleSearch]);

  // useEffect(() => {
  //   debouncedSearch();
  //   return () => debouncedSearch.cancel();
  // }, [debouncedSearch]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    // Define the fetch function
    const fetchWithDelay = async () => {
      try {
        await fetchOrders(storeIdParam);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
  
    if (isInitialLoad) {
      // Fetch immediately on initial load
      fetchWithDelay();
      setIsInitialLoad(false); // Mark initial load as done
    } else {
      // Delay fetch for subsequent changes
      const delayFetch = setTimeout(() => {
        fetchWithDelay();
      }, 1000); // 2000ms delay
  
      // Clear timeout if dependencies change before delay completes
      return () => clearTimeout(delayFetch);
    }
  }, [storeIdParam, searchStatus,searchOrderId, searchSeller, searchMinAmount, searchMaxAmount, searchDateFrom, searchDateTo, searchProductName, searchBarcode]);
  
  // fetchOrders(storeIdParam, searchParams);
  const handleInputChange = (setter) => (e) => {setter(e.target.value);

    // debounce(() => {
    //   fetchOrders(storeIdParam)
    // }, 1000)
  };
  const handlePageChange = (event, value) => {
    setPage(value);
    // debouncedSearch()
  };

  const filteredOrders = orders.filter(
    (order) =>
      order._id.includes(search) ||
      order.seller.name.toLowerCase().includes(search.toLowerCase()) ||
      order.seller.email.toLowerCase().includes(search.toLowerCase())
  );

  const handleOrderClick = (order) => {
    setSelectedOrder(order); // Set the selected order to display in the modal
  };

  const handleOpenReceiptModal = (order) => {
    setSelectedOrder(order);
    setOpenReceiptModal(true);
  };

  const handleCloseReceiptModal = () => {
    setOpenReceiptModal(false);
    setSelectedOrder(null);
  };
  const handleCloseModal = () => {
    setSelectedOrder(null); // Close the modal by resetting selected order
  };

  // if (loading) {
  //   return <CircularProgress />;
  // }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  const productsInOrders = orders?.flatMap(order => 
    order?.products.map(product => ({
      ...product,
      orderId: order.orderId,
      seller: order.seller,
      paymentStatus: order.paymentStatus,
      createdAt: order.createdAt
    }))
  );

  const filteredProducts = productsInOrders.filter(
    (product) =>
      product.name.toLowerCase().includes(search.toLowerCase()) ||
      product.productId.includes(search)
  );

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const clearSearchFields = () => {
    setSearchOrderId('');
    setSearchSeller('');
    setSearchMinAmount('');
    setSearchMaxAmount('');
    // setSearchDateFrom('');
    // setSearchDateTo('');
    setSearchProductName('');
    setSearchBarcode('');
  };

  const handleDateNavigation = (direction) => {
    const newDate = new Date(searchDateFrom);
    newDate.setDate(newDate.getDate() + direction); // Move date by direction (+1 or -1)
    setSearchDateFrom(formatTimestamp(newDate).slice(0,10));
  };
  function formatDate(date) {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    
    // Set the input date to midnight to ignore the time part
    const inputDate = new Date(date);
    inputDate.setHours(0, 0, 0, 0);
  
    // Check if the input date is today
    const options = { weekday: 'short' };
    const dayOfWeek = inputDate.toLocaleString('default', options);
    const options2= { weekday:"long" };
    const dayOfWeek2 = inputDate.toLocaleString('default', options2);
    if (inputDate.toDateString() === today.toDateString()) {
      return 'Today ('+dayOfWeek2+")";
    }
   
    if (inputDate.toDateString() === yesterday.toDateString()) {
   return 'Yesterday ('+dayOfWeek2+")";
    }
  
    // Get the day of the week (e.g., Mon, Tue)
 
  
    // Format the date as "26th Oct 2024"
    const day = inputDate.getDate();
    const month = inputDate.toLocaleString('default', { month: 'short' });
    const year = inputDate.getFullYear();
  
    // Adding suffix for the day
    const suffix = (day) => {
      if (day > 3 && day < 21) return 'th'; // Special case for 11th to 13th
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };
  
    return `${dayOfWeek}, ${day}${suffix(day)} ${month} ${year}`;
  }
  

  


  return (
    <>
       {/* <Button onClick={fetchSummary}>Fetch Summary</Button> */}
       <Box display="flex" alignItems="center">
              <IconButton onClick={() => handleDateNavigation(-1)}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h5" style={{ margin: '0 10px' }}>
                {formatDate(searchDateFrom)} {/* Display the formatted date */}
              </Typography>
              <IconButton onClick={() => handleDateNavigation(1)}>
                <ArrowForward />
              </IconButton>
            </Box>
       <Grid container spacing={2}>
        
    { [
    { title: 'Total Sales', value:summary.totalAmount, icon: <AttachMoney />, color: green[500] },
    { title: 'Total Orders', value:  summary.totalOrders,  icon: <ShoppingCart />, color: blue[500] },].map((data, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <SummaryCard
              title={data.title}
              value={data.value}
              icon={data.icon}
              color={data.color}
            />
          </Grid>
        ))}  </Grid>
    

      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
   <div style={{display:'flex',flexDirection:'column',width:'100%',padding:10}}>   
    <div style={{width:'100%',display:'flex',justifyContent:'space-between'}}>  
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Tabs value={value} onChange={(event, newValue) => {setValue(newValue)}} indicatorColor="primary" textColor="primary">
          <Tab label="Orders" />
          <Tab label="Products" />
        </Tabs>
      </Box>
       {/* <Typography variant="h5" gutterBottom style={{ marginBottom: '16px',alignSelf:"start" }}>
    Daily Orders
  </Typography> */}
  <div style={{display:'flex',alignItems:'center',flexDirection:'row',justifyContent:'center'}}>
   {/* <Typography variant='h6'>({(totalOrders)})</Typography> */}
  <Button onClick={toggleSearch} size="small" variant="outlined" style={{ marginBottom: '16px',alignSelf:"end" }}>
        {showSearch ? 'Hide Search' : 'Show Search'}
      </Button> 
  </div>
  
    </div>
  
  <div style={{marginBottom:0,display:'flex',alignItems:'center',flexDirection:'row',justifyContent:'space-between'}}>

         </div>
      {showSearch && (  <Paper style={{ padding: '16px', marginBottom: '20px',}}>
  <Typography variant="h6" gutterBottom>
    Search Orders
  </Typography>
  <Grid container spacing={2} style={{ marginBottom: '16px' }}>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Order ID"
          variant="outlined"
          value={searchOrderId}
          onChange={handleInputChange(setSearchOrderId)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Seller"
          variant="outlined"
          value={searchSeller}
          onChange={handleInputChange(setSearchSeller)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Min Amount"
          variant="outlined"
          value={searchMinAmount}
          onChange={handleInputChange(setSearchMinAmount)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Max Amount"
          variant="outlined"
          value={searchMaxAmount}
          onChange={handleInputChange(setSearchMaxAmount)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Date"
            type="date"
          variant="outlined"
          value={searchDateFrom}
          onChange={handleInputChange(setSearchDateFrom)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      {/* <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
            type="date"
          label="Date To"
          variant="outlined"
          value={searchDateTo}
          onChange={handleInputChange(setSearchDateTo)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Grid> */}
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Product Name"
          variant="outlined"
          value={searchProductName}
          onChange={handleInputChange(setSearchProductName)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      {/* <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Barcode"
          variant="outlined"
          value={searchBarcode}
          onChange={handleInputChange(setSearchBarcode)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
    </Grid> */}
     <Grid item xs={12} sm={3}>
              <TextField
                select
                fullWidth
                label="Payment Status"
                variant="outlined"
                value={searchStatus}
                onChange={handleInputChange(setSearchStatus)}
                size="small"
                InputLabelProps={{ shrink: true }}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="paid">Paid</MenuItem>
                <MenuItem value="canceled">Canceled</MenuItem>
              </TextField>
            </Grid>
      <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined" color="secondary" onClick={clearSearchFields}>
          Clear Search
        </Button>
      </Grid>
    </Grid>

</Paper>)}  </div>
        {/* <TextField
          autoFocus
          variant="outlined"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          size="small"
        /> */}
      </Box>

      {value === 0 && (
        <>
          {isSmallScreen ? (
            <Grid container spacing={2}>
              {orders.map((order) => (
                <Grid item xs={12} sm={6} md={4} key={order._id}>
                  <Card onClick={() => handleOrderClick(order)} sx={{ cursor: 'pointer', mb: 2 }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Order ID: {order.orderId} {order.paymentStatus === 'canceled' && (
                          <Chip
                            label="Canceled"
                            color="error"
                            size="small"
                            variant="outlined"
                            style={{ marginLeft: '5px' }}
                          />
                        )}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Seller:</strong> {order.seller.name} ({order.seller.email})
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        <strong>Order Date:</strong> {formatTimestamp(order.createdAt)}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ mt: 2, color: 'success.main', fontWeight: 'bold' }}
                      >
                        Total Amount: Ksh {order.totalAmount.toFixed(2)}
                      </Typography>
                      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenReceiptModal(order);
                          }}
                        >
                          Receipt
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <TableContainer component={Paper}>
              <Table size="small" aria-label="orders table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Order ID</TableCell>
                    <TableCell>Seller</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>Order Date</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((row, index) => (
                    <ExpandableRow 
                      key={index} 
                      row={row} 
                      onClick={handleOrderClick} 
                      onOpenReceipt={handleOpenReceiptModal}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              variant="outlined"
              shape="rounded"
            />
          </Box>
        </>
      )}
      
      {value === 1 && (
        <>
          {isSmallScreen ? (
            <Grid container spacing={2}>
              {productsInOrders.map((product) => (
                <Grid item xs={12} sm={6} md={4} key={product.productId}>
                  <Card sx={{ mb: 2 }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {product.name}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Product ID:</strong> {product.productId}
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        <strong>Quantity:</strong> {product.quantity}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ mt: 2, color: 'success.main', fontWeight: 'bold' }}
                      >
                        Price: Ksh {product.price.toFixed(2)}
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        <strong>Order ID:</strong> {product.orderId} {product.paymentStatus === 'canceled' && (
                          <Chip
                            label="Canceled"
                            color="error"
                            size="small"
                            variant="outlined"
                            style={{ marginLeft: '5px' }}
                          />
                        )}
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        <strong>Seller:</strong> {product.seller.name}
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        <strong>Order Date:</strong> {formatTimestamp(product.createdAt)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <TableContainer component={Paper}>
              <Table size="small" aria-label="products in orders table">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Product ID</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Order ID</TableCell>
                    <TableCell>Seller</TableCell>
                    <TableCell>Order Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productsInOrders.map((product, index) => (
                    <TableRow key={index}>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>{product.productId}</TableCell>
                      <TableCell>{product.quantity}</TableCell>
                      <TableCell>{`Ksh ${product.price.toFixed(2)}`}</TableCell>
                      <TableCell>{product.orderId} {product.paymentStatus === 'canceled' && (
                        <Chip
                          label="Canceled"
                          color="error"
                          size="small"
                          variant="outlined"
                          style={{ marginLeft: '5px' }}
                        />
                      )}</TableCell>
                      <TableCell>
                        <Tooltip title={product.seller.email} arrow>
                          <span>{product.seller.name}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{formatTimestamp(product.createdAt)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              variant="outlined"
              shape="rounded"
            />
          </Box>
        </>
      )}

      {isSmallScreen && (
        <Dialog open={Boolean(selectedOrder)} onClose={handleCloseModal} maxWidth="md" fullWidth>
          <DialogTitle>Order Details</DialogTitle>
          <DialogContent sx={{ p: 1 }}>
            {selectedOrder && (
              <Grid container spacing={1}>
                {selectedOrder.products.map((product, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card sx={{ mb: 2 }}>
                      <CardContent>
                        <Typography variant="h6" gutterBottom>
                          {product.name}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Product ID:</strong> {product.productId}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          <strong>Quantity:</strong> {product.quantity}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ mt: 2, color: 'success.main', fontWeight: 'bold' }}
                        >
                          Price: Ksh {product.price.toFixed(2)}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          <strong>Order Date:</strong> {formatTimestamp(selectedOrder.createdAt)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button size="small" variant="outlined" onClick={() => handleOpenReceiptModal(selectedOrder)}>Receipt</Button>
            <Button size="small" onClick={handleCloseReceiptModal}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      {selectedOrder && (
        <ReceiptModal
          order={selectedOrder}
          open={openReceiptModal}
          onClose={handleCloseReceiptModal}
        />
      )}
    </>
  );
}
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css'
import Home from './components/home';
import PointOfSale from './components/point';
import POSDashboard from './components/dashboard';
import Log from './components/logs';
import Inventory from './components/inventory';
import LoginPage from './components/login';
import SubaccountManager from './components/accounts';
import StoreManager from './components/store'; // Import your StoreManager component
import AmberspaceSystems from './components';
import AffiliateDashboard from './components/affiliate';
import ContactPage from './components/messages';
import StoreDetails from './components/records';
import OrderDetails from './components/summary';
import LoginDemo from './components/demo/login'
export default function App() {
  return (
    <BrowserRouter>
      <Routes>
    
      <Route path="/pos/demo" element={<LoginDemo/>}/>
      <Route path="/pos/demo/:id" element={<LoginDemo/>}/>
      <Route path="/messages" element={<ContactPage/>}/>
      <Route path="/" element={<AmberspaceSystems />}/>
      <Route path="/summary/:id" element={<OrderDetails />}/>
        {/* Login page rendered outside of the Home layout */}
        <Route path="/pos/login" element={<LoginPage />} />
        <Route path="/pos/login/:id" element={<LoginPage />} />
        {/* Store management route as a parent route */}
        <Route path="/stores" element={<StoreManager />}/>
          <Route path='/affiliate' element={<AffiliateDashboard/>}/>
          {/* Nested routes under StoreManager */}
               <Route path='/records' element={<StoreDetails/>}/>
          <Route path="/store/:id/" element={<Home />}>
            <Route index element={<PointOfSale />} />
            <Route path="dashboard" element={<POSDashboard />} />
            <Route path="stock" element={<Inventory />} />
            <Route path="accounts" element={<SubaccountManager />} />
            <Route path="logs" element={<Log />} />
     
            <Route
              path="*"
              element={
                <div style={{ textAlign: 'center' }}>
                  <div style={{ paddingTop: 60 }}>
                    <h1>Page Not Found!</h1>
                    <h1>404</h1>
                  </div>
                </div>
              }
            />
          </Route>
      
      </Routes>
    </BrowserRouter>
  );
}

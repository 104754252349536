// src/slices/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  _id:null,
  email: null,
  name: null,
  picture: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state._id = action.payload._id;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.picture = action.payload.picture;
    },
    clearUser: (state) => {
      state._id=null;
      state.email = null;
      state.name = null;
      state.picture = null;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;

import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { green, blue, orange, red } from '@mui/material/colors';
import CountUp from 'react-countup';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ReactECharts from 'echarts-for-react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

// Container styling
const DashboardContainer = styled(Box)({
  padding: '20px',
});

// Summary Card component
const SummaryCard = ({ title, value, icon, color }) => (
  <Card sx={{ mb: 2 }}>
    <CardContent>
      <Grid container spacing={2}>
        <Grid item>
          <Avatar sx={{ bgcolor: color }}>
            {icon}
          </Avatar>
        </Grid>
        <Grid item>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="h4">
            <CountUp start={0} end={value} duration={2.75} separator="," />
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

// Main POS Dashboard component
const POSDashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    totalProductSalesToday: 0,
    totalOrdersSoldToday: 0,
    totalMonthlyOrders: 0,
    totalMonthlySales: 0,
  });
  const [topSalesProducts, setTopSalesProducts] = useState([]);
  const [topSalesProducts_2, setTopSalesProducts_2] = useState([]);
  const [salesBySellers, setSalesBySellers] = useState([]); // New state for sales by sellers
  const [weeklySales, setWeeklySales] = useState([]);
  const { id: storeIdParam } = useParams();

  // Fetch dashboard data, top products, weekly sales, and sales by sellers from the API
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/dashboard-summary/${storeIdParam}`);
        setDashboardData(response.data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    const fetchTopSalesProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/top-products/${storeIdParam}`); // Updated endpoint to match your API
        setTopSalesProducts(response.data.topProducts); // Change state to reflect new data
      } catch (error) {
        console.error('Error fetching top sales products:', error);
      }
    };

    const fetchTopSalesProducts_2 = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/top-sales-products/${storeIdParam}`); // Updated endpoint for top sales products
        setTopSalesProducts_2(response.data.topSalesProducts); // Change state to reflect new data
      } catch (error) {
        console.error('Error fetching top sales products:', error);
      }
    };
    const fetchSalesBySellers = async () => { // New function to fetch sales by sellers
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/sales-by-seller/${storeIdParam}`);
        setSalesBySellers(response.data.salesBySellers);
      } catch (error) {
        console.error('Error fetching sales by sellers:', error);
      }
    };

    const fetchWeeklySales = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/sales-trend/${storeIdParam}`);
        setWeeklySales(response.data.sales);
      } catch (error) {
        console.error('Error fetching weekly sales:', error);
      }
    };
    fetchTopSalesProducts_2();
    fetchDashboardData();
    fetchTopSalesProducts(); // Updated function call
    fetchSalesBySellers(); // New function call
    fetchWeeklySales();
  }, [storeIdParam]);

  // Data for the summary cards
  const summaryData = [
    { title: 'Total Sales Today', value: dashboardData.totalProductSalesToday, icon: <AttachMoneyIcon />, color: green[500] },
    { title: 'Total Orders Today', value: dashboardData.totalOrdersSoldToday, icon: <ShoppingCartIcon />, color: blue[500] },
    { title: 'Total Monthly Orders', value: dashboardData.totalMonthlyOrders, icon: <PeopleIcon />, color: orange[500] },
    { title: 'Total Monthly Sales', value: dashboardData.totalMonthlySales, icon: <TrendingUpIcon />, color: red[500] },
  ];

  // ECharts options for sales trend chart
  const salesTrendOptions = {
    title: { text: 'Sales Trend' },
    tooltip: { trigger: 'axis' },
    xAxis: {
      type: 'category',
      data: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'], // Adjusted to match your API response
    },
    yAxis: { type: 'value' },
    series: [
      {
        name: 'Sales',
        type: 'line',
        data: weeklySales, // Use the fetched weekly sales data
      },
    ],
  };

  // ECharts options for product performance chart
  const productPerformanceOptions = {
    title: { text: 'Top 10 Products This Month' },
    tooltip: { trigger: 'axis' },
    xAxis: {
      type: 'category',
      data: topSalesProducts.map(product => product.productName), // Updated to reflect new data
    },
    yAxis: { type: 'value' },
    series: [
      {
        name: 'Units Sold',
        type: 'bar',
        data: topSalesProducts.map(product => product.quantity), // Updated to reflect new data
      },
    ],
  };

  return (
    <DashboardContainer>
      <Grid container spacing={2}>
        {/* Summary Cards */}
        {summaryData.map((data, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <SummaryCard
              title={data.title}
              value={data.value}
              icon={data.icon}
              color={data.color}
            />
          </Grid>
        ))}

        {/* Sales Trend Chart */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <ReactECharts option={salesTrendOptions} />
            </CardContent>
          </Card>
        </Grid>

        {/* Product Performance Chart */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <ReactECharts option={productPerformanceOptions} />
            </CardContent>
          </Card>
        </Grid>

        {/* Tables for Top Sales Products and Sales by Sellers Side by Side */}
        <Grid container spacing={2} item xs={12}>
          {/* Top Sales Products Table */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Top 20 Products sales
                </Typography>
                <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Product Name</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Total Sales(Ksh)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {topSalesProducts_2.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={3} align="center">No sales data available.</TableCell>
                        </TableRow>
                      ) : (
                        topSalesProducts_2.map((product, index) => (
                          <TableRow key={index}>
                            <TableCell>{product.productName}</TableCell>
                            <TableCell style={{textAlign:'right'}}>{product.quantity}</TableCell>
                            <TableCell style={{textAlign:'right'}}>{`${product.totalSales?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>

          {/* Sales by Sellers Table */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Sales by Sellers This Month
                </Typography>
                <TableContainer component={Paper}>
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        {/* <TableCell>Seller ID</TableCell> */}
                        <TableCell>Seller Name</TableCell>
                        {/* <TableCell>Seller Email</TableCell> */}
                       
                        <TableCell>Total Orders</TableCell> <TableCell>Total Sales</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {salesBySellers.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={5} align="center">No sales data available.</TableCell>
                        </TableRow>
                      ) : (
                        salesBySellers.map((seller, index) => (
                          <TableRow key={index}>
                         
                            <TableCell><Tooltip title={seller.sellerEmail}>{seller.sellerName}</Tooltip></TableCell>
                            
                            <TableCell style={{textAlign:'right'}}>{seller.totalOrders}</TableCell>
                            <TableCell style={{textAlign:'right'}}>{`Ksh ${seller.totalSales.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </DashboardContainer>
  );
};

export default POSDashboard;

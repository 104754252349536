import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Snackbar,
  Alert,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
  TextField,
  CircularProgress,
  Modal,
  Paper,
} from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { CheckCircle } from '@mui/icons-material';

const SubscriptionCard = () => {
  const [subscription, setSubscription] = useState(null);
  const [packages, setPackages] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [error, setError] = useState(null);
  const [mpesaPhone, setMpesaPhone] = useState('');
  const [orderId, setOrderId] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [polling, setPolling] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [pollingModalOpen, setPollingModalOpen] = useState(false);
  const { id: storeIdParam } = useParams();
  let intervalId;

  const fetchSubscription = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/payment/checkSubscription/${storeIdParam}`);
    
      if(subscription?.isActive==false && response.data?.isActive==true){
        window.location.reload();
      } 
       setSubscription(response.data);
    } catch (err) {
      console.error('Error fetching subscription data:', err);
      setError('Could not fetch subscription data. Please try again later.');
    }
  };

  const fetchPackages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/subscriptions`);
      setPackages(response.data);
    } catch (err) {
      console.error('Error fetching subscription packages:', err);
      setError('Could not fetch subscription packages. Please try again later.');
    }
  };

  useEffect(() => {
    fetchSubscription();
    fetchPackages();
  }, [storeIdParam]);

  const handlePaymentSubmit = async () => {
    try {
      // Validate phone number
      if (!selectedPackage || !mpesaPhone || mpesaPhone.length < 9) {
        alert('Please enter a valid phone number (minimum 9 digits) and select a package.');
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/payment/stkPush`, {
        phone: "254" + mpesaPhone.slice(-9),
        storeId: storeIdParam,
        packageId: selectedPackage._id,
      });
      setOrderId(response.data.CheckoutRequestID);
      setSnackbarOpen(true);
      setAlertMessage('Payment process initiated!');
      setAlertSeverity('success');

      setPollingModalOpen(true); // Open polling modal
      pollPaymentStatus(response.data.CheckoutRequestID);
    } catch (error) {
      console.error('Error initiating payment:', error);
      setAlertMessage('Could not initiate payment. Please try again later.');
      setAlertSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const pollPaymentStatus = async (orderId) => {
    setPolling(true);
    intervalId = setInterval(async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/payment/confirmPayment/${orderId}`);
        if (response.data.ResultCode !== undefined) {
          clearInterval(intervalId);
          setPolling(false);
          setPollingModalOpen(false); // Close polling modal
          if (response.data.ResultCode == 0) {
            setPaymentSuccess(true);
            setSnackbarOpen(true);
            setAlertMessage('Payment successful!');
            setAlertSeverity('success');
            fetchSubscription();
          } else {
            setAlertMessage('Payment failed. Please try again.');
            setAlertSeverity('error');
            setSnackbarOpen(true);
          }
        }
      } catch (err) {
        console.error('Error checking payment status:', err);
        clearInterval(intervalId);
        setPolling(false);
        setPollingModalOpen(false); // Close polling modal on error
      }
    }, 5000);
  };

  const handleCancelPayment = () => {
    clearInterval(intervalId);
    setPolling(false);
    setPollingModalOpen(false);
    setMpesaPhone(''); // Reset the phone number
    setSelectedPackage(null); // Reset selected package
    setOrderId(null); // Reset order ID
    setPaymentSuccess(false); // Reset payment success state
    setAlertMessage('Payment process cancelled. You can initiate a new payment.');
    setAlertSeverity('info');
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseModal = () => {
    setPaymentSuccess(false); // Reset state on modal close
  };

  const handleClosePollingModal = () => {
    setPollingModalOpen(false); // Reset polling modal state
  };

  const isExpired = () => {
    return subscription?.expiryDate && new Date(subscription.expiryDate) < new Date();
  };

  const formatDateTime = (date) => {
    if (!date) return 'N/A';
    const d = new Date(date);
    const yyyy = d.getFullYear();
    const mm = String(d.getMonth() + 1).padStart(2, '0');
    const dd = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd} ${hours}:${minutes}:${seconds}`;
  };

  if (error) return <Typography variant="body1" color="error">{error}</Typography>;

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sm={8} md={6}>
        <Card variant="outlined" sx={{ boxShadow: 3 }}>
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              Subscription Status
            </Typography>

            <Chip 
              label={subscription?.isActive ? 'Active' : 'Inactive'} 
              color={subscription?.isActive ? 'success' : 'error'} 
              sx={{ mb: 2 }} 
            />

            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
              <CalendarTodayIcon sx={{ marginRight: 1 }} />
              Expiry Date: {subscription?.lastSuccessfulPayment?.expiryDate ? formatDateTime(subscription?.lastSuccessfulPayment.expiryDate) : 'N/A'}
            </Typography>
            
            <Divider sx={{ my: 2 }} />

            {selectedPackage && !paymentSuccess && (
              <>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Amount to be Paid: Ksh {selectedPackage.amount}
                </Typography>
                <TextField 
                  label="M-Pesa Phone Number" 
                  fullWidth 
                  value={mpesaPhone} 
                  onChange={(e) => setMpesaPhone(e.target.value)} 
                  sx={{ mb: 2 }}
                  disabled={polling} // Disable during polling
                />
                <Button variant="contained" color="primary" onClick={handlePaymentSubmit} disabled={polling}>
                  Submit Payment
                </Button>
                {polling && (
                  <Button variant="outlined" color="secondary" onClick={handleCancelPayment} sx={{ ml: 2 }}>
                    Cancel Payment
                  </Button>
                )}
              </>
            )}

            <Typography variant="h6">Subscription Packages</Typography>
            <List>
              {packages.sort((a, b) => a.numberOfDays - b.numberOfDays).map((pkg) => (
                <ListItem key={pkg.id}>
                  <ListItemText 
                    primary={pkg.name} 
                    secondary={`Ksh ${pkg.amount} - ${pkg.duration}`} 
                  />
                  <ListItemSecondaryAction>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setSelectedPackage(pkg)}
                    >
                      <MonetizationOnIcon sx={{ marginRight: 1 }} />
                      Pay Now
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>

            {isExpired() && (
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => setSelectedPackage(packages[0])} 
                sx={{ marginTop: 2 }}
              >
                Pay Now
              </Button>
            )}
          </CardContent>
        </Card>
      </Grid>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
       {polling && (
        <Snackbar open={polling}>
          <Alert severity="info" icon={<CircularProgress size={20} />}>
            Payment Processing...
          </Alert>
        </Snackbar>
      )}
      <Modal open={pollingModalOpen} onClose={handleClosePollingModal}>
        <Paper elevation={5} sx={{ padding: 3, margin: 'auto', marginTop: '20%', maxWidth: 400 }}>
          <Typography variant="h6" gutterBottom>
            Checking for Payment 
          </Typography>
          <CircularProgress />
          <Typography variant="body1" sx={{ mt: 2 }}>
            Please wait while we confirm your payment status...
          </Typography>
        </Paper>
      </Modal>
    </Grid>
  );
};

export default SubscriptionCard;

import React,{useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BarChartIcon from '@mui/icons-material/BarChart';
import DescriptionIcon from '@mui/icons-material/Description';
import LayersIcon from '@mui/icons-material/Layers';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import InventoryIcon from '@mui/icons-material/Inventory';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import PointOfSale from './point';
import Inventory from './inventory';
import POSDashboard from './dashboard';
import OrdersTable from './orders';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, clearUser } from '../redux/slices/userSlice';
import { setToken, clearToken } from '../redux/slices/authSlice';
import { Logout,LocalShipping, Home, Payment, Subscriptions, AttachMoney, Report, TableBar, TableChart, Psychology} from '@mui/icons-material';
import SubaccountManager from './accounts';
import StoreManager from './store';
import { persistor } from '../redux/store';
import CustomerList from './customer';
import SupplierManager from './supplier';
import TodayIcon from '@mui/icons-material/Today'; // Daily view icon
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth'; // Monthly view icon
import DateRangeIcon from '@mui/icons-material/DateRange'; 
import DailyTable from './daily';
import PaymentsTable from './payment';
import SubscriptionManager from './subscriptions';
import MonthlyTable from './monthly';
import SubscriptionCard from './checkSub';
import axios from 'axios';
import { useEffect } from 'react';
import ExpenditureList from './expenditure';
import { clearStore } from '../redux/slices/storeSlice';
// import { useDispatch } from 'react-redux';
import { setStore } from '../redux/slices/storeSlice';
import Summary from './summary';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SummaryReport from './summary';
import MonthlyOrderStatistics from './monthlySummary';
import { Table } from '@mui/material';
import AIPOSReportGenerator from './ai-reports';
import { Helmet } from 'react-helmet-async';
const NAVIGATION = [
  {
    kind: 'header',
    title: 'Main items',
  }, {
    segment: 'home',
    title: 'Home',
    icon: <Home />,
  },
  {
    segment: 'dashboard',
    title: 'Dashboard',
    icon: <DashboardIcon />,
  },
  {
    segment: '',
    title: 'Sale',
    icon: <PointOfSaleIcon />,
  },
  {
    segment: 'orders',
    title: 'Orders',
    icon: <ShoppingCartIcon />,
  },

  {
    segment: 'inventory',
    title: 'Inventory',
    icon: <InventoryIcon />,
  },
  {
    segment: 'expense',
    title: 'Expense',
    icon: <AttachMoney />,
  },
  

  {
    kind: 'divider',
  },
 
  {
    segment: 'customers',
    title: 'Customers',
    icon: <PeopleIcon />,
  },
  {
    segment: 'supplier',
    title: 'Supplier',
    icon: <LocalShipping />,
  },

  {
    kind: 'header',
    title: 'Analytics',
  },
  {
    segment: 'reports',
    title: 'Reports',
    icon: <BarChartIcon />,
    children: [
       {
          segment: 'daily',
          title: 'Daily',
          icon: <TodayIcon />,},{
          segment: 'monthly',
          title: 'Monthly',
          icon: <CalendarViewMonthIcon />, // Icon for monthly view
        }, {
        segment: 'summary',
        title: 'Summary',
        icon: <SummarizeIcon />, 
        children:[
          {
            segment: 'daily_summary',
            title: 'Daily Summary',
            // icon: <SummarizeIcon />, // Icon for monthly view
          }, {
            segment: 'monthly_summary',
            title: 'Monthy Summary',
            // icon: <SummarizeIcon />, // Icon for monthly view
          },
        ]
        // Icon for monthly view
      },
      {
        segment: 'AI-Reports',
        title: 'AI Reports',
        icon: <Psychology />,}
   
     
      // {
      //   segment: 'annual',
      //   title: 'Annual',
      //   icon: <DateRangeIcon />, // Icon for annual view
      // },
    ],
  },
  // {
  //   segment: 'payment',
  //   title: 'Payment',
  //   icon: <Payment />,
  // },
  {
    segment: 'subscriptions',
    title: 'My Subscription',
    icon: <Subscriptions />,
  },
  {
    segment: 'settings',
    title: 'Settings',
    icon: <SettingsIcon />,
  },


  // {
  //   segment: 'integrations',
  //   title: 'Integrations',
  //   icon: <LayersIcon />,
  // },
  // {
  //   kind: 'divider',
  // },
  {
    segment: 'logout',
    title: 'Logout',
    icon: <Logout />,
  },
];

const demoTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

function DemoPageContent({ pathname }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.auth.token);

  React.useEffect(() => {
    // Save current pathname to localStorage whenever it changes
    localStorage.setItem('lastPath', pathname);

    if (pathname === '/home') {
      navigate('/stores');
    }
    var email=user.email;
    if (pathname === '/logout') {
      // dispatch(clearUser());
      dispatch(clearToken());
      persistor.purge();
      localStorage.removeItem('lastPath'); // Clear saved path on logout
      if(email=="johndoe@gmail.com"){
        navigate('../../pos/demo')
    }else{
              navigate('../../pos/login')
    }
    }
  }, [pathname, dispatch, navigate]);
  const [subscription, setSubscription] = useState({isActive:true});
  const { id: storeIdParam } = useParams();

  const fetchSubscription = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/payment/checkSubscription/${storeIdParam}`);
      setSubscription(response.data);
    } catch (err) {
      console.error('Error fetching subscription data:', err);
      // setError('Could not fetch subscription data. Please try again later.');
    }
  };
  useEffect(()=>{
    if(process.env.REACT_APP_BACKEND=="https://api.amberspace.co"){
fetchSubscription()
    }

  },[])

// Inside your component where you fetch/store data
// const dispatch = useDispatch();
  const [storeData, setStoreData] = useState(null);
// const token=useSelector(state=>state.auth.token)
  const fetchStoreVerification = async () => {
    // dispatch(clearStore());
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/verify-store/`+storeIdParam, {
            headers: {
                Authorization: `JWT ${token}`, // Assuming you store the token in localStorage
            },
        });
        dispatch(setStore({
          name: response.data?.storeName, // From your response
          location:response.data?.storeLocation,   // From your response
      }));
        setStoreData(response.data);
        // setError(null); // Clear previous errors if the request was successful
    } catch (err) {
        // Check if the error response is a 404
        if (err.response) {
            if (err.response.status === 404) {
                // Redirect to login if the store is not found
                navigate('../../stores'); // Change this to your login route
            } else if (err.response.status === 401) {
                // Redirect to login if unauthorized (user does not exist or token invalid)
                if(user.email=="johndoe@gmail.com"){
                  navigate('../../pos/demo')
              }else{
                        navigate('../../pos/login')
              } // Change this to your login route
            } else {
                // setError(err.response.data.message);
            }
        } else {
            // setError('An error occurred');
            console.error('Error fetching store verification:', err);
        }
    }
};

useEffect(() => {
    fetchStoreVerification(); // Initial fetch

    const intervalId = setInterval(() => {
        fetchStoreVerification(); // Fetch every 5 seconds
    }, 10000); // 5000 ms = 5 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
}, []);

  return (
    <Box
      sx={{
        py: 3,
        display: 'flex',
        flexDirection: 'column'
      }} style={{paddingBottom:100}}
    >
      {/* {pathname} */}
      {/* <Typography variant='h4' style={{marginLeft:10}}>{storeData?.storeName}</Typography> */}
      {user.email!="johndoe@gmail.com"?<>
      {(subscription?.isActive==false  )?<><SubscriptionCard/>{user.email=="rodgerkilonzo@gmail.com" &&<SubscriptionManager />}</>:<>
      {pathname == '/' && <PointOfSale />}
      {pathname == '/inventory' && <Inventory />}
      {pathname == '/dashboard' && <POSDashboard />}
      {pathname == '/orders' && <OrdersTable />}
      {pathname == '/settings' && <SubaccountManager />}
      {pathname == '/customers' && <CustomerList />}
      {pathname == '/reports/AI-Reports' && <AIPOSReportGenerator/>}
      
      {pathname == '/supplier' && <SupplierManager />}
      {pathname == '/reports/daily' && <DailyTable />}
      {pathname == '/payment' && <PaymentsTable />}
      {pathname == '/reports/summary/daily_summary' && <SummaryReport />}
      {pathname == '/reports/summary/monthly_summary' && <MonthlyOrderStatistics />}
      {pathname == '/expense' && <ExpenditureList />}
      {pathname == '/reports/monthly' && <MonthlyTable />}
          {pathname == '/subscriptions' && <><SubscriptionCard/>{user.email=="rodgerkilonzo@gmail.com" &&<SubscriptionManager />}</>}</>} 
          </>:<>  {pathname == '/' && <PointOfSale />}
      {pathname == '/inventory' && <Inventory />}
      {pathname == '/dashboard' && <POSDashboard />}
      {pathname == '/orders' && <OrdersTable />}
      {pathname == '/settings' && <SubaccountManager />}
      {pathname == '/customers' && <CustomerList />}
      {pathname == '/supplier' && <SupplierManager />}
      {pathname == '/reports/daily' && <DailyTable />}
      {pathname == '/reports/AI-Reports' && <AIPOSReportGenerator/>}
      {pathname == '/payment' && <PaymentsTable />}
      {pathname == '/reports/summary/daily_summary' && <SummaryReport />}
      {pathname == '/reports/summary/monthly_summary' && <MonthlyOrderStatistics />}
      {pathname == '/expense' && <ExpenditureList />}
      {pathname == '/reports/monthly' && <MonthlyTable />}</>}

    </Box>
  );
}

DemoPageContent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

function DashboardLayoutBasic(props) {
  const { window } = props;
  
  // Initialize pathname from localStorage or default to '/'
  const [pathname, setPathname] = React.useState(() => {
    const savedPath = localStorage.getItem('lastPath');
    return savedPath || '/';
  });

  const router = React.useMemo(() => ({
    pathname,
    searchParams: new URLSearchParams(),
    navigate: (path) => {
      if (pathname !== path) {
        setPathname(String(path));
        localStorage.setItem('lastPath', String(path)); // Save path when navigating
      }
    },
  }), [pathname]);

  const demoWindow = window !== undefined ? window() : undefined;

  return (
    <>
      <Helmet>
        <title>Armada POS System</title>
        <meta name="description" content="Armada - A robust, cloud-based POS system designed to streamline your business operations." />
        <meta name="keywords" content="Armada POS, cloud POS, POS system, retail management, sales tracking" />
        <meta property="og:title" content="Armada Cloud POS System" />
        <meta property="og:description" content="Experience seamless business management with Armada, the cloud POS system built for efficiency and scalability." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.amberspace.co.ke" />
        <meta property="og:image" content="../../assets/logo.png" />
      </Helmet>
       <AppProvider
      navigation={NAVIGATION}
      branding={{
        logo: <img src="../../assets/logo.png" alt="Armada" />,
        title: 'Armada',
      }}
      router={router}
      theme={demoTheme}
      window={demoWindow}
    >
      <DashboardLayout>
        <DemoPageContent pathname={pathname} />
      </DashboardLayout>
    </AppProvider>
    </>
 
  );
}

DashboardLayoutBasic.propTypes = {
  window: PropTypes.func,
};

export default DashboardLayoutBasic;
import React, { useState } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

const Log = () => {
  const [value, setValue] = useState(0);

  // Sample data for stock updates
  const stockUpdates = [
    {
      id: 1,
      productName: 'Product 1',
      oldStock: 50,
      newStock: 100,
      date: '2024-10-01',
      user: 'Alice Johnson', // Logged-in user responsible for the update
    },
    {
      id: 2,
      productName: 'Product 2',
      oldStock: 30,
      newStock: 50,
      date: '2024-10-02',
      user: 'Bob Smith', // Logged-in user responsible for the update
    },
  ];

  // Sample data for product sales
  const salesLogs = [
    {
      id: 1,
      productName: 'Product 1',
      quantity: 2,
      totalPrice: 200,
      date: '2024-10-01',
      user: 'Alice Johnson', // Logged-in user responsible for the sale
    },
    {
      id: 2,
      productName: 'Product 2',
      quantity: 1,
      totalPrice: 150,
      date: '2024-10-02',
      user: 'Bob Smith', // Logged-in user responsible for the sale
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Logs
      </Typography>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Stock Updates" />
        <Tab label="Product Sales" />
      </Tabs>

      {/* Stock Updates Tab */}
      {value === 0 && (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell align="right">Old Stock</TableCell>
                <TableCell align="right">New Stock</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="right">Updated By</TableCell> {/* New Column */}
              </TableRow>
            </TableHead>
            <TableBody>
              {stockUpdates.map((update) => (
                <TableRow key={update.id}>
                  <TableCell>{update.productName}</TableCell>
                  <TableCell align="right">{update.oldStock}</TableCell>
                  <TableCell align="right">{update.newStock}</TableCell>
                  <TableCell align="right">{update.date}</TableCell>
                  <TableCell align="right">{update.user}</TableCell> {/* Display User */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Product Sales Tab */}
      {value === 1 && (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell align="right">Quantity Sold</TableCell>
                <TableCell align="right">Total Price (KSh)</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="right">Sold By</TableCell> {/* New Column */}
              </TableRow>
            </TableHead>
            <TableBody>
              {salesLogs.map((sale) => (
                <TableRow key={sale.id}>
                  <TableCell>{sale.productName}</TableCell>
                  <TableCell align="right">{sale.quantity}</TableCell>
                  <TableCell align="right">{sale.totalPrice}</TableCell>
                  <TableCell align="right">{sale.date}</TableCell>
                  <TableCell align="right">{sale.user}</TableCell> {/* Display User */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default Log;

import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Chip,
  Card,
  CardContent,
  Grid,
  Alert,
  Pagination,
  Button,
  Container,
  Avatar,
  Icon,  TextField,  Snackbar,  Tabs, Tab,    
} from '@mui/material';
import {
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  AttachMoney as MoneyIcon,
  Google as GoogleIcon,
  Logout as LogoutIcon,
  AccountBalance as AccountBalanceIcon,
  Group as GroupIcon,
  CheckCircle as CheckCircleIcon,AttachMoney
} from '@mui/icons-material';
import { GoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setToken, clearToken } from '../redux/slices/authSlice';
import { setUser } from '../redux/slices/userSlice';
import axios from 'axios';
import { persistor } from '../redux/store';
// Updated mock data for signed clients
const clients = [
  { id: 1, name: 'John Doe', email: 'john@example.com', phone: '+1 (555) 123-4567', signupDate: '2023-05-01', affiliateCode: 'AF001', paidStatus: true, paidAmount: 500 },
  { id: 2, name: 'Jane Smith', email: 'jane@example.com', phone: '+1 (555) 234-5678', signupDate: '2023-05-03', affiliateCode: 'AF002', paidStatus: false, paidAmount: 0 },
  { id: 3, name: 'Bob Johnson', email: 'bob@example.com', phone: '+1 (555) 345-6789', signupDate: '2023-05-05', affiliateCode: 'AF001', paidStatus: true, paidAmount: 750 },
  { id: 4, name: 'Alice Brown', email: 'alice@example.com', phone: '+1 (555) 456-7890', signupDate: '2023-05-07', affiliateCode: 'AF003', paidStatus: false, paidAmount: 0 },
  { id: 5, name: 'Charlie Wilson', email: 'charlie@example.com', phone: '+1 (555) 567-8901', signupDate: '2023-05-09', affiliateCode: 'AF002', paidStatus: true, paidAmount: 1000 },
  { id: 6, name: 'David Lee', email: 'david@example.com', phone: '+1 (555) 678-9012', signupDate: '2023-05-11', affiliateCode: 'AF001', paidStatus: true, paidAmount: 600 },
  { id: 7, name: 'Eva Garcia', email: 'eva@example.com', phone: '+1 (555) 789-0123', signupDate: '2023-05-13', affiliateCode: 'AF003', paidStatus: false, paidAmount: 0 },
  { id: 8, name: 'Frank White', email: 'frank@example.com', phone: '+1 (555) 890-1234', signupDate: '2023-05-15', affiliateCode: 'AF002', paidStatus: true, paidAmount: 800 },
];

// Mock data for affiliate payments
const affiliatePayments = [
  { id: 1, date: '2023-05-07', amount: 1000 },
  { id: 2, date: '2023-05-14', amount: 1500 },
  { id: 3, date: '2023-05-21', amount: 2000 },
];

export default function AffiliateDashboard() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [page, setPage] = useState(1);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [user, setUser] = useState(null);
  const itemsPerPage = 5;

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      ((event).key === 'Tab' || (event).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

//   const handleGoogleLogin = () => {
//     // Simulating Google login
//     setIsLoggedIn(true);
//     setUser({ name: 'John Doe', email: 'john.doe@example.com' });
//   };

//   const handleLogout = () => {
//     setIsLoggedIn(false);
//     setUser(null);
//   };

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {['Dashboard', 'Clients', 'Earnings'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index === 0 ? <DashboardIcon /> : index === 1 ? <PeopleIcon /> : <MoneyIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const totalEarnings = clients.reduce((sum, client) => sum + client.paidAmount, 0);
//   const totalClients = clients.length;
//   const paidClients = clients.filter(client => client.paidStatus).length;
//   const totalPayments = affiliatePayments.reduce((sum, payment) => sum + payment.amount, 0);
//   const remainingEarnings = totalEarnings - totalPayments;
//   const totalPayments=clients.reduce((sum, client) => sum + client.paidAmount, 0)
  const paginatedClients = clients.slice((page - 1) * itemsPerPage, page * itemsPerPage);
  const isLoggedIn = useSelector((state) => !!state.auth.token);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: storeIdParam } = useParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'error' });
  const [storeId, setStoreId] = useState(storeIdParam || '');
  const handleGoogleLogin = async (credentialResponse) => {
    dispatch(clearToken());
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/auth`, credentialResponse);
      console.log('Google Login successful!', response.data);

      dispatch(setToken(response.data.token));
      dispatch(setUser(response.data.user));
    //   navigate('/stores'); 
    } catch (error) {
      console.error('Error logging in with Google:', error);
      setAlert({ open: true, message: error.response?.data?.message || 'Google login failed. Please try again.', severity: 'error' });
    }
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };
  
  const token = useSelector((state) => state.auth.token);
  const fetchEarnings = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND+'/api/my-referrals',
        {
            headers: {
              Authorization: `JWT ${token}`,
            },
          }
      );
      setData(response.data)
    //   setSuccessMessage(response.data.message);
    //   setErrorMessage('');
    } catch (error) {
    //   setSuccessMessage('');
    //   setErrorMessage(error.response?.data?.message || 'An error occurred');
    }
  };
useEffect(()=>{
    fetchEarnings()
},[])

const [data,setData] = useState({
    totalReferrals: 1,
    paidReferrals: 0,
    unpaidReferrals: 1,
    totalEarnings: 0,
    pendingEarnings: 0,
    referredUsers: [
      {
        user: {
          _id: "671da231d3c98ad729fb430a",
          email: "carspacekenya@gmail.com",
          name: "carspace",
          picture: "https://lh3.googleusercontent.com/a/ACg8ocLLPg1y3fF8lViENI-VNY2ZItF-Lvs46pKRGlH6hajw59PN-A=s96-c"
        },
        signupDate: "2024-10-27T02:16:08.881Z",
        status: "unpaid",
        totalPaid: 0
      }
    ]
  });

  // Extracting values from data object
  const remainingEarnings = data.pendingEarnings;
  const totalClients = data.totalReferrals;
  const paidClients = data.paidReferrals;
  const totalPayments = data.totalEarnings;
  const referredUsers = data.referredUsers;
  if (!isLoggedIn) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Affiliate Program
            </Typography>
            <Button color="inherit">About</Button>
            <Button color="inherit">Contact</Button>
          </Toolbar>
        </AppBar>
        <Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 64px)', // Subtract AppBar height
    backgroundImage: 'url(https://www.speedysticks.com/wp-content/uploads/AFF1.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative', // Required for backdrop-filter to work
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor:"rgba(0,0,0,0.5)",
      backdropFilter: 'blur(5px)', // Blur effect
      zIndex: 1,
    }
  }}
>
  <Container maxWidth="xs" sx={{ zIndex: 2 }}> {/* Keep content above backdrop */}
    <Card
      elevation={6}
      sx={{
        borderRadius: 2,
        overflow: 'hidden',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
      }}
    >
      <CardContent
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}
      >
        <Avatar sx={{ width: 56, height: 56, bgcolor: 'primary.main', mb: 2 }}>
          <MoneyIcon sx={{ fontSize: 32 }} />
        </Avatar>
        <Typography variant="h5" component="h1" gutterBottom>
          Affiliate Dashboard
        </Typography>
        <Typography variant="body2" align="center" sx={{ mb: 3 }}>
          Sign in to access your dashboard
        </Typography>
        <GoogleLogin
                  onSuccess={handleGoogleLogin}
                  onError={() => {
                    console.log('Google Login Failed');
                    setAlert({ open: true, message: 'Google login failed. Please try again.', severity: 'error' });
                  }}
                />
      </CardContent>
    </Card>
  </Container>
</Box>

      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
           Affiliate
          </Typography>
          {user && (
            <Typography variant="body1" sx={{ mr: 2 }}>
         {user.name}
            </Typography>
          )}
          <IconButton color="inherit" onClick={()=>{
                 dispatch(clearToken());
                 persistor.purge(); 
          }} >
            
            <LogoutIcon />
         
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        {drawerContent}
      </Drawer>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ maxWidth: '1400px', margin: '0 auto' }}>
        <Alert severity="info" sx={{ marginBottom: 3 }}>
          Payments are processed and sent by the end of each week.
        </Alert>
        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <AccountBalanceIcon sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                <Box>
                  <Typography color="textSecondary" gutterBottom>
                    Remaining Earnings
                  </Typography>
                  <Typography variant="h5" component="div">
                    ${remainingEarnings}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <GroupIcon sx={{ fontSize: 40, color: 'secondary.main', mr: 2 }} />
                <Box>
                  <Typography color="textSecondary" gutterBottom>
                    Total Clients
                  </Typography>
                  <Typography variant="h5" component="div">
                    {totalClients}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleIcon sx={{ fontSize: 40, color: 'success.main', mr: 2 }} />
                <Box>
                  <Typography color="textSecondary" gutterBottom>
                    Paid Clients
                  </Typography>
                  <Typography variant="h5" component="div">
                    {paidClients}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <AttachMoney sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                <Box>
                  <Typography color="textSecondary" gutterBottom>
                    Total Paid to Affiliate
                  </Typography>
                  <Typography variant="h5" component="div">
                    ${totalPayments}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Typography variant="h4" gutterBottom>
          Signed Clients
        </Typography>
        <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
          <Table sx={{ minWidth: 650 }} aria-label="client table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Signup Date</TableCell>
                <TableCell>Paid Status</TableCell>
                <TableCell>Total Paid</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {referredUsers.map((client) => (
                <TableRow key={client.user._id}>
                  <TableCell component="th" scope="row">
                    {client.user.name}
                  </TableCell>
                  <TableCell>{client.user?.email}</TableCell>
                  <TableCell>{new Date(client.signupDate).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <Chip
                      label={client.status === 'paid' ? 'Paid' : 'Unpaid'}
                      color={client.status === 'paid' ? 'success' : 'error'}
                    />
                  </TableCell>
                  <TableCell>${client.totalPaid}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Affiliate Payments Table */}
        <Typography variant="h4" gutterBottom>
          Affiliate Payments
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="affiliate payments table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Example payments data */}
              <TableRow>
                <TableCell component="th" scope="row">
                  No payments yet
                </TableCell>
                <TableCell align="right">$0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <strong>Total Payments</strong>
                </TableCell>
                <TableCell align="right"><strong>${totalPayments}</strong></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
    </Box>
  );
}
import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Snackbar,
  Alert,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  InputAdornment
} from '@mui/material';
import {
  Email,
  Phone,
  LocationOn,
  Delete,
  MarkEmailRead,
  Email as EmailIcon,
  Phone as PhoneIcon
} from '@mui/icons-material';
import axios from 'axios';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Phone number formatting
  const formatPhoneNumber = (value) => {
    const phone = value?.replace(/\D/g, '');
    if (phone?.length >= 10) {
      return `(${phone.slice(0,3)}) ${phone.slice(3,6)}-${phone.slice(6,10)}`;
    }
    return phone;
  };

  // Handle phone input
  const handlePhoneChange = (e) => {
    const input = e.target.value?.replace(/\D/g, '');
    if (input?.length <= 10) {
      setFormData({ ...formData, phone: input });
    }
  };

  // Fetch messages
  const fetchMessages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/messages`);
      setMessages(response.data);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error fetching messages',
        severity: 'error'
      });
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.phone?.length !== 10) {
      setSnackbar({
        open: true,
        message: 'Please enter a valid 10-digit phone number',
        severity: 'error'
      });
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/messages`, formData);
      setSnackbar({
        open: true,
        message: 'Message sent successfully!',
        severity: 'success'
      });
      setFormData({ name: '', email: '', phone: '', message: '' });
      fetchMessages();
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Error sending message',
        severity: 'error'
      });
    }
  };

  const handleMarkAsRead = async (id) => {
    try {
      await axios.patch(`${process.env.REACT_APP_BACKEND}/api/messages/${id}`, { status: 'read' });
      fetchMessages();
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error updating message status',
        severity: 'error'
      });
    }
  };

  // Handle message deletion
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/messages/${id}`);
      fetchMessages();
      setSnackbar({
        open: true,
        message: 'Message deleted successfully',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error deleting message',
        severity: 'error'
      });
    }
  };
  return (
    <Box sx={{ bgcolor: 'background.paper', py: 8 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Contact Form */}
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              Contact Us
            </Typography>
            <Paper elevation={3} sx={{ p: 3 }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      name="phone"
                      value={formatPhoneNumber(formData.phone)}
                      onChange={handlePhoneChange}
                      required
                      placeholder="(123) 456-7890"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon />
                          </InputAdornment>
                        ),
                      }}
                      helperText="Enter a 10-digit phone number"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Message"
                      name="message"
                      multiline
                      rows={4}
                      value={formData.message}
                      onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Send Message
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>

          {/* Messages List */}
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              Messages
            </Typography>
            <Paper elevation={3}>
              <List>
                {messages.map((message) => (
                  <ListItem
                    key={message._id}
                    button
                    onClick={() => {
                      setSelectedMessage(message);
                      setOpenDialog(true);
                    }}
                  >
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography variant="subtitle1">
                            {message.name}
                          </Typography>
                          {message.status === 'unread' && (
                            <Chip
                              size="small"
                              color="primary"
                              label="New"
                            />
                          )}
                        </Box>
                      }
                      secondary={
                        <>
                          <Typography variant="body2" color="textSecondary">
                            {message.email} • {formatPhoneNumber(message.phone)}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {message.message}
                          </Typography>
                        </>
                      }
                    />
             <ListItemSecondaryAction>
                      {message.status === 'unread' && (
                        <IconButton
                          edge="end"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleMarkAsRead(message._id);
                          }}
                        >
                          <MarkEmailRead />
                        </IconButton>
                      )}
                      <IconButton
                        edge="end"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(message._id);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>

        {/* Message Dialog */}
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          {selectedMessage && (
            <>
              <DialogTitle>
                Message from {selectedMessage.name}
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      <EmailIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                      {selectedMessage.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      <PhoneIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                      {formatPhoneNumber(selectedMessage.phone)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" paragraph>
                      {selectedMessage.message}
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDialog(false)}>
                  Close
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>


        {/* Snackbar */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default ContactPage;
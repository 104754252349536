// CategoryModal.js
import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Button,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CategoryModal = ({ open, onClose, storeId }) => {
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');
  const [editingCategoryId, setEditingCategoryId] = useState(null);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/category/${storeId}`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleAddCategory = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/category/${storeId}`, {
        name: categoryName,
        description: categoryDescription,
      });
    //   setCategories((prev) => [...prev, response.data.category]);
      setCategoryName('');

      setCategoryDescription('');
      fetchCategories()
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  const handleUpdateCategory = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/category/${storeId}/${editingCategoryId}`, {
        name: categoryName,
        description: categoryDescription,
      });
      fetchCategories(); // Refresh the category list
      setCategoryName('');
      setCategoryDescription('');
      setEditingCategoryId(null);
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/category/${storeId}/${categoryId}`);
      setCategories((prev) => prev.filter((cat) => cat._id !== categoryId));
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  const handleEditCategory = (category) => {
    setCategoryName(category.name);
    setCategoryDescription(category.description);
    setEditingCategoryId(category._id);
  };

  useEffect(() => {
    if (open) {
      fetchCategories();
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2" gutterBottom>
          Manage Categories
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <Close />
        </IconButton>
        <TextField
          label="Category Name"
          variant="outlined"
          fullWidth
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          sx={{ mb: 2 }}
        />
        {/* <TextField
          label="Description"
          variant="outlined"
          fullWidth
          value={categoryDescription}
          onChange={(e) => setCategoryDescription(e.target.value)}
          sx={{ mb: 2 }}
        /> */}
        <Button
          variant="contained"
          onClick={editingCategoryId ? handleUpdateCategory : handleAddCategory}
          sx={{ mb: 2 }}
        >
          {editingCategoryId ? 'Update Category' : 'Add Category'}
        </Button>
        <List>
          {categories.map((category) => (
            <ListItem key={category._id}>
              <ListItemText primary={category.name} secondary={category.description} />
              <IconButton onClick={() => handleEditCategory(category)}>
                <Typography>Edit</Typography>
              </IconButton>
              <IconButton onClick={() => handleDeleteCategory(category._id)}>
                <Typography>Delete</Typography>
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Modal>
  );
};

export default CategoryModal;

import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './slices/authSlice';
import userReducer from './slices/userSlice';
import storeReducer from './slices/storeSlice'; // Import the store slice

const authPersistConfig = {
    key: 'auth',
    storage,
    whitelist: ['token'], // Only persist the token
};

const userPersistConfig = {
    key: 'user',
    storage,
    whitelist: ['_id', 'email', 'name', 'picture'], // Persist user fields
};

const storePersistConfig = {
    key: 'store',
    storage,
    whitelist: ['name', 'location'], // Persist store fields
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedUserReducer = persistReducer(userPersistConfig, userReducer);
const persistedStoreReducer = persistReducer(storePersistConfig, storeReducer); // Persist store reducer

const store = configureStore({
    reducer: {
        auth: persistedAuthReducer,
        user: persistedUserReducer,
        store: persistedStoreReducer, // Add store to the root reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export const persistor = persistStore(store);
export default store;

'use client'

import React, { useState, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Typography,
  Snackbar,
  Alert,
  Grid
} from '@mui/material'
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

export default function SupplierList() {
  const { id: storeIdParam } = useParams()
  const [suppliers, setSuppliers] = useState([])
  const [filteredSuppliers, setFilteredSuppliers] = useState([])
  const [open, setOpen] = useState(false)
  const [selectedSupplier, setSelectedSupplier] = useState(null)
  const [formData, setFormData] = useState({
    companyName: '',
    contactPerson: '',
    email: '',
    phone: '',
    address: {
      street: '',
      city: '',
      state: '',
      zipCode: ''
    }
  })

  const [snackOpen, setSnackOpen] = useState(false)
  const [snackMessage, setSnackMessage] = useState('')
  const [snackSeverity, setSnackSeverity] = useState('success')
  const [searchQuery, setSearchQuery] = useState('')
  const isMobile = useMediaQuery('(max-width:600px)')

  const fetchSuppliers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/supplier/${storeIdParam}`)
      const data = await response.json()
      setSuppliers(data)
      setFilteredSuppliers(data)
    } catch (error) {
      console.error('Error fetching suppliers:', error)
      setSnackMessage('Error fetching suppliers')
      setSnackSeverity('error')
      setSnackOpen(true)
    }
  }

  useEffect(() => {
    fetchSuppliers()
  }, [storeIdParam])

  useEffect(() => {
    const results = suppliers.filter((supplier) =>
      supplier.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      supplier.contactPerson.toLowerCase().includes(searchQuery.toLowerCase())
    )
    setFilteredSuppliers(results)
  }, [searchQuery, suppliers])

  const handleOpen = (supplier = null) => {
    if (supplier) {
      setSelectedSupplier(supplier)
      setFormData(supplier)
    } else {
      setSelectedSupplier(null)
      setFormData({
        companyName: '',
        contactPerson: '',
        email: '',
        phone: '',
        address: {
          street: '',
          city: '',
          state: '',
          zipCode: ''
        }
      })
    }
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedSupplier(null)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name.includes('.')) {
      const [parent, child] = name.split('.')
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }))
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }))
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const url = selectedSupplier
        ? `${process.env.REACT_APP_BACKEND}/api/supplier/${storeIdParam}/${selectedSupplier._id}`
        : `${process.env.REACT_APP_BACKEND}/api/supplier/${storeIdParam}`
      const method = selectedSupplier ? 'PUT' : 'POST'

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })

      if (response.ok) {
        fetchSuppliers()
        handleClose()
        setSnackMessage(selectedSupplier ? 'Supplier updated successfully' : 'Supplier added successfully')
        setSnackSeverity('success')
        setSnackOpen(true)
      } else {
        setSnackMessage('Failed to save supplier')
        setSnackSeverity('error')
        setSnackOpen(true)
      }
    } catch (error) {
      console.error('Error saving supplier:', error)
      setSnackMessage('Error saving supplier')
      setSnackSeverity('error')
      setSnackOpen(true)
    }
  }

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this supplier?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/supplier/${storeIdParam}/${id}`, {
          method: 'DELETE',
        })

        if (response.ok) {
          fetchSuppliers()
          setSnackMessage('Supplier deleted successfully')
          setSnackSeverity('success')
          setSnackOpen(true)
        } else {
          setSnackMessage('Failed to delete supplier')
          setSnackSeverity('error')
          setSnackOpen(true)
        }
      } catch (error) {
        console.error('Error deleting supplier:', error)
        setSnackMessage('Error deleting supplier')
        setSnackSeverity('error')
        setSnackOpen(true)
      }
    }
  }

  const handleSnackClose = () => {
    setSnackOpen(false)
  }

  return (
    <div style={{padding:10}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px 0' }}>
        <Typography variant="h5">Suppliers</Typography>
        <Button variant="contained" color="primary" onClick={() => handleOpen()}>
          Add Supplier
        </Button>
      </div>

      <TextField
        label="Search"
        variant="outlined"
        size="small"
        className="mb-4"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
<br/><br/>
      {isMobile ? (
        <Grid container spacing={2}>
          {filteredSuppliers.map((supplier) => (
            <Grid item xs={12} sm={6} md={4} key={supplier._id}>
              <Paper className="p-4">
                <Typography variant="h6">{supplier.companyName}</Typography>
                <Typography>Contact: {supplier.contactPerson}</Typography>
                <Typography>Email: {supplier.email}</Typography>
                <Typography>Phone: {supplier.phone}</Typography>
                <Typography>City: {supplier.address.city}</Typography>
                <Typography>Street: {supplier.address.street}</Typography>
                <div>
                  <IconButton onClick={() => handleOpen(supplier)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(supplier._id)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      ) : (
        <TableContainer component={Paper}>
              <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>Contact Person</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Street</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSuppliers.map((supplier, index) => (
                <TableRow key={supplier._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{supplier.companyName}</TableCell>
                  <TableCell>{supplier.contactPerson}</TableCell>
                  <TableCell>{supplier.email}</TableCell>
                  <TableCell>{supplier.phone}</TableCell>
                  <TableCell>{supplier.address.city}</TableCell>
                  <TableCell>{supplier.address.street}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpen(supplier)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(supplier._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{selectedSupplier ? 'Edit Supplier' : 'Add Supplier'}</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              name="companyName"
              label="Company Name"
              fullWidth
              margin="normal"
              value={formData.companyName}
              onChange={handleChange}
              required
              autoComplete="off"
            />
            <TextField
              name="contactPerson"
              label="Contact Person"
              fullWidth
              margin="normal"
              value={formData.contactPerson}
              onChange={handleChange}
              required
              autoComplete="off"
            />
            <TextField
              name="email"
              label="Email"
              fullWidth
              margin="normal"
              value={formData.email}
              onChange={handleChange}
              required
              autoComplete="off"
              type="email"
            />
            <TextField
              name="phone"
              label="Phone"
              fullWidth
              margin="normal"
              value={formData.phone}
              onChange={handleChange}
              required
              autoComplete="off"
            />
            <TextField
              name="address.street"
              label="Street"
              fullWidth
              margin="normal"
              value={formData.address.street}
              onChange={handleChange}
              required
              autoComplete="off"
            />
            <TextField
              name="address.city"
              label="City"
              fullWidth
              margin="normal"
              value={formData.address.city}
              onChange={handleChange}
              required
              autoComplete="off"
            />
            {/* <TextField
              name="address.state"
              label="State"
              fullWidth
              margin="normal"
              value={formData.address.state}
              onChange={handleChange}
              required
              autoComplete="off"
            /> */}
            {/* <TextField
              name="address.zipCode"
              label="Zip Code"
              fullWidth
              margin="normal"
              value={formData.address.zipCode}
              onChange={handleChange}
              required
              autoComplete="off"
            /> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              {selectedSupplier ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity={snackSeverity} className="w-full">
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}
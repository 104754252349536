import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Pagination,
    CircularProgress,
    TextField,
    Avatar
} from '@mui/material';
import { useSelector } from 'react-redux';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const limit = 10; // Items per page
    const token = useSelector((state) => state.auth.token);
    const BASE_URL = process.env.REACT_APP_BACKEND;

    // Debounce function
    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(null, args);
            }, delay);
        };
    };

    // Fetch users whenever the page or search changes
    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `${BASE_URL}/api/users/all`, 
                    {
                        headers: {
                            Authorization: `JWT ${token}`,
                        },
                        params: {
                            page,
                            limit,
                            search
                        }
                    }
                );
                setUsers(response.data.users);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [page, search, token]); // Fetch when page or search changes

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    // Create a debounced search handler
    const handleSearchChange = debounce((value) => {
        setSearch(value);
        setPage(1); // Reset to page 1 on search
    }, 1000); // 1 second debounce delay

    return (
        <Paper elevation={3} sx={{ p: 2, m: 2 }}>
            <Typography variant="h5" gutterBottom>Users List</Typography>
            
            <TextField
                label="Search by email or name"
                variant="outlined"
                onChange={(event) => handleSearchChange(event.target.value)} // Use the debounced function
                sx={{ mb: 2 }}
                size="small"
            />

            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <TableContainer component={Paper}>
                        <Table size="small" sx={{ minWidth: 650, '& tbody tr:nth-of-type(odd)': { backgroundColor: '#f9f9f9' } }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Picture</TableCell>
                                    <TableCell>No.of stores</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user, index) => (
                                    <TableRow key={user._id}>
                                        <TableCell>{(page - 1) * limit + index + 1}</TableCell>
                                        <TableCell>{user.name}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>
                                            <Avatar alt={user.name} size='small' src={user.picture} />
                                        </TableCell>
                                        <TableCell>{user.storeCount}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
                    />
                </>
            )}
        </Paper>
    );
};

export default UserList;

import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    TextField,
    Typography,
    Grid,
    Snackbar,
    Alert,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
} from '@mui/material';
import axios from 'axios';
import ReactQuill from 'react-quill'; // Import React Quill
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

const API_URL =process.env.REACT_APP_BACKEND+'/api/subscriptions';

const getAllSubscriptions = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

const createSubscription = async (subscription) => {
    const response = await axios.post(API_URL, subscription);
    return response.data;
};

const updateSubscription = async (id, subscription) => {
    const response = await axios.put(`${API_URL}/${id}`, subscription);
    return response.data;
};

const deleteSubscription = async (id) => {
    await axios.delete(`${API_URL}/${id}`);
};

const SubscriptionManager = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [name, setName] = useState('');
    const [amount, setAmount] = useState('');
    const [duration, setDuration] = useState('');
    const [numberOfDays, setNumberOfDays] = useState('');
    const [description, setDescription] = useState('');
    const [selectedId, setSelectedId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const fetchSubscriptions = async () => {
        const data = await getAllSubscriptions();
        setSubscriptions(data);
    };

    useEffect(() => {
        fetchSubscriptions();
    }, []);

    const handleCreateOrUpdate = async () => {
        const subscriptionData = { name, amount, duration, numberOfDays, description };
        if (selectedId) {
            await updateSubscription(selectedId, subscriptionData);
            setSnackbarMessage('Subscription updated successfully!');
        } else {
            await createSubscription(subscriptionData);
            setSnackbarMessage('Subscription created successfully!');
        }
        fetchSubscriptions();
        handleCloseSnackbar();
        resetForm();
    };

    const handleEdit = (subscription) => {
        setName(subscription.name);
        setAmount(subscription.amount);
        setDuration(subscription.duration);
        setNumberOfDays(subscription.numberOfDays);
        setDescription(subscription.description);
        setSelectedId(subscription._id);
    };

    const handleDelete = async (id) => {
        await deleteSubscription(id);
        setSnackbarMessage('Subscription deleted successfully!');
        fetchSubscriptions();
        handleCloseSnackbar();
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const resetForm = () => {
        setName('');
        setAmount('');
        setDuration('');
        setNumberOfDays('');
        setDescription('');
        setSelectedId(null);
    };

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={8}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Manage Subscription Packages
                        </Typography>
                        <TextField 
                            label="Name" 
                            fullWidth 
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                            sx={{ mb: 2 }} // Add margin bottom for spacing
                        />
                        <TextField 
                            label="Amount (Ksh)" 
                            type="number" 
                            fullWidth 
                            value={amount} 
                            onChange={(e) => setAmount(e.target.value)} 
                            sx={{ mb: 2 }} // Add margin bottom for spacing
                        />
                        <TextField 
                            label="Duration" 
                            fullWidth 
                            value={duration} 
                            onChange={(e) => setDuration(e.target.value)} 
                            sx={{ mb: 2 }} // Add margin bottom for spacing
                        />
                        <TextField 
                            label="Number of Days" 
                            type="number" 
                            fullWidth 
                            value={numberOfDays} 
                            onChange={(e) => {
                                // Ensure input is strictly a number
                                const value = e.target.value;
                                if (value === '' || /^[0-9]+$/.test(value)) {
                                    setNumberOfDays(value);
                                }
                            }} 
                            sx={{ mb: 2 }} // Add margin bottom for spacing
                        />
                        <Typography variant="h6" sx={{ mb: 1 }}>Description</Typography>
                        <ReactQuill 
                            value={description} 
                            onChange={setDescription} 
                            style={{ marginBottom: '16px' }} // Add margin bottom for spacing
                        />
                        <Button variant="contained" color="primary" onClick={handleCreateOrUpdate}>
                            {selectedId ? 'Update Subscription' : 'Create Subscription'}
                        </Button>
                    </CardContent>
                </Card>
                <List>
                    {subscriptions.sort((a, b) => a.numberOfDays - b.numberOfDays).map((subscription) => (
                        <ListItem key={subscription._id}>
                            <ListItemText 
                                primary={subscription.name} 
                                secondary={`Ksh ${subscription.amount} - ${subscription.duration} - ${subscription.numberOfDays} days`} 
                            />
                            <ListItemSecondaryAction>
                                <Button variant="outlined" color="primary" onClick={() => handleEdit(subscription)}>
                                    Edit
                                </Button>
                                <Button variant="outlined" color="secondary" onClick={() => handleDelete(subscription._id)}>
                                    Delete
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Grid>

            {/* Snackbar for notifications */}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default SubscriptionManager;

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Typography,
  TablePagination,
  Grid,
  Card,
  CardContent,
  CardHeader,
//   CardContent,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TodayIcon from '@mui/icons-material/Today';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

// Available categories
const categories = [
  'Supplies',
  'Utilities',
  'Rent',
  'Transportation',
  'Advertising',
  'Payroll',
  'Miscellaneous',
  'Insurance',
  'Other',
];

const ExpendituresPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [category, setCategory] = useState('');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [expenditures, setExpenditures] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalExpenditures, setTotalExpenditures] = useState(0);
  const [todayTotal, setTodayTotal] = useState(0); // Total for today
  const [monthTotal, setMonthTotal] = useState(0); // Total for the month
  const { id: storeId } = useParams();
  
  // Date search states
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Fetch totals from API
  const fetchTotals = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/expenditures/${storeId}/totals`);
      setTodayTotal(response.data.todayTotal);
      setMonthTotal(response.data.monthTotal);
    } catch (error) {
      console.error('Error fetching totals:', error);
    }
  };

  // Fetch expenditures from API with pagination and date filters
  const fetchExpenditures = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/expenditures/${storeId}`, {
        params: {
          page,
          limit: rowsPerPage,
          dateFrom,
          dateTo,
        },
      });
      setExpenditures(response.data.expenditures);
      setTotalExpenditures(response.data.totalExpenditures);
    } catch (error) {
      console.error('Error fetching expenditures:', error);
    }
  };

  useEffect(() => {
    fetchTotals(); // Fetch totals on component mount
    fetchExpenditures();
  }, [page, rowsPerPage, storeId, dateFrom, dateTo]);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleExpenditureAdded = async () => {
    try {
      const newExpenditure = { category, amount: parseFloat(amount), description };
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/expenditures/${storeId}`, newExpenditure);

      if (response.status === 201) {
        fetchExpenditures();
        fetchTotals(); // Re-fetch totals after adding a new expenditure
        handleCloseModal();
        setCategory('');
        setAmount('');
        setDescription('');
      }
    } catch (error) {
      console.error('Error adding expenditure:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <div style={{padding:10}}>
      <div style={{ display: 'flex', justifyContent: "space-between", padding: 10 }}>
        <Typography variant="h5">
          Expenditures
        </Typography>
        <Button size="small" variant="outlined" color="primary" onClick={handleOpenModal} sx={{ mb: 2 }}>
          Add
        </Button>
      </div>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={4}>
          <Card sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
            <AttachMoneyIcon sx={{ fontSize: 40, color: 'green' }} />
            <CardContent>
              <Typography variant="h6" component="div">
                Today's Total
              </Typography>
              <Typography variant="h5" component="div">
                Ksh {todayTotal.toFixed(2)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
            <CalendarTodayIcon sx={{ fontSize: 40, color: 'blue' }} />
            <CardContent>
              <Typography variant="h6" component="div">
                This Month's Total
              </Typography>
              <Typography variant="h5" component="div">
                Ksh {monthTotal.toFixed(2)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        </Grid>
      {/* Total Expenditures */}
      {/* <div style={{ marginBottom: '10px' }}>
        <Typography variant="body1">Total Expenditure Today: Ksh {todayTotal.toFixed(2)}</Typography>
        <Typography variant="body1">Total Expenditure This Month: Ksh {monthTotal.toFixed(2)}</Typography>
      </div> */}

      {/* Date Range Search */}
      <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' ,flexWrap:"wrap"}}>
        <TextField
          type="date"
          label="From Date"
          value={dateFrom}
          onChange={(e) => setDateFrom(e.target.value)}
          InputLabelProps={{ shrink: true }}
          autoComplete="off"
          size="small"
        />
        <TextField
          type="date"
          label="To Date"
          value={dateTo}
          onChange={(e) => setDateTo(e.target.value)}
          InputLabelProps={{ shrink: true }}
          autoComplete="off"
             size="small"
        />
        <Button variant="outlined"    size="small" color="primary" onClick={fetchExpenditures}>
          Search
        </Button>
      </div>

      {/* Expenditure Display */}
      {isSmallScreen ? (
        <Grid container spacing={2}>
          {expenditures.map((expenditure) => (
            <Grid item xs={12} sm={6} md={4} key={expenditure._id}>
             <Card sx={{ mb: 2 }}>
  <CardContent>
    <Typography variant="h6" gutterBottom>
      {expenditure.category}
    </Typography>
    <Typography
      variant="h6"
      sx={{ mt: 1, color: 'success.main', fontWeight: 'bold' }}
    >
      Ksh {expenditure.amount.toFixed(2)}
    </Typography>
    <Typography variant="body2" sx={{ mt: 1 }}>
      <strong>Description:</strong> {expenditure.description || 'N/A'}
    </Typography>
    <Typography variant="body2" sx={{ mt: 1 }}>
      <strong>Date:</strong> {formatTimestamp(expenditure.date)}
    </Typography>
  </CardContent>
</Card>

            </Grid>
          ))}
            <TablePagination
            component="div"
            count={totalExpenditures}
            page={page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ marginTop: '16px' }}
          />
        </Grid>
      ) : (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expenditures.map((expenditure, i) => (
                <TableRow key={expenditure._id}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>{expenditure.category}</TableCell>
                  <TableCell>{expenditure.amount.toFixed(2)}</TableCell>
                  <TableCell>{expenditure.description || 'N/A'}</TableCell>
                  <TableCell>{formatTimestamp(expenditure.date)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Pagination Controls */}
      {!isSmallScreen && (
        <TablePagination
          component="div"
          count={totalExpenditures}
          page={page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      {/* Add Expenditure Modal */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" mb={2}>
            Add Expenditure
          </Typography>
          <TextField
            select
            label="Category"
            fullWidth
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            sx={{ mb: 2 }}
          >
            {categories.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Amount"
            type="number"
            fullWidth
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Description"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" color="primary" onClick={handleExpenditureAdded}>
            Add Expenditure
          </Button>
        </Box>
      </Modal>

    </div>
  );
};

export default ExpendituresPage;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    name: '',
    location: '',
};

const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        setStore: (state, action) => {
            const { name, location } = action.payload;
            state.name = name;
            state.location = location;
        },
        clearStore: (state) => {
            state.name = '';
            state.location = '';
        },
    },
});
export const selectStore = (state) => state.store;
export const { setStore, clearStore } = storeSlice.actions;
export default storeSlice.reducer;

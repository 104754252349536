import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, Alert } from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';

function ReferralCodeModal({ open, onClose, referralCode }) {
  const [inputCode, setInputCode] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const token = useSelector((state) => state.auth.token);
  const handleSubmit = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND+'/api/add-referred-user', { affiliateCode: inputCode },
        {
            headers: {
              Authorization: `JWT ${token}`,
            },
          }
      );
      setSuccessMessage(response.data.message);
      setErrorMessage('');
    } catch (error) {
      setSuccessMessage('');
      setErrorMessage(error.response?.data?.message || 'An error occurred');
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
          Enter Referral Code
        </Typography>
        <TextField
          label="Referral Code"
          variant="outlined"
          fullWidth
          value={inputCode}
          onChange={(e) => setInputCode(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit Code
        </Button>
        <Button variant="text" color="secondary" onClick={onClose} sx={{ mt: 2 }}>
          Close
        </Button>
        {successMessage && <Alert severity="success" sx={{ mt: 2 }}>{successMessage}</Alert>}
        {errorMessage && <Alert severity="error" sx={{ mt: 2 }}>{errorMessage}</Alert>}
      </Box>
    </Modal>
  );
}

export default ReferralCodeModal;

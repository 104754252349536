import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel,
  Paper, Input, Select, MenuItem, AppBar, Toolbar, Typography, IconButton, Menu, Avatar,
  CircularProgress,Button,
  Chip
} from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import SubscriptionCard from './checkSub';
// import PaymentModal from './paymentModal';

const PaymentsTable = () => {
  const [payments, setPayments] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchField, setSearchField] = useState('PhoneNumber');
  const [sortBy, setSortBy] = useState('Timestamp');
  const [sortDirection, setSortDirection] = useState('desc');
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { id: storeIdParam } = useParams();
  
  const columns = [
    { id: 'rowNumber', label: 'No.', sortable: false },  // New column for row numbering
    { id: 'Timestamp', label: 'Timestamp', sortable: false },
    { id: 'Amount', label: 'Amount', sortable: false },
    { id: 'PhoneNumber', label: 'Phone Number', sortable: false },
    { id: 'AccountReference', label: 'Account Reference', sortable: false },
    { id: 'Order_ID', label: 'Order ID', sortable: false },
    // { id: 'CheckoutRequestID', label: 'Checkout Request ID', sortable: true },
    { id: 'ResponseCode', label: 'Request', sortable: false },
    // { id: 'ResponseDescription', label: 'Response Description', sortable: true },
    { id: 'ResultCode', label: 'Result', sortable: false },
    { id: 'expiryDate', label: 'ExpiryDate', sortable: false },
    // { id: 'ResultDesc', label: 'ResultDesc', sortable: true },
  ];

  useEffect(() => {
    fetchPayments();
  }, [page, rowsPerPage, searchText, searchField, sortBy, sortDirection, startDate, endDate]);
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const fetchPayments = async () => {
    setLoading(true);
    try {
    //   console.log({
    //     page: page + 1,
    //     limit: rowsPerPage,
    //     search: searchField === 'Timestamp' ? '' : searchText,
    //     searchField: searchField,
    //     sortBy: sortBy,
    //     sortDirection: sortDirection,
    //     startDate: startDate ? startDate.toISOString() : null,
    //     endDate: endDate ? endDate.toISOString() : null,
    //   });
  
      const response = await axios.get(process.env.REACT_APP_BACKEND+'/api/payment', {
        params: {
            storeId:storeIdParam,
          page: page + 1,
          limit: rowsPerPage,
          search: searchField === 'Timestamp' ? '' : searchText,
          searchField: searchField,
          sortBy: sortBy,
          sortDirection: sortDirection,
          startDate: startDate ? startDate.toISOString() : null,
          endDate: endDate ? endDate.toISOString() : null,
        }
      });
  
      console.log(response.data);
  
      const formattedPayments = response.data.payments.map(payment => ({
        ...payment,
        Timestamp: convertTime(payment.Timestamp),
        expiryDate:formatTimestamp(payment.expiryDate)
      }));
  
      setPayments(formattedPayments);
      setTotalCount(response.data.totalPayments);
    } catch (error) {
      console.error('Error fetching payments:', error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const debouncedSearch = debounce((value) => {
    setSearchText(value);
    setPage(0);
  }, 500);

  const handleSearchChange = (event) => {
    debouncedSearch(event.target.value);
  };

  const handleSearchFieldChange = (event) => {
    setSearchField(event.target.value);
    setSearchText('');
    setStartDate(null);
    setEndDate(null);
    setPage(0);
  };

  const handleSort = (columnId) => {
    const isAsc = sortBy === columnId && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(columnId);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    console.log('Logging out...');
    handleMenuClose();
  };

  const convertTime = (timestamp) => {
    const year = parseInt(timestamp.slice(0, 4));
    const month = parseInt(timestamp.slice(4, 6)) - 1;
    const day = parseInt(timestamp.slice(6, 8));
    const hour = parseInt(timestamp.slice(8, 10));
    const minute = parseInt(timestamp.slice(10, 12));
    const second = parseInt(timestamp.slice(12, 14));

    const date = new Date(year, month, day, hour, minute, second);

    const daySuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    const formattedDate = `${day}${daySuffix(day)} ${date.toLocaleString('en-US', { month: 'short' })} ${year}, ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`;
    return formattedDate;
  };
  const [showTable, setShowTable] = useState(false);
  const toggleTableVisibility = () => {
    setShowTable(prevShowTable => !prevShowTable);
  };
  return (
    <div>
    <SubscriptionCard/>
      <div style={{ padding: '15px', marginTop: '40px' }}>
        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'start', gap: 10, flexWrap: 'wrap' }}>
          {/* <PaymentModal /> */}
          <div style={{ flex: 1 }}></div>
          {/* <Select
            value={searchField}
            size='small'
            onChange={handleSearchFieldChange}
            style={{ marginRight: '10px' }}
          >
            {columns.map((col) => (
              <MenuItem key={col.id} value={col.id}>
                {col.label}
              </MenuItem>
            ))}
          </Select>
          {searchField === 'Timestamp' ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ display: 'flex', gap: '10px' }}>
                <DatePicker
                  label="Start Date"
                  size='small'
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                    setPage(0);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                    setPage(0);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </LocalizationProvider>
          ) : (
            <Input
              type="text"
              placeholder={`Search by ${columns.find(col => col.id === searchField)?.label || 'Field'}`}
              value={searchText}
              onChange={handleSearchChange}
            />
          )}*/}
        </div> 
        
        <Button variant="contained" onClick={toggleTableVisibility}>
          {showTable ? 'Hide Payments Table' : 'Show Payments'}
        </Button>
        
        {showTable && (   <TableContainer component={Paper}>
          <Table size="small" sx={{
            '& .MuiTableRow-root:nth-of-type(odd)': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },}}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    {column.sortable ? (
                      <TableSortLabel
                        active={sortBy === column.id}
                        direction={sortBy === column.id ? sortDirection : 'asc'}
                        onClick={() => handleSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                payments.map((payment, index) => (
                  <TableRow key={payment.id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell> {/* Row Number */}
                    {columns.slice(1).map((column) =>{
 
                  if(column.id=="ResponseCode" ){
                    return <TableCell key={column.id}>  <Chip label='Sent'size='small'  color='default' /></TableCell>
                  }
                  if(column.id=="ResultCode") {
                    if(payment[column.id]==0){
                         return <TableCell key={column.id}>  <Chip label='Success' size='small' color='success' /></TableCell>
               
                    }else{
                      return <TableCell key={column.id}>  <Chip label='Error' size='small' color='warning' /></TableCell>
               
                    }
                    }
                    return  <TableCell key={column.id}>{payment[column.id]}</TableCell>
                    

                    })}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>)}
        
        {/* <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </div>
    </div>
  );
};

export default PaymentsTable;

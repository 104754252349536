import React, { useState } from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import Slider from 'react-slick';
import CloseIcon from '@mui/icons-material/Close';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const  ImageModal = ({ open, onClose, images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Slider {...settings}>
          {images.map((img, index) => (
            <Box key={index} sx={{ textAlign: 'center' }}>
              <img src={img} alt={`Slide ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
            </Box>
          ))}
        </Slider>
      </Box>
    </Modal>
  );
};
export default ImageModal;
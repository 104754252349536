import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TextField,
  TableRow,
  IconButton,
  Paper,
  Typography,
  TablePagination,
  Box,
  CircularProgress,
  Avatar,
  Modal,
  Button,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
  MenuItem,
  Chip
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { KeyboardArrowDown, KeyboardArrowUp, ArrowBack, ArrowForward, AttachMoney, ShoppingCart } from '@mui/icons-material';
import { green, blue, orange, red } from '@mui/material/colors';

const SummaryCard = ({ title, value, icon, color }) => (
  <Card sx={{ mb: 2 }}>
    <CardContent>
      <Grid container spacing={2}>
        <Grid item>
          <Avatar sx={{ bgcolor: color }}>
            {icon}
          </Avatar>
        </Grid>
        <Grid item>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="h4">
            {value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export default function OrderStatistics() {
  const [orderData, setOrderData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productOrders, setProductOrders] = useState(null);
  const [productOrdersPage, setProductOrdersPage] = useState(0);
  const [summary, setSummary] = useState({ totalOrders: 0, totalAmount: 0 });
  const { id: storeIdParam } = useParams();
  const token = useSelector((state) => state.auth.token);
  const BASE_URL = `${process.env.REACT_APP_BACKEND}`;

  // Search states
  const [searchFilters, setSearchFilters] = useState({
    orderId: '',
    seller: '',
    minAmount: '',
    maxAmount: '',
    dateFrom: new Date().toISOString().slice(0, 10),
    dateTo: new Date().toISOString().slice(0, 10),
    productName: '',
    barcode: '',
    status: ''
  });

  const [showSearch, setShowSearch] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const debouncedFetch = useCallback(
    debounce((filters) => {
      fetchOrders(filters);
      fetchSummary(storeIdParam, filters);
    }, 1000),
    [storeIdParam]
  );

  // Debounced search function
  const immediateFetch = useCallback((filters) => {
    fetchOrders(filters);
    fetchSummary(storeIdParam, filters);
  }, [storeIdParam]);

  useEffect(() => {
    // Perform immediate fetch on mount and when key parameters change
    immediateFetch(searchFilters);
  }, [immediateFetch, storeIdParam, page, rowsPerPage]);

  useEffect(() => {
    // Use debounced fetch for search filter changes
    debouncedFetch(searchFilters);
  }, [debouncedFetch, searchFilters]);

  // Update search filters
  const handleFilterChange = (field) => (event) => {
    const newFilters = {
      ...searchFilters,
      [field]: event.target.value
    };
    setSearchFilters(newFilters);
    debouncedFetch(newFilters);
  };

  const fetchOrders = async (filters) => {
    setLoading(true);
   var filter_2= filters;
   filter_2.dateTo= filter_2.dateFrom
    try {
      const searchParams = new URLSearchParams({
        page: page + 1,
        limit: rowsPerPage,
        ...filter_2
      });

      const response = await fetch(`${BASE_URL}/api/summary-2/${storeIdParam}?${searchParams}`, {
        headers: {
          Authorization: `JWT ${token}`,
        }
      });
      const data = await response.json();
      setOrderData(data);
    } catch (error) {
      console.error('Error fetching order data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSummary = async (id, filters) => {
    try {
      const queryParams = new URLSearchParams(filters);
      const response = await fetch(`${BASE_URL}/api/summary/${id}?${queryParams}`, {
        headers: {
          Authorization: `JWT ${token}`,
        }
      });
      const data = await response.json();
      setSummary(data);
    } catch (error) {
      console.error('Error fetching summary:', error);
    }
  };

  useEffect(() => {
    debouncedFetch(searchFilters);
  }, [page, rowsPerPage, storeIdParam]);

  const clearSearchFields = () => {
    const newFilters = {
      ...searchFilters,
      orderId: '',
      seller: '',
      minAmount: '',
      maxAmount: '',
      productName: '',
      barcode: '',
      status: ''
    };
    setSearchFilters(newFilters);
    debouncedFetch(newFilters);
  };

  const handleDateNavigation = (direction) => {
    const newDate = new Date(searchFilters.dateFrom);
    newDate.setDate(newDate.getDate() + direction);
    const newFilters = {
      ...searchFilters,
      dateFrom: formatTimestamp(newDate).slice(0, 10)
    };
    setSearchFilters(newFilters);
    debouncedFetch(newFilters);
  };

  function formatDate(date) {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    
    const inputDate = new Date(date);
    inputDate.setHours(0, 0, 0, 0);
    
    const options = { weekday: 'short' };
    const options2 = { weekday: "long" };
    const dayOfWeek = inputDate.toLocaleString('default', options);
    const dayOfWeek2 = inputDate.toLocaleString('default', options2);
    
    if (inputDate.toDateString() === today.toDateString()) {
      return 'Today (' + dayOfWeek2 + ")";
    }
    
    if (inputDate.toDateString() === yesterday.toDateString()) {
      return 'Yesterday (' + dayOfWeek2 + ")";
    }
    
    const day = inputDate.getDate();
    const month = inputDate.toLocaleString('default', { month: 'short' });
    const year = inputDate.getFullYear();
    
    const suffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };
    
    return `${dayOfWeek}, ${day}${suffix(day)} ${month} ${year}`;
  }

  // Product order handling
  const fetchProductOrders = async (productId) => {
    try {
      const searchParams = new URLSearchParams({
        page: (productOrdersPage + 1).toString(),
        limit: '5',
        orderId: searchFilters.orderId,
        seller: searchFilters.seller,
        minAmount: searchFilters.minAmount,
        maxAmount: searchFilters.maxAmount,
        dateFrom: searchFilters.dateFrom,
        dateTo:searchFilters.dateFrom,
        productName: searchFilters.productName,
        barcode: searchFilters.barcode,
        paymentStatus: searchFilters.status
      });

      const response = await fetch(
        `${BASE_URL}/api/product-orders/${storeIdParam}/${productId}?page=${searchParams}`,
        {
          headers: {
            Authorization: `JWT ${token}`,
          }
        }
      );
      const data = await response.json();
      setProductOrders(data);
    } catch (error) {
      console.error('Error fetching product orders:', error);
    }
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setProductOrdersPage(0);
    fetchProductOrders(product.productId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedProduct(null);
    setProductOrders(null);
  };

  const handleProductOrdersPageChange = (event, newPage) => {
    setProductOrdersPage(newPage);
    fetchProductOrders(selectedProduct.productId);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleSearch = () => {
    setShowSearch((prev) => !prev);
  };

  // if (loading) {
  //   return (
  //     <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
  //       <CircularProgress />
  //     </Box>
  //   );
  // }

  if (!orderData) {
    return <Typography>No order data available.</Typography>;
  }

  return (
    <Box sx={{ width: '100%', overflow: 'hidden', p: 1 }}>
      <Box display="flex" alignItems="center">
        <IconButton onClick={() => handleDateNavigation(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" style={{ margin: '0 10px' }}>
          {formatDate(searchFilters.dateFrom)}
        </Typography>
        <IconButton onClick={() => handleDateNavigation(1)}>
          <ArrowForward />
        </IconButton>
      </Box>

      <Grid container spacing={2}>
        {[
          { title: 'Total Sales', value: summary.totalAmount, icon: <AttachMoney />, color: green[500] },
          { title: 'Total Orders', value: summary.totalOrders, icon: <ShoppingCart />, color: blue[500] },
        ].map((data, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <SummaryCard
              title={data.title}
              value={data.value}
              icon={data.icon}
              color={data.color}
            />
          </Grid>
        ))}
      </Grid>

      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'end' }}>
        <Button onClick={toggleSearch} size="small" variant="outlined" style={{ marginBottom: '16px', alignSelf: "end" }}>
          {showSearch ? 'Hide Search' : 'Show Search'}
        </Button>
      </div>

      <div style={{ marginBottom: 0, display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
        {!showSearch && (
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Product Name"
              variant="outlined"
              value={searchFilters.productName}
              onChange={handleFilterChange('productName')}
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        )}
      </div>

      {showSearch && (
        <Paper style={{ padding: '16px', marginBottom: '20px' }}>
          <Typography variant="h6" gutterBottom>
            Search Orders
          </Typography>
          <Grid container spacing={2} style={{ marginBottom: '16px' }}>
         <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Seller"
                variant="outlined"
                value={searchFilters.seller}
                onChange={handleFilterChange('seller')}
                size="small"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
             {/*   <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Min Amount"
                variant="outlined"
                value={searchFilters.minAmount}
                onChange={handleFilterChange('minAmount')}
                size="small"
                InputLabelProps={{ shrink: true }}
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Max Amount"
                variant="outlined"
                value={searchFilters.maxAmount}
                onChange={handleFilterChange('maxAmount')}
                size="small"
                InputLabelProps={{ shrink: true }}
              /> 
            </Grid>*/}
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Date"
                type="date"
                variant="outlined"
                value={searchFilters.dateFrom}
                onChange={handleFilterChange('dateFrom')}
                size="small"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Product Name"
                variant="outlined"
                value={searchFilters.productName}
                onChange={handleFilterChange('productName')}
                size="small"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={3}>
              <TextField
                select
                fullWidth
                label="Payment Status"
                variant="outlined"
                value={searchFilters.status}
                onChange={handleFilterChange('status')}
                size="small"
                InputLabelProps={{ shrink: true }}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="paid">Paid</MenuItem>
                <MenuItem value="canceled">Canceled</MenuItem>
              </TextField>
            </Grid> */}
            <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="outlined" color="secondary" onClick={clearSearchFields}>
                Clear Search
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}

      <Typography variant="h6" gutterBottom>
        Product Statistics
      </Typography>
   
      
      {/* Responsive Grid for small screens */}
      <Box sx={{ display: { xs: 'block', md: 'none' }, mb: 4 }}>
        <Grid container spacing={2}>
          {orderData?.products?.map((product) => (
            <Grid item xs={12} sm={6} md={4} key={product.name}>
              <Card>
                <CardContent>
                  <Typography variant="h6">{product.name}</Typography>
                  <Typography>Quantity: {product.quantity}</Typography>
                  <Typography>Total Sales: {product.totalSales?.toFixed(2)}</Typography>
                  <Typography>Buying Price: {product.buyingPrice?.toFixed(2)}</Typography>
                  <Typography>Total Profit: {product.totalProfit?.toFixed(2)}</Typography>
                  <Typography>Total Tax: {product.totalTax?.toFixed(2)}</Typography>
                  <Typography>Tax Percentage: {product.taxPercentage}%</Typography>
                 
                  <Button onClick={() => handleProductClick(product)}  variant="contained" size="small" color="primary">View Orders</Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Table for larger screens */}
      <TableContainer component={Paper} sx={{ mb:3,display: { xs: 'none', md: 'block' } }}>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Total Sales</TableCell>
              <TableCell align="right">Buying Price</TableCell>
              <TableCell align="right">Total Profit</TableCell><TableCell align="right">Tax Percentage</TableCell>
              <TableCell align="right">Total Tax</TableCell>
              
          
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderData?.products?.map((product,i) => (
              <TableRow key={product.name}>
                <TableCell component="th" scope="row">{i+1}.{' '}
                  {product.name}
                </TableCell>
                <TableCell align="right">{product.quantity}</TableCell>
                <TableCell align="right">{product.totalSales?.toFixed(2)}</TableCell>
                <TableCell align="right">{product.buyingPrice?.toFixed(2)}</TableCell> 
                <TableCell align="right">{product.totalProfit?.toFixed(2)}</TableCell>
                <TableCell align="right">{product.taxPercentage}%</TableCell>
                <TableCell align="right">{product.totalTax?.toFixed(2)}</TableCell>
               
                <TableCell>
                  <Button onClick={() => handleProductClick(product)} size="small">View Orders</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={orderData.totalProducts}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="product-orders-modal"
        aria-describedby="modal-showing-orders-for-specific-product"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxHeight: '80vh',
          overflow: 'auto'
        }}>
          {selectedProduct && productOrders && (
            <>
              <Typography variant="h6" gutterBottom>
                Orders for {selectedProduct.name}
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Order ID</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      {/* <TableCell align="right">Price</TableCell> */}
                      <TableCell align="right">Total</TableCell>
                      <TableCell align="right">Buying Price</TableCell>  <TableCell align="right">Profit</TableCell>
                      <TableCell align="right">Tax</TableCell>
                      <TableCell align="right">Tax Amount</TableCell>
                    
                      <TableCell>Created At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                   {productOrders.orders.map((order) => (
                      <TableRow key={order.orderId}>
                        <TableCell component="th" scope="row">
                          {order.orderId}{order.paymentStatus === 'canceled' && (
                          <Chip
                            label="Canceled"
                            color="error"
                            size="small"
                            variant="outlined"
                            style={{ marginLeft: '5px' }}
                          />
                        )}
                        </TableCell>
                        <TableCell align="right">{order.products[0].quantity}</TableCell>
                        {/* <TableCell align="right">{order.products[0].price?.toFixed(2)}</TableCell> */}
                        <TableCell align="right">{order.products[0].pricePaid?.toFixed(2)}</TableCell>
                        <TableCell align="right">{order.products[0].buyingPrice?.toFixed(2)}</TableCell>  <TableCell align="right">{order.products[0].profit?.toFixed(2)}</TableCell>
                        <TableCell align="right">{order.products[0].tax}%</TableCell>
                        <TableCell align="right">{order.products[0].taxAmount?.toFixed(2)}</TableCell>
                     
                      
                        <TableCell>{ formatTimestamp(order.createdAt)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={productOrders.totalOrders}
                rowsPerPage={5}
                page={productOrdersPage}
                onPageChange={handleProductOrdersPageChange}
              />
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

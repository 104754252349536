import React, { useRef, useState } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tabs,
    Tab,
    Typography,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    useMediaQuery,
    useTheme
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { selectStore } from '../redux/slices/storeSlice'; 
import { useSelector } from 'react-redux';
import { useReactToPrint } from "react-to-print";

const ThermalDocument = ({ order, open, onClose }) => {
  const componentRef = React.useRef(null);
  // const componentRef = useRef(); // Reference for the component to print
  const printRef = React.useRef(); 
  const printFn = useReactToPrint({
    content: () => componentRef.current,
    contentRef: componentRef,
    documentTitle: "Invoice",
    pageStyle: `
      @media print {
        body {
          -webkit-print-color-adjust: exact !important;
          font-family: 'monospace';
       
        }
        div{  background-color:white !important;
          color:black !important;
        }
      
        table {
          width: 100%;
           color:black !important;
          border-collapse: collapse;
        }
        th, td {
          color:black !important;
          padding: 8px;
          text-align: left;
          border: 1px solid #ddd;
        }
      }
    `,
    onBeforeGetContent: () => {
      // This optional callback can be used for any preparation before printing
    }
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const printRef = useRef();
  const [tab, setTab] = useState(0);
  const storeData = useSelector(selectStore);

  const handlePrint = () => {
    if (tab === 1) {
      printFn();
      return;
    }
    const printContent = printRef.current;
    const WindowPrint = window.open('', '', 'width=900,height=650');
    WindowPrint.document.write(printContent.outerHTML);
    WindowPrint.document.close();
    WindowPrint.focus();
    WindowPrint.print();
    WindowPrint.close();
  };

  const { seller, products, createdAt } = order;

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const calculateTotalPrice_2 = (items) => {
    return items.reduce((total, item) => total + item.price * ((100 - item.tax) / 100) * item.quantity, 0);
  };

  const calculateTotalPriceTax = (items) => {
    return items.reduce((total, item) => total + (item.price * (item.tax / 100)) * item.quantity, 0);
  };

  const calculateTotalPriceWithTax = (items) => {
    return items.reduce((total, item) => total + (item.price) * item.quantity, 0);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={tab === 1 ? "lg" : "md"}
      fullWidth
      PaperProps={{
        sx: {
          m: isMobile ? 1 : 2,
          width: isMobile ? '100%' : undefined,
          maxHeight: isMobile ? '100%' : '90vh'
        }
      }}
    >
      <Tabs
        value={tab}
        onChange={(e, newValue) => setTab(newValue)}
        variant="fullWidth"
        sx={{ mb: 2 }}
      >
        <Tab label="Receipt" />
        <Tab label="Invoice" />
      </Tabs>

      <DialogContent>
        <div ref={printRef}>
          {tab === 0 ? (
            <div style={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap', width: '300px', margin: '0 auto', fontSize: 12 }}>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                <Typography variant="h6">RECEIPT</Typography>
                <Typography>Store: {storeData.name}</Typography>
                <Typography>Sold By: {seller.name}</Typography>
                <Typography>Address: {storeData.location}</Typography>
                <Typography>Date: {formatDate(createdAt)}</Typography>
                <Typography>Order ID: {order.orderId}</Typography>
              </div>

              <hr />

              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Menu</TableCell>
                      <TableCell align="center">Quant.</TableCell>
                      <TableCell align="right">Total price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((product, index) => (
                      <TableRow key={product._id}>
                        <TableCell>{index + 1}. {product.name.trim()}</TableCell>
                        <TableCell align="center">{product.quantity}</TableCell>
                        <TableCell align="right">KES {product.price.toFixed(2)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <hr />

              <div style={{ marginBottom: '10px' }}>
                <Typography style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>Total price (Before tax):</span>
                  <span>KES {calculateTotalPrice_2(products).toFixed(2)}</span>
                </Typography>
                <Typography style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>Tax (VAT %):</span>
                  <span>KES {calculateTotalPriceTax(products).toFixed(2)}</span>
                </Typography>
                <Typography style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid gray', paddingBottom: '5px' }}>
                  <strong>Total price:</strong>
                  <strong>KES {calculateTotalPriceWithTax(products).toFixed(2)}</strong>
                </Typography>
              </div>

              <div style={{ textAlign: 'center' }}>
                <Typography>Order Number: {order.orderId}</Typography>
                <Typography>Thank you for shopping with us!</Typography>
              </div>
            </div>
          ) : (
            <Paper ref={componentRef} elevation={3} sx={{ p: { xs: 2, sm: 3 }, width: '100%', overflow: 'auto' }}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="h4" gutterBottom>INVOICE</Typography>
                  <Typography>{storeData.name}</Typography>
                  <Typography>Sold By: {seller.name}</Typography>
                  <Typography>Location: {storeData.location}</Typography>
                  <Typography>Invoice No.: {order.orderId}</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={3} style={{ marginTop: '20px' }}>
                <Grid item xs={6}></Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <Typography variant="subtitle1">BILL TO</Typography>
                  <Typography>Name: ...........................................</Typography>
                  <Typography>Phone: ............................................</Typography>
                  <Typography>Address: ............................................</Typography>
                </Grid>
              </Grid>

              <TableContainer style={{ marginTop: '30px' }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>QTY</strong></TableCell>
                      <TableCell><strong>DESCRIPTION</strong></TableCell>
                      <TableCell align="right"><strong>AMOUNT</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((product) => (
                      <TableRow key={product._id}>
                        <TableCell>{product.quantity}</TableCell>
                        <TableCell>{product.name}</TableCell>
                        <TableCell align="right">KES {product.price.toFixed(2)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid container spacing={3} style={{ marginTop: '30px' }}>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Typography style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>Subtotal:</span>
                    <span>KES {calculateTotalPrice_2(products).toFixed(2)}</span>
                  </Typography>
                  <Typography style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>Sales Tax:</span>
                    <span>KES {calculateTotalPriceTax(products).toFixed(2)}</span>
                  </Typography>
                  <Typography style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', marginTop: '10px' }}>
                    <span>TOTAL:</span>
                    <span>KES {calculateTotalPriceWithTax(products).toFixed(2)}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handlePrint}
          color="primary" 
                    variant="contained"
                    startIcon={<PrintIcon />}
                >
                    Print {tab === 0 ? 'Receipt' : 'Invoice'}
                </Button>  <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
               
            </DialogActions>
        </Dialog>
    );
};

export default ThermalDocument;
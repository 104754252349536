/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-eval */
/* eslint-disable no-use-before-define */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  MenuItem,
  Select,
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Box,  Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
const SubaccountManager = () => {
 // Get storeId from URL params
 // For storing real store _id
  const [subaccounts, setSubaccounts] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [level, setLevel] = useState(1);
  const [editSubaccountId, setEditSubaccountId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const { id: storeIdParam } = useParams();
  const [realStoreId, setRealStoreId] = useState(null); 
  const token = useSelector((state) => state.auth.token);
  const BASE_URL = `${process.env.REACT_APP_BACKEND}`;

  // Fetch the real store _id on component load
  useEffect(() => {
    if (storeIdParam) {
      fetchRealStoreId();
    }
  }, [storeIdParam]);

  // Fetch the real store _id based on the storeIdParam from the URL
  const fetchRealStoreId = async () => {
    // try {
    //   const response = await axios.get(`${BASE_URL}/api/user/store/get-object-id/${storeIdParam}`, {
    //     headers: {
    //       Authorization: `JWT ${token}`,
    //     },
    //   });
      setRealStoreId(storeIdParam); // Save the real store _id
      fetchSubaccounts(storeIdParam); // Fetch subaccounts once the real store _id is available
    // } catch (error) {
    //   console.error('Error fetching store _id:', error);
    //   setSnackbarMessage('Error fetching store ID');
    //   setSnackbarSeverity('error');
    //   setOpenSnackbar(true);
    // }
  };

  // Fetch subaccounts using the real store _id
  const fetchSubaccounts = async (storeId) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/store/${storeId}/subaccounts`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      setSubaccounts(response.data.subaccounts);
    } catch (error) {
      console.error('Error fetching subaccounts:', error);
      setSnackbarMessage('Error fetching subaccounts');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleNameChange = (e) => setName(e.target.value);
  const handleLevelChange = (e) => setLevel(Number(e.target.value));

  const handleAddSubaccount = async () => {
    if (!email || !password || !name) {
      setSnackbarMessage('All fields are required.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/api/store/${realStoreId}/subaccount`, // Use the real store _id
        { email, password, name, level },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      );
      fetchSubaccounts(realStoreId)
      // setSubaccounts([...subaccounts, response.data.subaccount]);
      setSnackbarMessage('Subaccount added successfully');
      setSnackbarSeverity('success');
      clearFields();
    } catch (error) {
      console.error('Error adding subaccount:', error);
      setSnackbarMessage(error.response?.data?.message || 'Error adding subaccount');
      setSnackbarSeverity('error');
    }
    setOpenSnackbar(true);
  };

  const handleUpdateSubaccount = async () => {
    if (!email || !name) {
      setSnackbarMessage('Email and name must be provided.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      const response = await axios.put(
        `${BASE_URL}/api/store/${realStoreId}/subaccount/${editSubaccountId}`, // Use the real store _id
        { email, password, name, level },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      );
      // setSubaccounts(
      //   subaccounts.map((subaccount) =>
      //     subaccount._id === editSubaccountId ? response.data.subaccount : subaccount
      //   )
      // );
      fetchSubaccounts(realStoreId)
      setSnackbarMessage('Subaccount updated successfully');
      setSnackbarSeverity('success');
      clearFields();
      setEditSubaccountId(null);
    } catch (error) {
      console.error('Error updating subaccount:', error);
      setSnackbarMessage(error.response?.data?.message || 'Error updating subaccount');
      setSnackbarSeverity('error');
    }
    setOpenSnackbar(true);
  };

  const handleDeleteSubaccount = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this subaccount?');
    if (!confirmed) {
      return;
    }

    try {
      await axios.delete(`${BASE_URL}/api/store/${realStoreId}/subaccount/${id}`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      setSubaccounts(subaccounts.filter((subaccount) => subaccount._id !== id));
      setSnackbarMessage('Subaccount deleted successfully');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error deleting subaccount:', error);
      setSnackbarMessage(error.response?.data?.message || 'Error deleting subaccount');
      setSnackbarSeverity('error');
    }
    setOpenSnackbar(true);
  };

  const handleEditSubaccount = (subaccount) => {
    console.log('Editing subaccount:', subaccount); // Debugging line
    setEditSubaccountId(subaccount._id);
    setEmail(subaccount.email);
    setName(subaccount.name);
    setLevel(subaccount.level);
    setPassword(''); // Clear password field
  };

  const clearFields = () => {
    setEmail('');
    setPassword('');
    setName('');
    setLevel(1);
    setEditSubaccountId(null); // Reset edit mode
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const [copied, setCopied] = useState(false);
  const user = useSelector((state) => state.user);
  var storeLink=''
  if(user.email!="johndoe@gmail.com"){
      storeLink = `${window.location.origin}/pos/login/${storeIdParam}`;
  }else{
     storeLink = `${window.location.origin}/pos/demo/${storeIdParam}`;

  }
 
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
  };
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Manage Subaccounts
      </Typography>

      <Paper style={{ padding: '16px', marginBottom: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Name"
              value={name}
              onChange={handleNameChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Email"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              type="password"
              label="Password"
              value={password}
              onChange={handlePasswordChange}
              required={!editSubaccountId}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Select
              fullWidth
              value={level}
              onChange={handleLevelChange}
              displayEmpty
            >
              <MenuItem value={1}>Level 1 - Basic</MenuItem>
              <MenuItem value={2}>Level 2 - Intermediate</MenuItem>
              <MenuItem value={3}>Level 3 - Admin</MenuItem>
            </Select>
          </Grid>
        </Grid>

        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '16px', marginRight: '8px' }}
          onClick={editSubaccountId ? handleUpdateSubaccount : handleAddSubaccount}
        >
          {editSubaccountId ? 'Update Subaccount' : 'Add Subaccount'}
        </Button>

        {editSubaccountId && (
          <Button
            variant="outlined"
            color="secondary"
            style={{ marginTop: '16px' }}
            onClick={clearFields}
          >
            Cancel
          </Button>
        )}
      </Paper>
      <Box display="flex" alignItems="center">
      <Typography variant="body1" component="a" href={storeLink} target="_blank" rel="noopener noreferrer">
        Visit Store
      </Typography>
      <CopyToClipboard text={storeLink} onCopy={handleCopy}>
        <Tooltip title={copied ? 'Copied!' : 'Copy Link'}>
          <IconButton>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      </CopyToClipboard>
    </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Level</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subaccounts.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No subaccounts found.
                </TableCell>
              </TableRow>
            ) : (
              subaccounts.map((subaccount) => (
                <TableRow key={subaccount._id}>
                  <TableCell>{subaccount.name}</TableCell>
                  <TableCell>{subaccount.email}</TableCell>
                  <TableCell>{subaccount.level}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEditSubaccount(subaccount)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDeleteSubaccount(subaccount._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SubaccountManager;
